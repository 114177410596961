import { createAsyncThunk } from '@reduxjs/toolkit';

import API from '../../REST_API/listApi';

export const getTeamLeaderboar = createAsyncThunk(
  'teamLeaderboard/getTeamLeaderboarData',
  async (id: number) => {
    const response = await API.teams.getTeamMembers(id);
    return response.data;
  }
);
