import { createSlice } from '@reduxjs/toolkit';
import { getConfig } from "../Events/events.thunk";

interface IinitialState {
  data: {} | any;
  loading: boolean;
}

const reducerConfig = createSlice({
  name: 'config',
  initialState: {error: false, data: null, loading: false} as IinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConfig.pending, (state: IinitialState) => {
      state.loading = true;
    });
    builder.addCase(getConfig.fulfilled, (state: IinitialState, action) => {
      state.loading = false;
      state.data = action.payload;
    });
  },
});

export default reducerConfig.reducer;
