import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import cn from 'classnames';

import {AddReadingModal, LoaderData, Modal, Ripple} from '../';
import coinIcon from '../ProgressLoader/icon/coin.svg';
import {
  books,
  booksActive,
  clock,
  close,
  logoRfms,
  main,
  mainActive,
  progress,
  progressActive,
  settings,
  settingsActive,
  share,
} from './iconNav';
import {IState} from '../../types';
import {RootState} from '../../store';

import styles from './FooterNav.module.scss';
import ShareModal from "../Modal/ShareModal/ShareModal";
import {useMediaQuery} from "react-responsive";
import {analytics} from "../../analytics";
import Achievement from "../Achievements/Achievement/Achievement";
import progressMyApi from "../../REST_API/progressMyApi";
import { VIEWER_ROLE } from "../../const";
import { isTMViewer } from "../../REST_API";

export interface UrlParams {
  logReading: ''
}


const FooterNav: React.FC = () => {
    const anyDataIsLoading = useSelector((state: RootState) => state.indicators.isLoading);
    const events = useSelector((state: IState) => state.events.data);

    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
    const [addReadingModalIsOpen, setAddReadingModalIsOpen] = useState<boolean>(false);
    const [shareModalIsOpen, setShareModalIsOpen] = useState<boolean>(false);


    const location = useLocation().pathname;

    let newAchievement = useSelector((state: RootState) => state.progressMy.newAchievement);
    if(!isTMViewer()) newAchievement = undefined;

    const [achievementOpen, setAchievementOpen] = useState<boolean>(true);

    const isCurentPage = (name: string): boolean => location.startsWith(name);

    const toggleMenu = () => {
      if (events?.closed) return;
      setMenuIsOpen(!menuIsOpen);
    };
    const handleOpenAddReadingModal = () => {
      setMenuIsOpen(false);
      setAddReadingModalIsOpen(true);
    };

    const desktop = useMediaQuery({
      query: '(min-device-width: 1100px)',
    });

    const handleShare = async () => {
      setMenuIsOpen(false);
      // @ts-ignore
      if (!desktop && navigator.share) {
        try {
          // @ts-ignore
          await navigator.share({
            title: 'Read for My School',
            // text: 'My Donation Page',
            url: events ? events.shareLink : '',
          });
        } catch (err) {
          console.error(err);
        }
        analytics.logEvent('share_click', {
          method: 'native'
        })
      } else {
        setShareModalIsOpen(true);
        console.error('Native share function is not supported');
        analytics.logEvent('share_click', {
          method: 'modal'
        })
      }


    };


    useEffect(() => {
      document.body.style.overflow = menuIsOpen ? 'hidden' : 'auto';
    }, [menuIsOpen]);

    useEffect(() => {
      document.addEventListener('logreading', handleOpenAddReadingModal, false);
    }, []);


    const onAchievementModalClose = () => {
      if (newAchievement) progressMyApi.setViewed(newAchievement.id);
      setAchievementOpen(false);
    }

    if (
      location.includes('/settings/users/') ||
      location.includes('/welcome') ||
      location.includes('/sign-up') ||
      location.includes('/log-in') ||
      location.includes('/event/') ||
      location.includes('/forgot-password') ||
      !events
    )
      return null;
    if (location.includes('books/')) {
      if (!location.includes('books/recommended') && !location.includes('books/my')) return null;
    }


    return (
      <>
        <footer className={cn(styles.footerNavWrapper, menuIsOpen && styles.footerNavWrapperIsOpen)}>
          <div className={styles.footerNavOverlay}/>
          <div className={styles.footerNavMiddle}>
            <button
              className={styles.footerNavMiddleButton}
              onClick={toggleMenu}
              disabled={anyDataIsLoading}
            >
              {anyDataIsLoading ? (
                <>
                  <LoaderData width={52} colorWhite={true}/>
                  <img
                    alt='readIcon'
                    className={cn(styles.readIcon, styles.readIconLoad)}
                    src={logoRfms}
                  />
                </>
              ) : (
                <>
                  {menuIsOpen ? (
                    <img src={close} className={styles.closeIcon} alt='close'/>
                  ) : (
                    <img alt='readIcon' className={styles.readIcon} src={logoRfms}/>
                  )}
                </>
              )}
            </button>
            <div className={styles.actionsTitle}>What do you want to do?</div>
            <div className={styles.footerNavMenu}>
              <button onClick={() => setMenuIsOpen(false)}>
                <a
                  className={styles.footerNavMenuItem}
                  rel='noopener noreferrer'
                  href={events.shareLink + '/donate'}
                  target='_blank'
                >
                  <div className={styles.footerNavMenuIcon}>
                    <img src={coinIcon} alt='donation'/>
                  </div>
                  <div className={styles.footerNavMenuLabel}>Pledge</div>
                </a>
              </button>
              <button
                onClick={() => {
                  setMenuIsOpen(false);
                  handleOpenAddReadingModal();
                }}
                className={styles.footerNavMenuItem}
              >
                <div className={styles.footerNavMenuIcon}>
                  <img src={clock} alt='clock'/>
                </div>
                <div className={styles.footerNavMenuLabel}>Read</div>
              </button>
              <button className={styles.footerNavMenuItem} onClick={handleShare}>
                <div className={styles.footerNavMenuIcon}>
                  <img src={share} alt='share'/>
                </div>
                <div className={styles.footerNavMenuLabel}>Share</div>
              </button>
            </div>
          </div>
          <div className={styles.leftShadow}/>
          <div className={styles.rightShadow}/>
          <Ripple>
            <nav className={styles.wrapperFooterNav}>
              <ul className={styles.footerNavLeft}>
                <li className={cn(styles.navItem, isCurentPage('/main') && styles.navItemActive)}>
                  <Link className={cn(styles.navItem)} to='/main'>
                    <img
                      className={styles.navImg}
                      alt='main'
                      src={isCurentPage('/main') ? mainActive : main}
                    />
                    <span
                      className={cn(styles.navText, isCurentPage('/main') && styles.navTextActive)}
                    >
                    My page
                  </span>
                  </Link>
                </li>
                <li className={cn(styles.navItem, isCurentPage('/progress') && styles.navItemActive)}>
                  <Link className={cn(styles.navItem)} to='/progress/my'>
                    <img
                      className={styles.navImg}
                      alt='progress'
                      src={isCurentPage('/progress') ? progressActive : progress}
                    />
                    <span
                      className={cn(
                        styles.navText,
                        isCurentPage('/progress') && styles.navTextActive
                      )}
                    >
                    progress
                  </span>
                  </Link>
                </li>
              </ul>
              <ul className={styles.footerNav}>
                <li className={cn(styles.navItem, isCurentPage('/books') && styles.navItemActive)}>
                  <Link className={cn(styles.navItem)} to='/books/recommended'>
                    <img
                      className={styles.navImg}
                      alt='books'
                      src={isCurentPage('/books') ? booksActive : books}
                    />
                    <span
                      className={cn(styles.navText, isCurentPage('/books') && styles.navTextActive)}
                    >
                    books
                  </span>
                  </Link>
                </li>
                <li className={cn(styles.navItem, isCurentPage('/settings') && styles.navItemActive)}>
                  <Link className={cn(styles.navItem)} to='/settings'>
                    <img
                      className={styles.navImg}
                      alt='settings'
                      src={isCurentPage('/settings') ? settingsActive : settings}
                    />
                    <span
                      className={cn(
                        styles.navText,
                        isCurentPage('/settings') && styles.navTextActive
                      )}
                    >
                    settings
                  </span>
                  </Link>
                </li>
              </ul>
            </nav>
          </Ripple>
        </footer>
        <AddReadingModal
          isOpen={addReadingModalIsOpen}
          onClose={() => setAddReadingModalIsOpen(false)}
        />

        <ShareModal
          shareLink={events.shareLink}
          isOpen={shareModalIsOpen}
          cancel={() => setShareModalIsOpen(false)}
        />

        {newAchievement &&
        <Modal classModal={styles.modal} close={onAchievementModalClose} isOpen={achievementOpen}>
          <Achievement achievement={newAchievement}/>
        </Modal>}

      </>
    );
  }
;

export default FooterNav;
