import { axiosInstance } from './';
import load from "./index";

export default {
  getTeamMembers(teamId: number) {
    return axiosInstance.get(`/api/v1/teams/${teamId}/members`);
  },

  getMember(eventId: number, userId: number) {
    return axiosInstance.get(`/api/v1/events/${eventId}/members?userId=${userId}`);
  },

  getMemberById(memberId: number) {
    return axiosInstance.get(`/api/v1/members/${memberId}`);
  },

  setTeam(teamId: number, inviteCode?: string) {
    return load('POST', `/api/v1/teams/${teamId}/members`,
      {inviteCode});
  }
};
