import React, { useState } from 'react';
import cn from 'classnames';

import { CloseEye, EyeIcon, Search } from '../../../assets/images/icons';

import styles from './Input.module.scss';

const Input: React.FC<any> = ({ placeholder, meta, type, isSearch, ...field }) => {
  const [showPass, setShowPass] = useState(type !== 'password');
  const setShow = () => (showPass ? 'text' : 'password');

  const getInputType = () => {
    if (type === 'password') return 'text';
    //TODO bug in Mask function
    if (type === 'number') return 'text';
    if (type === 'search') return 'search';
    if (type === 'email') return 'email';
    return 'text';
  };
  return (
    <>
      <div
        className={cn(
          styles.wrapper,
          meta && meta.touched && meta.error && styles.wrapperError,
          !showPass && styles.hidePass
        )}
      >
        {isSearch && (
          <div className={styles.search}>
            <Search />
          </div>
        )}
        <input
          // autoComplete={'off'}
          placeholder={placeholder}
          className={styles.input}
          type={getInputType()}
          {...field}
        />
        {type === 'password' &&
          (!showPass ? (
            <CloseEye onClick={() => setShowPass(!showPass)} className={styles.icon} />
          ) : (
            <EyeIcon onClick={() => setShowPass(!showPass)} className={styles.icon} />
          ))}
      </div>
      {meta && meta.touched && meta.error && <div className={styles.error}>{meta.error}</div>}
    </>
  );
};

export default Input;
