import { createAsyncThunk } from '@reduxjs/toolkit';

import API from '../../REST_API/listApi';

export const getReadingRecords = createAsyncThunk(
  'readingRecords/getReadingRecordsData',
  async (id: number) => {
    const response = await API.readingRecords.getReadingRecordsData(id);
    return response.data;
  }
);
