import React, { useState } from 'react';

import { IReadingRecord } from '../../types';
import { Button, ReadingRecord } from '../';
import styles from '../ReadingRecords/ReadingRecords.module.scss';

interface IReadingRecordList {
  readingRecords: Array<IReadingRecord>;
}

const ReadingRecordList: React.FC<IReadingRecordList> = ({readingRecords}) => {

  const [limit, setLimit] = useState(10);
  let recods = readingRecords;
  if (recods.length > limit) recods = recods.slice(0, limit);

  return (
    <div>
      {recods.map((item: IReadingRecord) => (
        <ReadingRecord
          id={item.id}
          key={item.id}
          created={item.created}
          mins={item.mins}
          books={item.books}
          book={item.book}

        />
      ))}

      {limit < readingRecords.length && <div className={styles.showMore}>
        <Button onClick={() => {
          setLimit(limit + 10)
        }} type="thin" >
          Show more
        </Button>
      </div>
      }
    </div>
  );
};

export default ReadingRecordList;
