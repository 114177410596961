import React, { useState, useEffect } from 'react';
import { ArrowIcon, History, Close, Barcode } from '../../../assets/images/icons';
import styles from './Search.module.scss';
import { Input, LoaderData } from '../../../components';
import { IState } from '../../../types';
import { getSearchBooks } from '../../../store/Books/books.thunk';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import CardBook from '../CardBook/CardBook';
import OutsideClickHandler from 'react-outside-click-handler';
import { agent } from '../../../cordova-agent';
import { analytics } from "../../../analytics";
import Button from "../../../components/UI/Button/Button";

interface ISearch extends RouteComponentProps {
}

const Search: React.FC<ISearch> = ({history}) => {
  const [text, setText] = useState('');
  const [completed, setCompleted] = useState(false);
  const dispatch = useDispatch();
  const searchBooks = useSelector((state: IState) => state.books.data.searchBooks);
  const isLoading = useSelector((state: IState) => state.books.loading);
  const [isFocused, setisFocused] = useState(true);
  const barcodeSupport = !!agent.cordova;



  function handleKeyDown(e: React.KeyboardEvent, func: () => void) {
    if (e.keyCode === 13) {
      func();
    }
  }

  const reset = () => {
    setText('');
    dispatch(getSearchBooks(''));
  };

  const openScanner = () => {
    analytics.logEvent('book_scanner_open');
    agent.openScanner((result: string) => {
      if (result) {
        setText(result);
        search(result);
        history.push(`/books/${result}`);
        analytics.logEvent('book_scanner_result', {
          isbn: result
        });
      }
    });
  };

  const search = (value?: string) => {
    const textSearch = value || text;
    dispatch(getSearchBooks(textSearch));
    const search = localStorage.getItem('searchBooks');

    setisFocused(false);
    setCompleted(true);

    analytics.logEvent('book_search', {
      text: textSearch
    });
  };

  return (
    <div onKeyDown={(e) => text && handleKeyDown(e, search)} className={styles.wrapper}>
      <button onClick={() => history.goBack()} className={styles.BTN_Back}>
        <ArrowIcon/>
      </button>
      {text !== '' && (
        <button onClick={reset} className={styles.close}>
          <Close/>
        </button>
      )}
      {text === '' && barcodeSupport && (
        <button onClick={openScanner} className={styles.barcode}>
          <Barcode/>
        </button>
      )}
      <OutsideClickHandler onOutsideClick={() => setisFocused(false)}>
        <div className={styles.wrapperSearch}>
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setText(e.target.value);
              setCompleted(false);
            }}
            value={text}
            isSearch={true}
            placeholder={'Search by Title or ISBN'}
            onFocus={() => setisFocused(true)}
            type='search'
            autoFocus={true}
          />
        </div>
        {/*{!isLoading && isFocused && (*/}
        {/*<div className={styles.listSearch}>*/}
        {/*{searchBooksList.map((item: string, index: number) => (*/}
        {/*<div className={styles.itemSearch} key={item + index}>*/}
        {/*<button*/}
        {/*onClick={() => {*/}
        {/*setText(item);*/}
        {/*search(item);*/}
        {/*}}*/}
        {/*>*/}
        {/*<History />*/}
        {/*<span>{item}</span>*/}
        {/*</button>*/}
        {/*</div>*/}
        {/*))}*/}
        {/*</div>*/}
        {/*)}*/}
      </OutsideClickHandler>
      {isLoading && (
        <div className={styles.wrapperLoaderData}>
          <LoaderData width={40}/>
        </div>
      )}

      {!isLoading && searchBooks && completed && searchBooks.length > 0 && (
        <div className={styles.searchResult}>
          <span className={styles.title}>Search results</span>
          {searchBooks.map((item: any) => (
            <CardBook key={item.isbn} book={item}/>
          ))}
        </div>
      )}

      {!isLoading && searchBooks && completed && searchBooks.length === 0 && text !== '' && (
        <div>No results matched your query</div>
      )}

      {!isLoading && text === '' && (
        <div className={styles.barcodeTip}>
          <p>Use a Barcode Scanner to find your book quickly or type its Title/ISBN manually.</p>
        </div>
      )}

      {!isLoading && text.length > 0 && !completed && (
        <Button onClick={search} isSearch>
          Search books
        </Button>
      )}

    </div>
  );
};

export default withRouter(Search);
