import { createSlice } from '@reduxjs/toolkit';
import { getDonations } from './donations.thunk';
import { IDonations } from '../../types';

interface IinitialState {
  error: boolean | string;
  data: null | IDonations;
  loading: boolean;
}

const reducerDonations = createSlice({
  name: 'donations',
  initialState: { error: false, data: null, loading: false } as IinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDonations.pending, (state: IinitialState) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getDonations.fulfilled, (state: IinitialState, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getDonations.rejected, (state: IinitialState) => {
      state.loading = false;
    });
  },
});

export default reducerDonations.reducer;
