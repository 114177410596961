import React from 'react';
import stylesSignUp from '../../SignUp.module.scss';
import { ArrowIcon } from '../../../../assets/images/icons';
import ChangeAvatar from '../../../CreateReaderProfile/ChangeAvatar/ChangeAvatar';
import cn from 'classnames';
import styles from './ChangeAvatar.module.scss';

interface IChangeAvatar {
  avatar: string;
  setAvatar: (avatar: string) => void;
  setIsOpen: (data: boolean) => void;
}

const ChangeAvatarMob: React.FC<IChangeAvatar> = ({ avatar, setAvatar, setIsOpen }) => {
  return (
    <div className={styles.wrapper}>
      <button
        onClick={() => setIsOpen(false)}
        className={cn(stylesSignUp.BTN_Back, styles.BTN_Back)}
      >
        <ArrowIcon />
      </button>
      <span className={cn(stylesSignUp.titleModal, styles.title)}>Choose avatar</span>
      <ChangeAvatar url={avatar} setAvatar={setAvatar} setActive={() => setIsOpen(false)} />
    </div>
  );
};

export default ChangeAvatarMob;
