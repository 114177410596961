import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../REST_API/listApi';

export const getRecommendedBooks = createAsyncThunk('books/getRecommendedBooks', async () => {
  const response = await API.books.getrecommendedBooksData();
  return response.data;
});

export const getListsBooks = createAsyncThunk('books/getBookLists', async () => {
  const response = await API.books.getlistsBooksData();
  return response.data;
});

export const getList = createAsyncThunk('books/getList', async (id: number) => {
  const response = await API.books.getlistsData(id);
  return response.data;
});

export const getSearchBooks = createAsyncThunk('books/getSearchBooks', async (text: string) => {
  const response = text.length > 0 ? await API.books.getSearchBooks(text) : { data: [] };
  return response.data;
});

export const getBookDetails = createAsyncThunk('books/getBookDetails', async (id: number) => {
  const response = await API.books.getBookDetails(id);
  return response.data;
});

export const deleteBookDetails = createAsyncThunk('books/deleteBookDetails', () => {});

export const deleteList = createAsyncThunk('books/deleteList', () => {});

export const deleteBook = createAsyncThunk('books/deleteBook', (id: number) => {
  return id;
});
