/* eslint-disable */

import {createAsyncThunk} from '@reduxjs/toolkit';

import API from '../../REST_API/listApi';
import {ACCESS_TOKEN, SIGN_UP_TEAM_ID} from "../../const";
import {useParams} from "react-router-dom";

export const getUser = createAsyncThunk('user/getUserData', async (invite?: string) => {
    const response = await API.user.getUserData();
    let user = response.data;
    const event = new CustomEvent('userloaded', {'detail': user});
    document.dispatchEvent(event);

    let adminLoginlink = `https://${location.host.startsWith('dev') ? 'dev-' : ''}admin.readformyschool.com/main?accessToken=${localStorage.getItem(ACCESS_TOKEN)}`;

    if (user && user.role === 'EA') {
        window.location.href = adminLoginlink;
        window.localStorage.clear();
        return null;
    }

    if (user && user.role === 'TL') {
        //profile is filled out
        if (user.firstName) {
            window.location.href = adminLoginlink;
            window.localStorage.clear();
        }
        return user;
    }

    return user;
});
