import React, {useState} from 'react';
import cn from 'classnames';

import {Modal} from '../../index';
import styles from './Achievement.module.scss';
import {IAchievement} from "../../../types";
import Achievement from "./Achievement";

interface IProps {
  achievement: IAchievement
}

const AchievementListItem: React.FC<IProps> = ({achievement}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={cn(styles.achievement, achievement.achieved && styles.achievementCurrent)}
      >
        <img className={styles.achievementImg} alt={achievement.name} src={achievement.imageUrl}/>
      </button>
      <Modal classModal={styles.modal} close={() => setIsOpen(false)} isOpen={isOpen}>
        <Achievement achievement={achievement}/>
      </Modal>
    </>
  );
};

export default AchievementListItem;
