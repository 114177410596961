import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { FormatError, Input } from '../../../components';
import { logIn } from '../../../REST_API/logInApi';
import { Facebook, Google } from '../../../assets/images/icons';
import ButtonNavigation from './ButtonNavigation/ButtonNavigation';
import styles from '../SignUp.module.scss';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Field, Formik } from 'formik';
import { useDispatch } from "react-redux";
import { getEvents } from "../../../store/Events/events.thunk";
import { getUser } from "../../../store/User/user.thunk";
import { agent } from "../../../cordova-agent";
import { getListsBooks } from "../../../store/Books/books.thunk";
import { analytics } from "../../../analytics";

interface IData {
  email: string;
  password: string;
}

const LogIn: React.FC<RouteComponentProps> = ({history}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const myRef = useRef(null);
  const handleScroll = () => {
    // @ts-ignore
    myRef.current.scrollIntoView(false);
  };

  useEffect(() => {
    handleScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values: IData, actions: any) => {
    setIsLoading(true);

    const succses = (data: any) => {
      localStorage.setItem('accessToken', data.accessToken);
      setIsLoading(false);
      dispatch(getEvents());
      dispatch(getUser());
      dispatch(getListsBooks());
      setTimeout(() => {
        history.push('/main');
      }, 400);
      analytics.logEvent('login', {method: 'email'});
    };
    const error = (data: any) => {
      setIsLoading(false);
      actions.setFieldError('password', FormatError(data));
    };

    logIn({email: values.email, password: values.password}, succses, error);
  };

  function handleKeyDown(e: React.KeyboardEvent, func: () => void) {
    if (e.keyCode === 13) {
      func();
    }
  }

  return (
    <>
      <span className={styles.title}>Log in</span>


      <Formik initialValues={{email: '', password: ''}} onSubmit={onSubmit}>
        {({handleSubmit, submitForm, values}: any) => {
          return (
            <form
              onKeyDown={(e) => values.email && values.password && handleKeyDown(e, submitForm)}
              className={cn(styles.listInputLogin)}
              onSubmit={handleSubmit}
            >

              <span className={styles.text}>Log in with Email</span>

              <Field name='email'>
                {({field, meta}: any) => (
                  <div ref={myRef} className={styles.firstInput}>
                    <Input meta={meta} type="email" placeholder='Email' {...field} />
                  </div>
                )}
              </Field>
              <Field name='password'>
                {({field, meta}: any) => (
                  <div>
                    <Input meta={meta} type='password' placeholder='Password' {...field} />
                  </div>
                )}
              </Field>

              {agent.cordova ? '':
              <div className={styles.loginWithSocial}>
                <span className={styles.label}>Or log in with</span>
                <div className={styles.socialButtons}>
                  <div className={styles.SocialBtn}>
                    <a href={`/auth/google?h${Date.now()}`}><Google/></a>
                  </div>
                  <div className={styles.SocialBtn}>
                    <a href={`/auth/facebook?h${Date.now()}`}><Facebook/></a>
                  </div>
                </div>
              </div>
              }

              <ButtonNavigation isLoading={isLoading} values={values} onClick={submitForm}/>
            </form>
          );
        }}
      </Formik>


    </>
  );
};

export default withRouter(LogIn);
