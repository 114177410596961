import React, { useState } from 'react';
import { setBookStatus } from '../../../../REST_API/booksApi';
import styles from './StatusBook.module.scss';
import { LoaderData } from '../../../../components';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import {
  Heart,
  Check,
  Time,
  RadioButtonsOff,
  RadioButtonsOn,
} from '../../../../assets/images/icons';
import { getBookDetails, getListsBooks } from '../../../../store/Books/books.thunk';
import { useDispatch, useSelector } from 'react-redux';
import { analytics } from "../../../../analytics";
import { getBookLists } from "../../../../store/DonationPage/thunks";
import { IState } from "../../../../types";

const list = [
  {
    name: 'wishlist',
    title: 'My Wishlist',
    icon: Heart,
  },
  {
    name: 'bookshelf',
    title: 'My Bookshelf',
    icon: Time,
  },
  {
    name: 'completed',
    title: 'Completed',
    icon: Check,
  },
];

const StatusBook: React.FC<any> = ({data, id}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeName, setActiveName] = useState('');
  const includesType = (text: string) => data.find((item: any) => item.type === text);

  const bookshelf = useSelector((state: IState) =>
    state.books.data.listBook?.find((item) => item.type === 'bookshelf')
  );

  const handleClick = (type: string) => {
    setActiveName(type);

    const success = () => {
      dispatch(getBookDetails(id));
      dispatch(getListsBooks());
      // setTimeout(() => {
      setActiveName('');
      // }, 600);
      setTimeout(()=>{
        if (type === 'bookshelf') {
          history.push('/progress/my');
          window.document.dispatchEvent(new CustomEvent('logreading'));
        }
      },1000);

    };

    setBookStatus(
      includesType(type) ? 'DELETE' : 'PUT',
      `/api/v1/books/lists/${type}/books/${id}`,
      success
    );

    analytics.logEvent('book_add', {
      listType: type
    });
  };

  const getTitle = (item: any) => {
    if (item.name === 'bookshelf') {
      return includesType(item.name) ? 'My Bookshelf' : 'Start reading'
    } else {
      return item.title;
    }
  };

  return (
    <div className={styles.wrapper}>
      {list.map((item) => (
        <div
          key={item.name}
          onClick={() => activeName !== item.name && handleClick(item.name)}
          className={cn(
            styles.item,
            includesType(item.name) && styles.currentItem,
            activeName === item.name && styles.clicked
          )}
        >
          <item.icon/>
          <span>{getTitle(item)}</span>
          {activeName === item.name ? (
            <LoaderData width={15}/>
          ) : includesType(item.name) ? (
            <RadioButtonsOn/>
          ) : (
            <RadioButtonsOff/>
          )}
        </div>
      ))}
    </div>
  );
};

export default StatusBook;
