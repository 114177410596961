import React, { useEffect, useState } from 'react';

import styles from './MemberDetails.module.scss';
import { IPublicEvent, ITeamMember } from "../../../../store/DonationPage/types";
import { arrow } from "../../../../components/ListLeaderboard/icon";
import { ProgressLoader } from "../../../../components";
import FundraiserStats from "../../FundraiserStats/FundraiserStats";
import { IBook } from "../../../../types";
import CardBook from "../../../Books/CardBook/CardBook";
import ReadingProgress from "../../../../components/progress/ReadingProgress/ReadingProgress";

interface Props {
  event: IPublicEvent;
  member?: ITeamMember;
  bookLists: Array<IBook>
}

const MemberDetails = ({event, member, bookLists}: Props) => {

  const [isVisible, setIsVisible] = useState(false);


  const readingBook = bookLists && bookLists.find(l => l.type === 'bookshelf')?.books.find(i => i != undefined);

  return (
    <div className={`${styles.MemberDetails}`}>

      <div className={styles.detailsButton} onClick={() => setIsVisible(!isVisible)}>
        {isVisible ? `Hide details` : `Show details`}
        <img className={`${styles.arrow} ${isVisible && styles.arrowInvert}`} alt='' src={arrow}/>
      </div>

      <div className={isVisible ? styles.visible : styles.hidden}>

        {/*<FundraiserStats event={event}/>*/}

        <h3 className={styles.title}>My personal message</h3>

        <div className={styles.personalMessage}>
          {event.invite.message || event.details.defaultPersonalMessage}

          <br/>
          <label>
            {event.invite.from.firstName}
          </label>
        </div>

        {readingBook && <>
          <br/>
          <h3>
            What {event.invite.from.firstName} is reading now
          </h3>

          <CardBook book={readingBook}
                    externalLink={true}
                    buyButton={false}/>
        </>}

        {member &&
        <ReadingProgress value={member?.progress.minsSum} goal={member.team.goals.readingMinsPerMember}/>}


      </div>


    </div>
  );
};


export default MemberDetails;
