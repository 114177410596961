import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getList, deleteList } from '../../../store/Books/books.thunk';
import { IState } from '../../../types';
import { withRouter, Link, RouteComponentProps } from 'react-router-dom';
import { ArrowIcon } from '../../../assets/images/icons';
import styles from './List.module.scss';
import CardBook from '../CardBook/CardBook';
import { LoaderData } from '../../../components';
import stylesGlobal from '../Books.module.scss';

interface MatchParams {
  id: string;
}

interface IList extends RouteComponentProps<MatchParams> {}

const List: React.FC<IList> = ({ match, history }) => {
  const dispatch = useDispatch();
  const list = useSelector((state: IState) => state.books.data.list);
  const myRef = useRef(null);

  useEffect((): any => {
    window.scrollTo(0, 0);
    dispatch(getList(+match.params.id));
    if (list && +match.params.id != list.id) {
      dispatch(deleteList());
    }
  }, []);

  if (!list)
    return (
      <div className={stylesGlobal.wrapperLoaderData}>
        <LoaderData width={40} />
      </div>
    );

  const formatGradesInterval = () => {
    if (list.minGrade == -1 && list.maxGrade == 12) return 'Any';
    if (list.minGrade == -1) return `Pre K - ${list.maxGrade}`;
    return `${list.minGrade} - ${list.maxGrade}`;
  };

  return (
    <div className={styles.wrapper}>
      <button ref={myRef} onClick={() => history.goBack()} className={styles.BTN_Back}>
        <ArrowIcon />
      </button>
      <span className={styles.title}>{list.title}</span>
      <span>Recommended for grades: {formatGradesInterval()}</span>
      <div className={styles.list}>
        {list.books.map((item) => (
          <CardBook key={item.isbn} book={item}  />
        ))}
      </div>
    </div>
  );
};

export default withRouter(List);
