import load from '../REST_API';

export default {
  getAddReadingData(id: number, datetime?: Date, mins?: number, bookId?: number, books?: number) {
    const value: any = { mins: mins || 0 };
    if (datetime) value.time = datetime ? datetime.getTime() : '';
    if (bookId) value.bookId = bookId;
    if (books) value.books = books;

    return load('POST', `/api/v1/members/${id}/progress/records`, value);
  },
};

export const deleteReadinItem = async (recordId: number, memberId: number, success: any) => {
  const res = await load('DELETE', `/api/v1/members/${memberId}/progress/records/${recordId}`);
  if (res.ok) success();
  return res;
  // return load('DELETE', `/api/v1/members/${memberId}/progress/records/${recordId}`);
};
