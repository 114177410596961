import React, {useEffect, useState, useContext} from 'react';
import cn from 'classnames';
import moment from 'moment';
import {getAchievements, getProgressMy} from '../../../store/ProgressMy/progressMy.thunk';
import {IState} from '../../../types';
import {Button, Select} from '../../';
import {getAddReading} from '../../../store/AddReading/addReading.thunk';
import styles from '../AddReadingModal.module.scss';
import {useSelector, useDispatch} from 'react-redux';
import {AppContext} from '../AddReadingModal';
import {getListsBooks} from "../../../store/Books/books.thunk";
import {analytics} from "../../../analytics";

type Props = {
  setTimeAdd: (time: number) => void;
  setCurrentSlide: (value: number) => void;
};

const convertMinutes = (time: number) => {
  const hours = Math.floor(time / 60);
  const minutes = time % 60;

  return {hours, minutes};
};

const STEP = 10;
const TODAY = moment().format('YYYY-MM-DD');
const YESTERDAY = moment().subtract(1, 'days').format('YYYY-MM-DD');

const LogMinutes: React.FC<Props> = ({setCurrentSlide, setTimeAdd}) => {
  const dispatch = useDispatch();
  const {currentBook} = useContext(AppContext);

  const progressMy = useSelector((state: IState) => state.progressMy.data);
  const loading = useSelector((state: IState) => state.addReading.loading);

  const days: string[] = [...new Array(30)].map((i, idx) =>
    moment().startOf('day').subtract(idx, 'days').format('YYYY-MM-DD')
  );

  useEffect(() => {
    analytics.logView('log_reading');
    if (!progressMy) dispatch(getProgressMy());
    dispatch(getListsBooks());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [time, setTime] = useState<number>(0);
  const [selectedDay, setSelectedDay] = useState<string>(TODAY);
  const [activeTab, setActiveTab] = useState<string>('TODAY');
  const [isRequestSuccess, setIsRequestSuccess] = useState<boolean>(false);

  const getDayLabel = (day: string) => {
    if (day === TODAY) return 'Today';
    if (day === YESTERDAY) return 'Yesterday';

    return moment(day, 'YYYY-MM-DD').format('LL');
  };

  const handleIncrease = () => {
    const nextTime = time + STEP;

    if (nextTime <= 180) {
      setTime(nextTime);
    }
  };

  const handleDecrease = () => {
    if (time > 0) {
      setTime(time - STEP);
    }
  };
  const handleChangeActiveTab = (tab: string) => {
    if (tab === 'TODAY' || tab === 'OTHER') setSelectedDay(TODAY);
    if (tab === 'YESTERDAY') setSelectedDay(YESTERDAY);

    setActiveTab(tab);
  };

  const handleSubmit = () => {
    if (progressMy) {
      const fin = (data: boolean) => {
        if (navigator.vibrate) navigator.vibrate([250]);
        setCurrentSlide( currentBook > 0 ? 3 : 4);
      };
      setTimeAdd(time);

      const datetime = moment(selectedDay).toDate();
      datetime.setHours(new Date().getHours());
      datetime.setMinutes(new Date().getMinutes());

      dispatch(
        getAddReading({
          id: progressMy.id,
          datetime: datetime,
          mins: time,
          bookId: currentBook > 0 ? currentBook : undefined,
          setIsRequestSuccess: setIsRequestSuccess,
          setShowGoal: fin,
        })
      );
      analytics.logEvent('log_reading', {
        minutes: time,
        day: selectedDay,
        bookId: currentBook
      })

      setTimeout(() => {
        dispatch(getAchievements());
      }, 3000);
    }
  };

  return (
    <>
      <h3 className={styles.title}>
        Please log your reading <br/> minutes
      </h3>
      <div className={styles.time}>
        <button onClick={handleDecrease} className={styles.timeButton}>
          -
        </button>
        <Timer time={time}/>
        <button onClick={handleIncrease} className={styles.timeButton}>
          +
        </button>
      </div>
      <div className={styles.day}>
        <button
          onClick={() => handleChangeActiveTab('TODAY')}
          className={cn(styles.dayItem, activeTab === 'TODAY' && styles.isActive)}
        >
          Today
        </button>
        <button
          onClick={() => handleChangeActiveTab('YESTERDAY')}
          className={cn(styles.dayItem, activeTab === 'YESTERDAY' && styles.isActive)}
        >
          Yesterday
        </button>
        <button
          onClick={() => handleChangeActiveTab('OTHER')}
          className={cn(styles.dayItem, activeTab === 'OTHER' && styles.isActive)}
        >
          Other
        </button>
      </div>
      {activeTab === 'OTHER' && (
        <div className={styles.choiceDay}>
          <Select onChange={setSelectedDay} value={selectedDay}>
            {days.map((day) => (
              <option key={day} value={day}>
                {getDayLabel(day)}
              </option>
            ))}
          </Select>
        </div>
      )}
      <Button
        disabled={time === 0}
        onClick={handleSubmit}
        isLoading={loading}
        isSuccessful={isRequestSuccess}
      >
        Next
      </Button>
    </>
  );
};

const Timer: React.FC<{ time: number }> = ({time}) => {
  const getHours = () => {
    const hour = convertMinutes(time).hours;
    return hour >= 10 ? hour : `0${hour}`;
  };

  const getMinutes = () => {
    const {minutes} = convertMinutes(time);
    return minutes > 0 ? minutes : `0${minutes}`;
  };

  return (
    <div className={styles.timeInner}>
      <div className={styles.timeBlock}>
        <strong>{getHours()}</strong>
        <span>Hrs</span>
      </div>
      <strong>:</strong>
      <div className={styles.timeBlock}>
        <strong>{getMinutes()}</strong>
        <span>Mins</span>
      </div>
    </div>
  );
};

export default LogMinutes;
