import React from 'react';


import styles from './DonationInfo.module.scss';
import { IDonationInfo } from "../../../store/DonationPage/types";
import MoneyAmount from "../../../components/UI/MoneyAmount";
import moment from "moment";

interface Props {
  donation: IDonationInfo;
  currency?: string;
}

const DonationInfo = ({donation, currency}: Props) => {

  return (
    <div className={`${styles.DonationInfo}`}>

      <div className={styles.amount}>
        <MoneyAmount amount={donation.amount} currency={currency || ''}/>
      </div>

      <div>
        <div className={styles.donorName}>{donation.donorName}</div>
        <div className={styles.donorMessage}>{donation.message ? `${donation.message}, `:''}
          <span className={styles.time}>{' '}{moment(donation.createdAt).format('LL')}</span>
        </div>

      </div>

    </div>
  );
};

export default DonationInfo;
