import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { IState } from '../../types';
import DefaultEventImage from '../../assets/images/illustrations/DefaultEventImage.png';
import { NewWindowIcon } from '../../assets/images/icons/index';

import styles from './Events.module.scss';
import DonationsProgress from "../progress/DonationsProgress/DonationsProgress";
import { ArrowIcon } from "../../assets/images/icons";
import { replaceLinks } from "../../utils/textUrils";

const Events: React.FC = () => {
  const [open, setOpen] = useState<boolean>(true);
  const events = useSelector((state: IState) => state.events.data);
  if (!events) return null;

  const {name, end, details} = events || {};

  let dateDiff = moment(end).diff(moment(), 'days');
  dateDiff = dateDiff > 0 ? dateDiff : 0;

  return (
    <>
      <div className={styles.background}/>
      <div className={styles.wrapperEvents}>

        <div onClick={() => setOpen(!open)} className={`${styles.arrowBtn} ${open && styles.arrowUp}`}>
          <ArrowIcon/>
        </div>

        <span className={styles.heading}>My Fundraiser</span>
        <span className={styles.name}>{name}</span>
        <span className={styles.textEvents}>
          {events.closed ?
            <span className={styles.textDateLeft}>The fundraiser is now closed</span> :
            <>There are <span className={styles.textDateLeft}>{dateDiff} days left</span> in your
              fundraiser</>
          }
          </span>

        {open && (
          <div className={open && styles.container}>
            <img alt={name} className={styles.imgEvent} src={details.photo ? details.photo : DefaultEventImage}/>
            <span className={styles.title}>Summary</span>

            <div className={styles.body}  dangerouslySetInnerHTML={{__html: replaceLinks(details.summary)}}/>

            <span className={styles.title}>Objectives</span>
            {details.objectives && <ul>
              {details.objectives.map((item) => (
                <li style={{listStyle: 'inside'}} key={item} className={styles.body}>
                  {item}
                </li>
              ))}
            </ul>}
            <br/>

          </div>
        )}


        <DonationsProgress amount={events.progress.raisedSum}
                           goal={events.goals.fundraising} currency={events.currency}
                           raisedLabel={`total raised`}
        />

        {open &&
        <a href={events.shareLink} className={styles.button} target="_blank">
          My Donation Page
          <NewWindowIcon className={styles.icon}/>
        </a>}

        {/*{open ? (*/}
        {/*  <button onClick={() => setOpen(false)} className={styles.textClose}>*/}
        {/*    Hide details*/}
        {/*  </button>*/}
        {/*) : (*/}
        {/*  <Button styleBTN={{height: 35}} type='Content' onClick={() => setOpen(true)}>*/}
        {/*    View details*/}
        {/*  </Button>*/}
        {/*)}*/}

      </div>
    </>
  );
};

export default Events;
