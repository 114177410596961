import React, {useEffect, useState} from 'react';
import InputMask from 'react-input-mask';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import stylesCode from './code.module.scss';
import {Input, LoaderData} from '../../../components';
import {IState} from '../../../types';
import {Check} from '../../../assets/images/icons';
import infoIcon from '../../../assets/images/icons/info.svg';
import ApstoreImgSrc from '../../../assets/images/logo/appstore.png';
import GooglePlayImgSrc from '../../../assets/images/logo/googleplay.png';

import cn from 'classnames';
import styles from '../SignUp.module.scss';
import {useSelector} from 'react-redux';
import ButtonNavigation from '../ButtonNavigation/ButtonNavigation';
import {agent, APP_STORE_LINK, GOOGLE_PLAY_LINK} from "../../../cordova-agent";
import {useMediaQuery} from "react-responsive";
import {analytics} from "../../../analytics";

interface MatchParams {
    code: string;
}

interface ICode extends RouteComponentProps<MatchParams> {
    data: any;
    page: {
        activePage: number;
        setActivePage: (page: number) => void;
        code: string;
        setCode: (code: string) => void;
    };
    list: string[];
}

export const CODE_LENGTH = 5;

const Code: React.FC<ICode> = ({history, data, match, page, list}) => {
    const error = useSelector((state: IState) => state.signUpData.error);
    const loading = useSelector((state: IState) => state.signUpData.loading);
    const invite = useSelector((state: IState) => state.signUpData.data);
    const value = match.params.code;
    const {code, setCode} = page;
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        analytics.logView('sign_up_code');
        if (match.params.code && match.params.code.length === CODE_LENGTH)
            setCode(match.params.code);
    }, []);

    useEffect(() => {
        if (value.length !== CODE_LENGTH || error || loading) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, loading]);

    useEffect(() => {
        if (code && code.length === CODE_LENGTH) {
            history.push(`/sign-up/join/code/${code}`);
            analytics.logEvent('sign_up_code_enter', {code});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code]);

    const getStatus = () => {
        if (loading) return <LoaderData className={stylesCode.LoaderData} width={19}/>;
        if (data) return <Check className={styles.confirmedIcon}/>;
    };


    const desktop = useMediaQuery({
        query: '(min-width: 800px)',
    });
    let userAgent = navigator.userAgent;
    const isAndroid = /android/i.test(userAgent);
    const isIPhone = /iPhone/.test(userAgent);

    return (
        <>
            <span className={styles.text}>Insert {CODE_LENGTH}-digit invite code here</span>
            <InputMask
                mask='* * * * *'
                maskChar={'-'}
                value={loading ? value : code}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setCode(e.target.value.replace(/(\s|-)/g, ''));
                }}
            >
                {(inputProps: any) => (
                    <div className={cn(error && stylesCode.shake)}>
                        <Input
                            type='number'
                            meta={{
                                error:
                                    error &&
                                    'Invalid invite code. Please talk to your administrator to receive your valid invite code',
                                touched: error && true,
                            }}
                            {...inputProps}
                            style={{textAlign: 'center'}}
                            placeholder='- - - - -'
                            disabled={loading}
                        />
                    </div>
                )}
            </InputMask>
            {getStatus()}
            {data && data.event && (
                <div className={styles.listField}>

                    {invite.inviteRole === 'TL' &&

                            <div className={styles.info}>
                                <img src={infoIcon} alt="" className={styles.infoIcon}/>
                                You are invited to be a Team leader in the fundraiser challenge below.
                                Please select your team on the next step.
                            </div>
                    }

                    <span className={styles.field}>Fundraiser</span>
                    <span className={styles.value}>{data.event.name}</span>
                    <span className={styles.field}>School</span>

                    {data.event.location &&
                        <span className={styles.value}>
            {data.event.location.schoolName}, {data.event.location.state}{' '}
                            {data.event.location.zipcode}
          </span>
                    }
                    <span className={styles.field}>Administrator</span>
                    <span className={styles.value}>
            {data.from.firstName} {data.from.lastName}
          </span>
                </div>
            )}
            {!data && (
                <div className={styles.hint}>
                    <div className={styles.info}>
                        <img src={infoIcon} alt="" className={styles.infoIcon}/>
                        Please insert your invite code and set up a new reader profile to support your fundraiser.
                        If you don’t know your new invite code, please contact your event administrator.
                    </div>

                    {!agent.cordova && (isIPhone || isAndroid) &&
                        <div className={styles.marketButtons}>

                            <div className={styles.text}>Install Read for My School application</div>

                            {(isIPhone) &&
                                <a href={APP_STORE_LINK} target="_blank">
                                    <img src={ApstoreImgSrc} className={styles.appstore}/>
                                </a>
                            }
                            {(isAndroid) &&
                                <a href={GOOGLE_PLAY_LINK} target="_blank">
                                    <img src={GooglePlayImgSrc} className={styles.googleplay}/>
                                </a>
                            }
                        </div>}
                </div>
            )}
            <ButtonNavigation isDisabled={isDisabled} list={list} page={{...page, activePage: 0}}/>
        </>
    );
};

export default withRouter(Code);
