import { createSlice } from '@reduxjs/toolkit';
import { getTeamLeaderboar } from './teamLeaderboar.thunk';
import { ITeamLeaderboard } from '../../types/index';

interface IinitialState {
  error: boolean | string;
  data: null | ITeamLeaderboard;
  loading: boolean;
}

const reducerTeamLeaderboard = createSlice({
  name: 'teamLeaderboard',
  initialState: { error: false, data: null, loading: false } as IinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTeamLeaderboar.pending, (state: IinitialState) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getTeamLeaderboar.fulfilled, (state: IinitialState, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getTeamLeaderboar.rejected, (state: IinitialState) => {
      state.loading = false;
    });
  },
});

export default reducerTeamLeaderboard.reducer;
