import React from 'react';

import ButtonNavigation from '../../Welcome/ButtonNavigation/ButtonNavigation';

import { ChildOnBook } from '../../../assets/images/icons';

import styles from './LastStep.module.scss';
import ApstoreImgSrc from "../../../assets/images/logo/appstore.png";
import GooglePlayImgSrc from "../../../assets/images/logo/googleplay.png";
import { agent, APP_STORE_LINK, GOOGLE_PLAY_LINK } from "../../../cordova-agent";

const LastStep: React.FC = () => {

  let userAgent = navigator.userAgent;
  const isAndroid = /android/i.test(userAgent);
  const isIPhone = /iPhone/.test(userAgent);

  const appLink = agent.cordova ? null : isAndroid ? GOOGLE_PLAY_LINK : (isIPhone ? APP_STORE_LINK : null);

  return (
    <div className={styles.wrapper}>
      <ChildOnBook className={styles.ChildOnBook}/>
      <div className={styles.body}>
        <span className={styles.title}>Let’s fly!</span>
        <span className={styles.text}>
          Congrats! You have successfully registered to your fundraiser!
        </span>

        {(isIPhone && !agent.cordova) &&
        <a href={APP_STORE_LINK} target="_blank">
          <img src={ApstoreImgSrc} className={styles.appstore}/>
        </a>
        }
        {(isAndroid && !agent.cordova) &&
        <a href={GOOGLE_PLAY_LINK} target="_blank">
          <img src={GooglePlayImgSrc} className={styles.googleplay}/>
        </a>
        }

      </div>
      <ButtonNavigation welcome={false} appLink={appLink}/>
    </div>
  );
};

export default LastStep;
