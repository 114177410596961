import { createSlice } from '@reduxjs/toolkit';
import { IUser } from '../../types';
import { getUser } from './user.thunk';

interface IinitialState {
  error: boolean | string;
  data: null | IUser;
  loading: boolean;
}

const reducerUser = createSlice({
  name: 'user',
  initialState: {error: false, data: null, loading: false} as IinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state: IinitialState) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getUser.fulfilled, (state: IinitialState, action) => {
      state.loading = false;
      state.data = action.payload;
      let user = state.data;
      if (user) {
        user.hasProfile = !!user.firstName && !!user.lastName;
        if (user.managed)
          user.managed.forEach(managed => managed.isCurrent = (managed.id === user?.id));
      }
    });
    builder.addCase(getUser.rejected, (state: IinitialState) => {
      state.loading = false;
    });
  },
});

export default reducerUser.reducer;
