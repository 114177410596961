import {getPlatform} from "./analytics";
import {VIEWER_ROLE} from "./const";
// import {VIEWER_ROLE} from "./REST_API";

var APP_ID = "hlicm2hh";


export const intercomAgent = {

    initialize() {
        document.addEventListener('userloaded', this.onUserLoaded.bind(this), false);
    },

    onUserLoaded(e) {
        this.initializeIntercom();
        window.intercomSettings = {
            app_id: APP_ID,
            hide_default_launcher: true
        };
        let user = e.detail;
        // this.registerUser(user);
        // console.log('intercomAgent.onUserLoaded', user)
    },

    registerUser(user) {
        if (!user || (localStorage.getItem(VIEWER_ROLE))) {
            return;
        }

        // if (window.intercom) {
        //   console.log('Intercom register using cordova plugin, userID = ', user.id);
        //   window.intercom.registerIdentifiedUser({userId: user.id});
        //   window.intercom.registerForPush();
        //   window.intercom.updateUser(this.mapToAttributes(user));
        //   if (localStorage.getItem('fcmToken'))
        //     window.intercom.sendPushTokenToIntercom(localStorage.getItem('fcmToken'), () => {
        //       console.log('token sent to intercom');
        //     })
        //
        // } else
        if (window.Intercom) {
            console.log('Intercom register using wep app, userID = ', user.id);
            window.Intercom('boot', this.mapToAttributes(user));
        }
    },

    mapToAttributes(user) {
        let event = user.events && user.events.length > 0 ? user.events[0] : null;
        return {
            app_id: APP_ID,
            user_id: user.id,
            userId: user.id,
            name: (user.firstName ? user.firstName + ' ' + user.lastName : user.email) + ' (' + user.role + ')',
            email: user.email,
            role: user.role,
            created_at: user.created / 1000,
            photo: user.avatar,
            profiles_count: user.managed && user.managed.length,
            web_app_version: process.env.REACT_APP_VERSION,
            web_app_platform: getPlatform(),
            fundraiser_id: event ? event.id : null,
            fundraiser_name: event ? event.name : null,
        }
    },

    openDialog(user) {
        // if (window.intercom) {
        //   window.intercom.displayMessenger();
        // } else {
        if (window.Intercom){
            this.registerUser(user);
            window.Intercom('show');
        }

    },

    setPushToken(token) {
        localStorage.setItem('fcmToken', token);
    },

    initializeIntercom(){
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
        } else {
            var d = document;
            var i = function () {
                i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args);
            };
            w.Intercom = i;
            var l = function () {
                var s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = 'https://widget.intercom.io/widget/' + APP_ID;
                var x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === 'complete') {
                l();
            } else if (w.attachEvent) {
                w.attachEvent('onload', l);
            } else {
                w.addEventListener('load', l, false);
            }
        }
    }

};

intercomAgent.initialize();



