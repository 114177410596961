import React from 'react';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { useSelector } from 'react-redux';
import { ReadingRecordList } from '../';
import { IState } from '../../types';
import styles from './ReadingRecords.module.scss';
import Button from "../UI/Button/Button";

const CustomizedAxisTick: React.FC<ICustomizedAxisTick> = ({x, y, payload}) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={6} y={0} dy={10} textAnchor='end' fill='#666' className={styles.customXAxisTick}>
        {payload.value}
      </text>
    </g>
  );
};

const ReadingRecords: React.FC = () => {
  const readingRecords = useSelector((state: IState) => state.readingRecords.data);

  const showAddReadingDialog = () => {
    document.dispatchEvent(new Event('logreading'));
  };
  return (
    <div className={styles.wrapperReadingRecords}>
      <span className={styles.readingRecordsTitle}>My reading record</span>
      {readingRecords &&
      <div className={styles.readingRecords}>
        {readingRecords.natural.length === 0 ?
          <div className={styles.noRecords}>
            <div>No records yet</div>
            <Button onClick={showAddReadingDialog}>Read now</Button>
          </div> :
          <ResponsiveContainer width='100%' height={127}>
            <BarChart margin={{top: 31, right: 0, bottom: 5, left: 0}} data={readingRecords.days}>
              <Tooltip/>
              <Bar barSize={15} dataKey='mins' fill={styles.chartColor} radius={2}/>
              <XAxis tick={CustomizedAxisTick} stroke='#dadada' tickLine={false} dataKey='weekDay'/>
            </BarChart>
          </ResponsiveContainer>
        }
      </div>}
      {readingRecords &&
      <ReadingRecordList readingRecords={readingRecords.natural}/>
      }
    </div>
  );
};

type ICustomizedAxisTick = {
  fill?: string;
  height: number;
  index: number;
  payload: {value: string; [key: string]: string | number};
  stroke: string;
  textAnchor: string;
  verticalAnchor: string;
  visibleTicksCount: number;
  width: number;
  x: number;
  y: number;
};

export default ReadingRecords;
