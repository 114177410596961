import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from '../../../types';
import YellowBook from '../../../assets/images/illustrations/YellowBook.svg';
import { getListsBooks } from '../../../store/Books/books.thunk';
import styles from './listBook.module.scss';
import BookList from '../BookList/BookList';
import Button from '../../../components/UI/Button/Button';
import { Link } from 'react-router-dom';

const ListBook = () => {
  const dispatch = useDispatch();
  const listBook = useSelector((state: IState) => state.books.data.listBook);
  useEffect(() => {
    dispatch(getListsBooks());
  }, []);

  if (!listBook) return <div className={styles.wrapperListBook}></div>;

  return (
    <div className={styles.wrapperListBook}>

      {listBook.length <= 1 && (
        <div className={styles.empty}>
          <img src={YellowBook} alt=''/>
          <p>You don't have booklists yet</p>
          <Link to={'/books/search'}>
            <Button onClick={() => {
            }}>Add your first book</Button>
          </Link>
        </div>
      )}

      {listBook.map((item: any) => (
        <BookList key={item.id} listBook={item}/>
      ))}

    </div>
  );
};

export default ListBook;
