import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../REST_API/listApi';
import { ITeam } from '../../types';

export const getSignUpData = createAsyncThunk('signUpData/getSignUpData', async (id: string) => {
  const response = await API.signUpData.getSignUpData(id);
  return response.data;
});

export const getCurrentTeam = createAsyncThunk('signUpData/getCurrentTeam', async (team: ITeam) => {
  return team;
});

export const deleteCurrentTeam = createAsyncThunk('signUpData/deleteCurrentTeam', () => {});
