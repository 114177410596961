import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import { getReadingRecords } from './readingRecords.thunk';
import { IReadingRecord, IReadingRecords } from '../../types';

interface IinitialState {
  error: boolean | string;
  data: null | IReadingRecords;
  loading: boolean;
}

const reducerReadingRecords = createSlice({
  name: 'readingRecords',
  initialState: { error: false, data: null, loading: false } as IinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReadingRecords.pending, (state: IinitialState) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getReadingRecords.fulfilled, (state: IinitialState, action) => {
      state.loading = false;
      const readingRecordsData = action.payload;
      const days = [];

      readingRecordsData.forEach((r: IReadingRecord) => (r.day = moment(r.created).format('L')));

      if (readingRecordsData.length > 0) {
        const lastDate = moment(readingRecordsData[0].created);
        // daily stats by last month
        for (let i = 0; i < 30; i++) {
          const day = lastDate.format('L');
          // calculate total mins for day
          const mins = readingRecordsData
            .filter((r: IReadingRecord) => r.day === day)
            .map((r: IReadingRecord) => r.mins || 0)
            .reduce((r1: number, r2: number) => r1 + r2, 0);
          days.push({day, mins, weekDay: moment(day).format('dd')});
          lastDate.add(-1, 'days');
        }
      }

      state.data = { natural: readingRecordsData, days: days.reverse() };
    });
    builder.addCase(getReadingRecords.rejected, (state: IinitialState) => {
      state.loading = false;
    });
  },
});

export default reducerReadingRecords.reducer;
