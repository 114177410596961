import React from 'react';

import { Button, Modal } from '../../';

import styles from './MemberModal.module.scss';

interface Props {
  memberName?: string,
  teamName?: string,
  shareLink?: string,
  cancel: () => void;
  isOpen: boolean;
}

const MemberModal: React.FC<Props> = ({
                                        memberName,
                                        teamName,
                                        shareLink,
                                        isOpen,
                                        cancel,
                                      }) => {

  return (
    <Modal styleModal={{minHeight: 200}}
           isOpen={isOpen} close={cancel}
    >
      <div className={styles.wraper}>
        <div className={styles.title}>{memberName}</div>
        <div className={styles.caption}>{teamName}</div>

        <a href={shareLink} target="_blank">
          <Button onClick={() => {
          }}>
            Donation page
          </Button>
        </a>
      </div>
    </Modal>
  );
};

export default MemberModal;
