import React, { useState } from 'react';
import moment from 'moment';
import NoBookCover from '../../../assets/images/illustrations/YellowBook.svg';
import { deleteReadinItem } from '../../../REST_API/addReadingApi';
import styles from './ReadingRecord.module.scss';
import { getProgressMy } from '../../../store/ProgressMy/progressMy.thunk';
import { Delete } from '../../../assets/images/icons';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from '../../../types';
import { ConfirmModal } from '../..';
import { getReadingRecords } from '../../../store/ReadingRecords/readingRecords.thunk';

interface IReadingRecord {
  created: number;
  mins: number;
  books : number;
  book: any;
  id: number;
}

const ReadingRecord: React.FC<IReadingRecord> = ({ created, mins,  books, book, id }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const progressMy = useSelector((state: IState) => state.progressMy.data);
  const date = moment(created);

  if (!progressMy) return null;

  const deleteReadingRecord = () => {
    setIsOpen(false);
    const success = () => {
      dispatch(getProgressMy());
      dispatch(getReadingRecords(progressMy.id));
    };
    deleteReadinItem(id, progressMy.id, success);
  };
  return (
    <div className={styles.ReadingRecord}>
      <img src={book ? book.image : NoBookCover}
           className={styles.ReadingRecordBookImage}
      />
      <div className={styles.ReadingRecordDate}>
        {moment(date).format('LL')}
        <br />
        {book ? <span className={styles.bookTitle}>{book.title}</span> : <span></span>}
      </div>
      {mins>0 &&
      <div className={styles.ReadingRecordTime}>{mins} min</div>}
      {books>0 &&
      <div className={styles.ReadingRecordTime}>Completed</div>}
      <Delete onClick={() => setIsOpen(true)} className={styles.ReadingRecordLine} />
      <ConfirmModal
        title={'Delete this record?'}
        confirm={deleteReadingRecord}
        cancel={() => setIsOpen(false)}
        isOpen={isOpen}
        textBTN={'Delete'}
        type={'Delete'}
      />
    </div>

  );
};

export default ReadingRecord;
