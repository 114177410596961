import React, { useEffect, useState, createContext } from 'react';
import { useHistory, withRouter, RouteComponentProps } from 'react-router-dom';

import { getSignUpData, deleteCurrentTeam } from '../../store/SignUpData/signUpData.thunk';
import NavigationSignUp from '../../navigation/NavigationSignUp';
import { IState } from '../../types';
import { ArrowIcon, Books } from '../../assets/images/icons';
import cn from 'classnames';
import styles from './SignUp.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import ChangeAvatarMob from './CreateReaderProfile/ChangeAvatar/ChangeAvatar';
import { CODE_LENGTH } from "./Code/Code";

interface ISignUp extends RouteComponentProps {
  children?: JSX.Element;
}

export const AppContext = createContext({} as any);

const SignUp: React.FC<ISignUp> = ({ history, children }) => {
  const dispatch = useDispatch();
  const state = useSelector((state: IState) => state.signUpData.currentTeam);

  const [code, setCode] = useState('');
  const { location } = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [avatar, setAvatar] = useState('');
  const [activePage, setActivePage] = useState(1);

  const list: string[] = [
    `/sign-up/join/code/${code || ''}`,
    `/sign-up/join/teams/${code || ''}`,
    `/sign-up/join/register/${state ? state.inviteCode : ''}`,
    `/sign-up/profile`,
    `/sign-up/personal`,
  ];

  useEffect((): any => {
    const activePage: number = list.findIndex((item: string) => item === location.pathname);
    setActivePage(activePage > 0 ? activePage : 0);

    return () => dispatch(deleteCurrentTeam());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (code && code.length === CODE_LENGTH) {
      dispatch(getSignUpData(code));
      setCode(code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const onClikBack = () => {
    if (location.pathname === '/forgot-password') {
      history.goBack();
      return;
    }
    if (activePage === 3) {
      return;
    } else if (activePage > 0) {
      history.push(list[activePage - 1]);
      setActivePage(activePage - 1);
    } else {
      history.push('/welcome');
    }
  };

  const isTeams =
    location.pathname.includes('sign-up/join/teams') ||
    location.pathname.includes('/sign-up/profile');
  const isRegister = location.pathname.includes('/sign-up/join/register');

  return (
    <div className={cn(styles.wrapper)}>
      <button onClick={onClikBack} className={styles.BTN_Back}>
        <ArrowIcon />
      </button>
      <Books className={styles.booksIcon} />
      <div className={cn(styles.openAvatar, isOpen && styles.openAvatarMob)}>
        <ChangeAvatarMob avatar={avatar} setAvatar={setAvatar} setIsOpen={setIsOpen} />
      </div>
      <div
        className={cn(
          styles.body,
          isOpen && styles.openAvatar,
          isTeams && styles.bodyTeams,
          isRegister && styles.bodyRegister
        )}
      >
        <AppContext.Provider
          value={{
            isOpen,
            setIsOpen,
            avatar,
            setAvatar,
            activePage,
            setActivePage,
            list,
          }}
        >
          {!children && <span className={styles.title}>Sign Up</span>}
          {children ? (
            children
          ) : (
            <NavigationSignUp page={{ activePage, setActivePage, setCode, code }} list={list} />
          )}
        </AppContext.Provider>
      </div>
    </div>
  );
};

export default withRouter(SignUp);
