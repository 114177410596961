import React, {useState, useEffect, useContext} from 'react';
import CountUp from 'react-countup';
import {useHistory} from 'react-router-dom';

import {IState} from '../../../types';
import Loader from '../../Loader/Loader';
import Button from '../../UI/Button/Button';

import time from '../icons/clock.svg';

import styles from '../AddReadingModal.module.scss';
import {useSelector, useDispatch} from 'react-redux';
import {getAchievements, getProgressMy} from '../../../store/ProgressMy/progressMy.thunk';
import {AppContext} from '../AddReadingModal';
import {getReadingRecords} from "../../../store/ReadingRecords/readingRecords.thunk";

type Props = {
  onClose: () => void;
};

const Goal: React.FC<Props> = ({onClose}) => {
  const {currentIndex, timeAdd, setCurrentIndex} = useContext(AppContext);
  const dispatch = useDispatch();
  const progressMy = useSelector((state: IState) => state.progressMy.data);

  useEffect(() => {
    return () => setCurrentIndex(0);
  }, []);

  const history = useHistory();
  const handleClose = () => {
    dispatch(getProgressMy());
    dispatch(getReadingRecords(progressMy?.id || 0));
    onClose();
    setTimeout(() => {
      history.push('/progress/my');
    }, 500);
  };

  if (!progressMy) return null;

  const {
    progress: {minsSum},
    team: {goals},
  } = progressMy;

  return (
    <>
      <h3 className={styles.title}>
        Good job!<br/> Keep reading!
      </h3>
      <div className={styles.middle}>
        {currentIndex > 1 && (
          <Loader
            diameter={208}
            valueEnd={(minsSum * 100) / goals.readingMinsPerMember}
            backroundColor='#00AEEF'
          >
            <div className={styles.goal}>
              <img className={styles.goalImage} alt='time' src={time}/>
              <span className={styles.goalLoader}>
                minutes read<br/>
                <span className={styles.textGoal}>
                  {' '}
                  {goals.readingMinsPerMember.toLocaleString()} goal
                </span>
              </span>
              <span className={styles.goalTotal}>
                <CountUp delay={0.5} start={minsSum} end={minsSum + timeAdd} duration={1.5}/>
              </span>
            </div>
          </Loader>
        )}
      </div>

      <Button onClick={handleClose}>Show my progress</Button>
    </>
  );
};

export default Goal;
