import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../types';
import { getProgressMy } from '../../store/ProgressMy/progressMy.thunk';
import NavigationProgress from '../../navigation/NavigationProgress';

import styles from './progress.module.scss';

const Progress: React.FC = () => {
  const dispatch = useDispatch();
  const progressMy = useSelector((state: IState) => state.progressMy.data);
  const location = useLocation().pathname;

  useEffect(() => {
    if (!progressMy && !location.includes('/progress/my')) {
      dispatch(getProgressMy());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.progressWrraper}>
      <div className={styles.progressContentWrraper}>
        <NavigationProgress/>
      </div>
    </div>
  );
};

export default Progress;
