import React, { useContext } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import cn from 'classnames';
import { Stepper, LoaderData } from '../../../components';

import styles from './ButtonNavigation.module.scss';

interface IButtonNavigation extends RouteComponentProps {
  list: string[];
  isDisabled?: boolean;
  onClick?: () => void;
  page: { activePage: number; setActivePage: (data: number) => void };
  isLoading?: boolean;
}

const ButtonNavigation: React.FC<IButtonNavigation> = ({
  history,
  list,
  isDisabled,
  onClick,
  page,
  isLoading,
}) => {
  const getButtonName = (index: number): string => {
    switch (index) {
      case 0:
        return 'Confirm';
      case 4:
        return 'Complete';
      default:
        return 'Next';
    }
  };

  const { activePage, setActivePage } = page;

  const LogIn = () => {
    if (page.activePage && setActivePage) {
      history.push(list[activePage - 1]);
      setActivePage(activePage - 1);
    } else {
      history.push('/welcome');
    }
  };

  const SignUp = () => {
    if (activePage < 4) {
      history.push(list[activePage + 1]);
      setActivePage(activePage + 1);
    }
  };

  const clickPage = () => {
    if (onClick) {
      onClick();
    } else {
      SignUp();
    }
  };

  return (
    <div className={cn(styles.listButton)}>
      <button type={'button'} onClick={() => LogIn()} className={styles.LogIn}>
        <span className={styles.text}>Back</span>
      </button>
      <div className={styles.stepper}>
        <Stepper value={activePage} />
      </div>
      <button
        type={'button'}
        onClick={() => !isDisabled && clickPage()}
        className={cn(styles.SignUp, isDisabled && styles.SignUpDisabled)}
      >
        <span className={styles.text}>
          {isLoading ? <LoaderData colorWhite={true} width={20} /> : getButtonName(activePage)}
        </span>
      </button>
      <div className={styles.block} />
    </div>
  );
};

export default withRouter(ButtonNavigation);
