import React from 'react';
import arrow from './arrow.svg';

import styles from './SettingsItems.module.scss';
import { intercomAgent } from "../../../intercom";
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from "../../../cordova-agent";

type Props = {
    user: any
};

const HelpAndSupport: React.FC<Props> = ({user}) => {
  const cordovaDevice = eval('window.device');
  const onContactSupport = () => {
    intercomAgent.openDialog(user);
  }
  let userAgent = navigator.userAgent;
  const isAndroid = /android/i.test(userAgent);
  const isIPhone = /iPhone/.test(userAgent);

  return (
    <div className={styles.wrapper}>
      <span className={styles.heading}>Help</span>
      <button onClick={onContactSupport} className={styles.card}>
        <div className={styles.wrapperTitle}>
          <span className={styles.title}>Contact support</span>
        </div>
        <div className={styles.arrow}>
          <img src={arrow} alt='arrow'/>
        </div>
      </button>
      <a className={styles.card} href={isAndroid ? GOOGLE_PLAY_LINK : APP_STORE_LINK} target="_blank">
        <div className={styles.wrapperTitle}>
          <span className={styles.title}>App version</span>
          <span
            className={styles.value}>{process.env.REACT_APP_VERSION} {cordovaDevice && `(${cordovaDevice.platform})`}</span>
        </div>
      </a>
    </div>
  );
};

export default HelpAndSupport;
