import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { IState } from '../types';
import ChooseTeam from '../screens/SignUp/ChooseTeam/ChooseTeam';
import Code from '../screens/SignUp/Code/Code';
import Register from '../screens/SignUp/CreateAccount/CreateAccount';
import { useSelector } from 'react-redux';

interface INavigation {
  page: {
    activePage: number;
    setActivePage: (page: number) => void;
    code: string;
    setCode: (code: string) => void;
  };
  list: string[];
}

const Navigation: React.FC<INavigation> = ({ page, list }) => {
  const data = useSelector((state: IState) => state.signUpData.data);
  return (
    <Switch>
      <Route exact path='/sign-up/join/code/:code'>
        <Code page={page} data={data} list={list} />
      </Route>
      <Route path='/sign-up/join/teams/:code'>
        <ChooseTeam page={page} event={data?.event} list={list} />
      </Route>
      <Route path='/sign-up/join/register/:code'>
        <Register page={page} list={list} />
      </Route>
      <Route exact path='/sign-up/join/'>
        <Redirect to='/sign-up/join/code/-' />
      </Route>
    </Switch>
  );
};

export default Navigation;
