import React from 'react';
import styles from './RadioButtons.module.scss';
import cn from 'classnames';
import { RadioButtonsOff, RadioButtonsOn } from '../../../assets/images/icons';

const RadioButtons: React.FC<{ isCheck: boolean }> = ({ isCheck }) => {
  return (
    <div className={cn(isCheck ? styles.CheckOn : styles.CheckOff)}>
      {isCheck ? <RadioButtonsOn /> : <RadioButtonsOff />}
    </div>
  );
};

export default RadioButtons;
