import styles from "../../../screens/Books/BookDetails/BookDetails.module.scss";
import { BookStore, BookStoreLink } from "../../../types";
import { ReactComponent as RWLogo } from '../../../assets/images/logo/RW_logo.svg';
import { ReactComponent as BetterBookLogo } from '../../../assets/images/logo/BetterBook.svg';
import React from "react";


export default function BookStoreButton({link}: { link: BookStoreLink }) {
  return <a
    href={link.link}
    target='_blank'
    className={styles.bookPrice}
  >
    {link.store == BookStore.BWB && <BetterBookLogo />}
    {link.store == BookStore.RW && <RWLogo/>}
    {link.price && (
      <div className={styles.bookPriceWrapper}>
        <span className={styles.price}>{link.store == BookStore.BWB ? <>Used</> : <>New</>}</span>
        <span className={styles.priceValue}>${link.price}</span>
      </div>
    )}
  </a>
}
