import { fetchStart, fetchSuccess } from './actionsIndicators';
import { createSlice } from '@reduxjs/toolkit';

interface IinitialState {
  isLoading: boolean;
}

export const reducerIndicators = createSlice({
  name: 'indicators',
  initialState: { isLoading: false } as IinitialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchStart, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSuccess, (state) => {
        state.isLoading = false;
      }),
});

export default reducerIndicators.reducer;
