import React from 'react';
import styles from './CardBook.module.scss';
import { Menu } from '../../../assets/images/icons';
import { Link } from "react-router-dom";
import { Button } from "../../../components";
import BookStoreButton from "../../../components/books/BookStoreLinks";
import { BookStoreLink } from "../../../types";

interface ICardBook {
  book: any;
  buyButton?: boolean;
  externalLink?: boolean;
}

const CardBook: React.FC<ICardBook> = ({book, buyButton, externalLink}) => {
  const {image, author, title, pageCount} = book;
  return (
    <Link to={`/books/${book.isbn}`} className={styles.wrapper}>
      <img className={styles.image} src={image} alt=''/>
      <div className={styles.listText}>
        <span className={styles.author}>{author}</span>
        <span className={styles.title}>{title}</span>
        <span className={styles.pageCount}>{pageCount} pages</span>
      </div>
    </Link>
  );
};

export default CardBook;
