import React from 'react';
import { buildStyles,CircularProgressbarWithChildren } from 'react-circular-progressbar';

import { AnimatedProgressProvider } from '../';

import 'react-circular-progressbar/dist/styles.css';
import styles from './Loader.module.scss';

interface IProgressMyLoader {
  valueStart?: number;
  valueEnd: number;
  backroundColor: string;
  pathColor?: string;
  diameter?: number;
  children?: JSX.Element;
  rotation?: number;
  width?: number;
}

const Loader: React.FC<IProgressMyLoader> = ({
  valueStart = 0,
  valueEnd,
  backroundColor = '#00AEEF',
  pathColor = 'rgba(196, 202, 209, 0.6)',
  diameter,
  children,
  rotation = 0.75,
  width = 4,
}) => {
  return (
    <div className={styles.wrapperLoader} style={diameter ? { width: diameter } : {}}>
      <AnimatedProgressProvider duration={1} valueStart={valueStart} valueEnd={valueEnd > 100 ? 100 : valueEnd}>
        {(value: number) => (
          <CircularProgressbarWithChildren
            value={value}
            circleRatio={rotation}
            strokeWidth={width}
            styles={
              rotation !== 0.75
                ? buildStyles({ pathColor: backroundColor, trailColor: pathColor })
                : buildStyles({
                    rotation: 1 / 2 + 1 / 8,
                    pathColor: backroundColor,
                    trailColor: pathColor,
                  })
            }>
            {children}
          </CircularProgressbarWithChildren>
        )}
      </AnimatedProgressProvider>
    </div>
  );
};

export default Loader;
