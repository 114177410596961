import React from 'react';

import styles from './BooksWishlist.module.scss';
import { IUser, ICardBook, BookStoreLink } from "../../../../types";

import CardBook from "../../../Books/CardBook/CardBook";
import BookStoreButton from "../../../../components/books/BookStoreLinks";

interface Props {
  user: IUser,
  book: ICardBook
}

const BooksWishlist = ({user, book}: Props) => {

  return (
    <div className={`${styles.BooksWishlist}`}>
      <div className={styles.title}>
        Keep {user.firstName} reading by purchasing a book from their wish list!
      </div>

      <p>
        Below are recommended books that {user.firstName} would like to start reading to support their read-a-thon.
      </p>

      <div className={styles.list}>
        <CardBook book={book}
                  externalLink={true}
                  buyButton={true}
                  key={book.id}/>

        <div className="styles">
          {book.storeLinks?.map((store: BookStoreLink) =>
            <BookStoreButton link={store} key={store.store}/>
          )}
        </div>

      </div>

    </div>
  );
};


export default BooksWishlist;
