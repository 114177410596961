import { createSlice } from '@reduxjs/toolkit';
import { getListTeam } from './listTeam.thunk';
import { IEvent } from '../../types';

interface IinitialState {
  error: boolean | string;
  data: null | IEvent;
  loading: boolean;
}

const reducerListTeam = createSlice({
  name: 'listTeam',
  initialState: { error: false, data: null, loading: false } as IinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getListTeam.pending, (state: IinitialState) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getListTeam.fulfilled, (state: IinitialState, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getListTeam.rejected, (state: IinitialState) => {
      state.loading = false;
    });
  },
});

export default reducerListTeam.reducer;
