import { axiosInstance } from './';
import load from './';

export default {
  getrecommendedBooksData() {
    return axiosInstance.get('/api/v1/books/recommended/lists');
  },
  getlistsBooksData(userId?: number) {
    return axiosInstance.get('/api/v1/books/lists' + (userId ? `?userId=${userId}` : ''));
  },
  getlistsData(id: number) {
    return axiosInstance.get(`/api/v1/books/lists/${id}`);
  },
  getSearchBooks(text: string) {
    const isbn = text.replace(/\D/g, '').trim();
    if (isbn.length === 13) {
      return axiosInstance.get(`/api/v1/books/search?isbn=${isbn}`);
    } else {
      return axiosInstance.get(`/api/v1/books/search?title=${text}`);
    }
  },
  getBookDetails(id: number) {
    return axiosInstance.get(`/api/v1/books/${id}`);
  },
};

export const setBookStatus = async (typeLoad: string, url: string, succses: () => void) => {
  const res = await load(typeLoad, url);

  if (res.ok) {
    succses();
  }
};
