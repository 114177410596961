import * as firebase from 'firebase';
import {agent} from "./cordova-agent";


export const analytics = {

  initialize() {
    document.addEventListener('userloaded', this.onUserLoaded.bind(this), false);
    this.initAnalytics();
  },

  onUserLoaded(e) {
    let user = e.detail;
    if (user) {
      firebase.analytics().setUserId(user.id);
    }
  },


  initAnalytics() {
    var firebaseConfig = {
      apiKey: "AIzaSyD1DCes0dCp81E337Aw1dUsG3rXVagda7M",
      authDomain: "rfms-mobile-app.firebaseapp.com",
      databaseURL: "https://rfms-mobile-app.firebaseio.com",
      projectId: "rfms-mobile-app",
      storageBucket: "rfms-mobile-app.appspot.com",
      messagingSenderId: "928953897642",
      appId: "1:928953897642:web:80a76dc1eb2e1c0304b6d3",
      measurementId: "G-GE9FGBB79P"
    };

    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  },

  logEvent(event, params) {

    if(params) params.platform = getPlatform();
    console.info('logEvent', event, params);


    firebase.analytics().logEvent(event, params);

    if (!event.startsWith('view_')) {
      if (window.intercom) {
        window.intercom.logEvent(event, params);
      } else if (window.Intercom) {
        window.Intercom('trackEvent', event, params);
      }
    }
  },

  logView(view_item) {
    this.logEvent("view_item", {screen_name: view_item});
    this.logEvent('view_' + view_item)
  },

};

analytics.initialize();



export function getPlatform() {
  let userAgent = navigator.userAgent;
  let platform = '';
  if (/android/i.test(userAgent)) platform = '_android';
  if (/iPhone/.test(userAgent)) platform = '_iphone';
  return agent.cordova ? 'native' + platform : 'web' + platform;
}
