import React, { useContext } from 'react';

import Button from '../../UI/Button/Button';

import progressIcon from '../icons/progress.png';

import styles from '../AddReadingModal.module.scss';
import { getAddReading } from "../../../store/AddReading/addReading.thunk";
import { deleteBook } from "../../../store/Books/books.thunk";
import { useDispatch, useSelector } from "react-redux";
import { ICardBook, IState } from "../../../types";
import { AppContext } from "../AddReadingModal";

type Props = {
  setCurrentSlide: (value: number) => void;
};

const CompleteConfirm: React.FC<Props> = ({ setCurrentSlide}) => {
  const dispatch = useDispatch();
  const {currentBook} = useContext(AppContext);
  const progressMy = useSelector((state: IState) => state.progressMy.data);
  const bookshelf = useSelector((state: IState) =>
    state.books.data.listBook?.find((item) => item.type === 'bookshelf')
  );
  const book = bookshelf?.books.find(book=>book.id===currentBook)

  const finishedBook = () => {
    if (currentBook)
      dispatch(
        getAddReading({
          id: progressMy ? progressMy.id : 0,
          mins: 0,
          books: 1,
          bookId: currentBook,
          setIsRequestSuccess: () => {
            dispatch(deleteBook(currentBook));
          },
        })
      );
    setCurrentSlide(4);
  };

  return (
    <>
      <h3 className={styles.title}>
        Did you finish the book?
      </h3>
      <div className={styles.bookCover}>
        <img src={book?.image}/>
      </div>

      <div>
        <Button onClick={() => finishedBook()} type='link'>Yes, I’ve finished the book</Button>
        <Button onClick={() => setCurrentSlide(4)}>No, I'm still reading the book</Button>
      </div>
    </>
  );
};

export default CompleteConfirm;
