import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ErrorModal, FormatError, LoaderData, Modal } from '../../components';
import {
  deleteCreateReaderProfile,
  getCreateReaderProfile,
} from '../../store/CreateReaderProfile/createReaderProfile.thunk';
import { getListTeam } from '../../store/listTeam/listTeam.thunk';
import { changeUser } from '../../REST_API/userApi';
import { IProfile, IState } from '../../types';
import ChangeAvatar from './ChangeAvatar/ChangeAvatar';
import DeleteProfile from './DeleteProfile/DeleteProfile';
import Form from './Form/Form';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../store/User/user.thunk';
import { ArrowIcon } from '../../assets/images/icons';
import { useMediaQuery } from 'react-responsive';
import styles from './CreateReaderProfile.module.scss';

interface MatchParams {
  id?: string | undefined;
}

interface ICreateReaderProfile extends RouteComponentProps<MatchParams> {
  title: string;
}

const CreateReaderProfile: React.FC<ICreateReaderProfile> = ({ title, history, match }) => {
  const dispatch = useDispatch();
  const events = useSelector((state: IState) => state.events.data);
  const user = useSelector((state: IState) => state.createReaderProfile.data);
  const listTeam = useSelector((state: IState) => state.listTeam.data);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [error, setError] = useState<false | string>(false);
  const [active, setActive] = useState<string>('form');
  const [avatar, setAvatar] = useState('');
  const adapt = useMediaQuery({
    query: '(min-device-width: 1100px)',
  });

  useEffect(() => {
    if (events) {
      if (match.params.id) {
        dispatch(getCreateReaderProfile(`/api/v1/users/${match.params.id}`));
      }
      dispatch(getListTeam(events.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  useEffect(() => {
    if (user) setAvatar(user.avatar);
  }, [user]);

  useEffect((): any => {
    return () => dispatch(deleteCreateReaderProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values: IProfile) => {
    const currentValues: IProfile = {
      teamId: values.team,
      firstName: values.firstName,
      lastName: values.lastName,
      displayName: values.displayName,
      personalMessage: values.personalMessage,
      avatar,
    };
    setIsLoading(true);

    const succses = () => {
      setIsLoading(false);
      setIsSuccessful(true);
      dispatch(getUser());

      setTimeout(() => {
        history.push('/settings');
      }, 600);
    };

    const error = (data: any) => {
      setError(FormatError(data));
      setIsLoading(false);
    };

    changeUser(
      match.params.id ? 'PUT' : 'POST',
      match.params.id ? `/api/v1/users/${match.params.id}` : '/api/v1/users/me/managed',
      currentValues,
      succses,
      error
    );
  };

  if (!events || (match.params.id && !user))
    return (
      <div className={styles.wrapperLoaderData}>
        <LoaderData width={60} />
      </div>
    );
  if (!listTeam)
    return (
      <div className={styles.wrapperLoaderData}>
        <LoaderData width={60} />
      </div>
    );

  const showeDelete = user && !user.email && active === 'form';
  const showeAvatar = !adapt && active === 'avatar';

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div
          onClick={() => (active === 'avatar' ? setActive('form') : history.goBack())}
          className={styles.BTN_Back}
        >
          <ArrowIcon />
        </div>
        <span className={styles.title}>{active === 'form' ? title : 'Choose avatar'}</span>
      </div>
      <div className={active !== 'form' ? styles.closedForm : ''}>
        <Form
          user={user}
          onSubmit={active === 'form' ? onSubmit : () => {}}
          listTeam={listTeam}
          setShowSelect={setShowSelect}
          showSelect={showSelect}
          isLoading={isLoading}
          isSuccessful={isSuccessful}
          events={events}
          setActive={setActive}
          avatar={avatar}
        />
      </div>

      <Modal
        classModal={styles.modalAvatar}
        styleModal={{ width: 845, height: 700, marginBottom: -200 }}
        isOpen={adapt && active === 'avatar'}
        close={() => adapt && active === 'avatar' && setActive('form')}
      >
        <>
          {adapt && active === 'avatar' && (
            <>
              <span className={styles.titleModal}>Choose avatar</span>
              <ChangeAvatar url={avatar} setAvatar={setAvatar} setActive={setActive} />
            </>
          )}
        </>
      </Modal>

      {showeAvatar && <ChangeAvatar url={avatar} setAvatar={setAvatar} setActive={setActive} />}
      {showeDelete && <DeleteProfile />}
      <ErrorModal title={error || ''} cancel={() => setError(false)} isOpen={!!error} />
    </div>
  );
};

export default withRouter(CreateReaderProfile);
