import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import styles from './Achievements.module.scss';
import progressMyApi from "../../REST_API/progressMyApi";
import {IAchievement, IState} from "../../types";
import AchievementListItem from "./Achievement/AchievementListItem";
import {useSelector} from "react-redux";


const Achievements: React.FC = ({}) => {
    const [isOpen, setIsOpen] = useState(false);
    const list = useSelector((state: IState) => state.progressMy.achievements);

    return (
      <div className={styles.wrapperAchievement}>
        <div className={styles.headerAchievement}>
          <span className={styles.headerAchievementTitle}>My achievements</span>
          <button className={styles.showAllBtn} onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? 'Hide' : 'Show'} all
          </button>
        </div>

        {list && list.length > 0 ?
          <div className={cn(styles.listAchievement, isOpen && styles.listAchievementOpen)}>
            {list.map((item: IAchievement) => (
              <AchievementListItem key={item.id} achievement={item}/>
            ))}
          </div> :
          <div className={cn(styles.listAchievement, isOpen && styles.listAchievementOpen)}>
            {[1, 2, 3, 4, 5, 6, 7, 8].map(i => (
              <div className={styles.mockItem}/>
            ))}
          </div>
        }
      </div>
    );
  }
;

export default Achievements;
