import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getRecordsWithProgressMy} from '../../store/ProgressMy/progressMy.thunk';
import {Achievements, Achievements2, ProgressLoader, ReadingRecords} from '../../components';

import {IState} from '../../types';

const ProgressMy: React.FC = () => {
  const dispatch = useDispatch();
  const progressMy = useSelector((state: IState) => state.progressMy.data);
  useEffect(() => {
    dispatch(getRecordsWithProgressMy());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!progressMy) return null;

  const {
    progress: { minsSum, raisedSum },
    team: { goals },
    achievements,
  } = progressMy;
  
  return (
    <>
      <ProgressLoader
        valueCoin={raisedSum}
        valueTime={minsSum}
        totalValueTime={goals.readingMinsPerMember}
        totalValueCoin={goals.fundraisingPerMember}
      />
      {/*<Achievements goals={goals} achievements={achievements} />*/}
      <Achievements2 />
      <ReadingRecords />
    </>
  );
};

export default ProgressMy;
