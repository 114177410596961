import React, {useEffect, useState} from 'react';

import logoIcon from '../../../../assets/images/logo/color_logo_icon.svg';
import styles from './Tips.module.scss';
import {Input, Select} from "../../../../components";
import {getPublicEvent} from "../../../../store/DonationPage/thunks";
import {analytics} from "../../../../analytics";
import {formatAmount} from "../../../../components/UI/GetCurrency/GetCurrency";

interface Props {
  schoolName: string;
  amount: number;
  options: string;
  currency: string;
  onChangeTipsAmount: (amount: number) => void
}

const DEF_TIPS_OPTIONS = [20, 15, 10];

const Tips = ({schoolName, amount, currency, options, onChangeTipsAmount}: Props) => {

  const [tipsVariant, setTipsVariant] = useState(-1);
  const [otherValue, setOtherValue] = useState(0);

  let optionsArr = DEF_TIPS_OPTIONS;
  let defOption = 1;
  try {
    optionsArr = options.split(",").map((o, i) => {
      if (o.indexOf("default") !== -1) defOption = i;
      if (o.indexOf("custom") !== -1) return 0;
      return parseInt(o.trim().replace("(default)", "").trim());
    })
  } catch (e) {
    console.error(e);
  }

  const values = optionsArr.map(percent => {
    if (percent === 0) return {value: 0, option: 'Custom Tip'};
    return {
      value: amount * (percent / 100),
      option: `${percent}% tip (${formatAmount((amount * (percent / 100)).toFixed(2), currency)})`
    }
  });

  useEffect(() => {
    setTipsVariant(defOption);
    onChangeTipsAmount(values[defOption].value);
    setOtherValue(values[defOption].value);
  }, []);

  useEffect(() => {
    if (tipsVariant >= 0)
      onChangeTipsAmount(values[tipsVariant].value);
  }, [amount]);

  return (
    <div className={`${styles.tips}`}>
      <img src={logoIcon} className={styles.icon}/>
      <div className={styles.title}>
        Help us keep kids reading and put 100% of your donation to work for your school!
      </div>
      <div className={styles.text}>
        Your generosity helps fund the costs of the campaign and allows us to provide
        a FREE fundraising platform so {schoolName} can fully utilize your donation.
      </div>

      <div className={styles.form}>
        <div>
          <Select value={tipsVariant} onChange={(i) => {
            setTipsVariant(i);
            if (i < optionsArr.length) onChangeTipsAmount(values[i].value);
            if (optionsArr[i] === 0) onChangeTipsAmount(otherValue)
          }}>

            {values.map((item, i) =>
              <option key={i} value={i}>
                {item.option}
              </option>
            )}

          </Select>
        </div>
        {optionsArr[parseInt(tipsVariant.toString())] === 0 &&
        <div className={styles.input}>
          <Input value={formatAmount((otherValue).toFixed(2), currency)}
                 onChange={(e: any) => {
                   let string = e.target.value.replace(/\D/g, '');
                   let number = parseFloat(string || 0) / 100;
                   setOtherValue(number);
                   onChangeTipsAmount(number);
                 }}/>
        </div>
        }
      </div>
    </div>
  );
};

export default Tips;
