import React, { useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useSwipeable } from 'react-swipeable';
import cn from 'classnames';

import { Button, Portal } from '../';

import styles from './Modal.module.scss';

interface IModal {
  title?: string;
  isOpen: boolean;
  onClickBTN?: () => void;
  children?: JSX.Element;
  textBTN?: string;
  close: () => void;
  styleModal?: React.CSSProperties;
  classModal?: string;
}

const Modal1: React.FC<IModal> = ({
                                    title,
                                    isOpen,
                                    onClickBTN,
                                    children,
                                    textBTN,
                                    close,
                                    styleModal = {},
                                    classModal = '',
                                  }) => {
  const handlers = useSwipeable({
    onSwipedDown: () => close(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  useEffect(() => {
    setTimeout(() => {
      document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    }, 100);
  }, [isOpen]);
  return (
    <Portal>
      <div
        {...handlers}
        className={cn(styles.modalOverlay, isOpen ? styles.fadein : styles.fadeOut)}
      >
        <OutsideClickHandler onOutsideClick={() => close()}>
          <div style={styleModal} className={cn(styles.modalWindow, classModal)}>
            {title && <div className={styles.modalTitle}>{title}</div>}
            {children}
            {textBTN && (
              <div className={styles.modalFooter}>
                <Button onClick={() => {
                  close();
                  if (onClickBTN) onClickBTN();
                }}>{textBTN}</Button>
              </div>
            )}
          </div>
        </OutsideClickHandler>
      </div>
    </Portal>
  );
};

export default Modal1;
