import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

import Button from '../../UI/Button/Button';
import { getAddReading } from '../../../store/AddReading/addReading.thunk';
import { deleteBook } from '../../../store/Books/books.thunk';
import './SelectBook.scss';
import styles from '../AddReadingModal.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IState, ICardBook } from '../../../types';

type Props = {
  setcurrentBook: (value: number) => void;
  currentBook: number | undefined;
  setCurrentSlide: (value: number) => void;
};

const SelectBook: React.FC<Props> = ({setcurrentBook, currentBook, setCurrentSlide}) => {
  const dispatch = useDispatch();
  const progressMy = useSelector((state: IState) => state.progressMy.data);
  const bookshelf = useSelector((state: IState) =>
    state.books.data.listBook?.find((item) => item.type === 'bookshelf')
  );

  const [isCompleteConfirm, setIsCompleteConfirm] = useState<boolean>(false);
  if (!bookshelf?.books) return null;

  let arr: any[] = [...bookshelf.books];
  if (bookshelf) {
    arr.push({
      id: -1,
      title: 'No book? Log minutes here.'
    });
  }
  let currentId = 0;

  const settings = {
    className: 'select-book-slider',
    centerMode: true,
    infinite: arr.length > 2 ? true : false,
    slidesToShow: arr.length < 2 ? 2 : 1,
    variableWidth: true,
    adaptiveHeight: true,
    focusOnSelect: true,
  };

  const handleSelect = (index: number) => {
    setcurrentBook(arr[index].id);
    currentId = arr[index].id;
    console.log('arr[index].id '+arr[index].id)
  };

  const handleClick = () => {
    localStorage.setItem(
      'currentBookId',
      currentId === 0 ? arr[0].id.toString() : currentId.toString()
    );
    setCurrentSlide(2);
    if (currentId === 0) setcurrentBook(arr[0].id);
  };

  const getList = () => {
    const currentId = Number(localStorage.getItem('currentBookId'));
    if (!localStorage.getItem('currentBookId')) {
      return arr;
    }

    if (arr[0].id !== currentId)
      arr = arr.sort(function (x: ICardBook, y: ICardBook) {
        return x.id === currentId ? -1 : y.id === currentId ? 1 : 0;
      });

    return arr;
  };

  const finishedBook = () => {
    let bookId = currentId || getList()[0].id;
    dispatch(
      getAddReading({
        id: progressMy ? progressMy.id : 0,
        mins: 0,
        books: 1,
        bookId: bookId > 0 ? bookId : undefined,
        setIsRequestSuccess: () => {
          dispatch(deleteBook(currentId || getList()[0].id));
        },
      })
    );
    setCurrentSlide(3);
  };

  return (
    <>
      <h3 className={styles.title}>Please select the book<br/> you are reading now.</h3>
      <Slider {...settings} afterChange={handleSelect}>
        {getList().map((book: any) => (
          <div key={book.id} className='select-book-slider__item'>
            <div className='select-book-slider__image'>
              {book.image ? <img src={book.image} alt=''/> :
                <div className='select-book-slider__noimage'><label>{book.title}</label></div>}
            </div>
          </div>
        ))}
      </Slider>

        <div>
          <Button onClick={handleClick}>Next</Button>
        </div>

    </>
  );
};

export default SelectBook;
