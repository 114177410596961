import React, { useEffect, useState } from 'react';
import styles from './BookDetails.module.scss';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import stylesGlobal from '../Books.module.scss';
import { LoaderData } from '../../../components';
import { BookStore, BookStoreLink, compareBookStoreLink, ICardBook, IState } from '../../../types';
import { deleteBookDetails, getBookDetails } from '../../../store/Books/books.thunk';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowIcon} from '../../../assets/images/icons';
import cn from 'classnames';
import PoweredbyGoogleImg from '../../../assets/images/logo/poweredby_google.png';
import StatusBook from './StatusBook/StatusBook';
import BookStoreButton from "../../../components/books/BookStoreLinks";

interface MatchParams {
  isbn: string;
}

interface IBookDetails extends RouteComponentProps<MatchParams> {
}

const BookDetails: React.FC<IBookDetails> = ({match, history}) => {
  const book: ICardBook = useSelector((state: IState) => state.books.data.bookDetails);
  const error = useSelector((state: IState) => state.books.error);
  const [showDescription, setshowDescription] = useState(false);
  const dispatch = useDispatch();

  useEffect((): any => {
    window.scrollTo(0, 0);
    dispatch(getBookDetails(+match.params.isbn));
    return () => dispatch(deleteBookDetails());
  }, []);

  if (error)
    return (
      <div className={styles.wrapper}>
        <button onClick={() => history.goBack()} className={styles.BTN_Back}>
          <ArrowIcon/>
        </button>
        <div className={styles.wrapperError}>
          <span className={styles.errorText}>
            Unfortunately, we found no book in our database with ISBN {match.params.isbn}. Please
            try to find this one by the title
          </span>
        </div>
      </div>
    );

  if (!book)
    return (
      <div className={stylesGlobal.wrapperLoaderData}>
        <LoaderData width={40}/>
      </div>
    );

  // const getPriceValue = () => {
  //   let lowestPrice = book.bwbBook?.usedPrice || book.bwbBook?.newPrice;
  //   return lowestPrice != '$0.00' ? lowestPrice : null;
  // };

  const getStoreLink = (store: BookStore): BookStoreLink | undefined => {
    return book.storeLinks.find(it => it.store === store)
  };

  return (
    <div className={styles.wrapper}>
      <button onClick={() => history.goBack()} className={styles.BTN_Back}>
        <ArrowIcon/>
      </button>
      <div className={styles.header}>
        <img className={styles.img} src={book.image} alt=''/>
        <span className={styles.title}>{book.title}</span>
        <span>by {book.author}</span>
      </div>
      <StatusBook data={book.lists} id={book.isbn}/>

      {book.storeLinks.map(store =>
        <BookStoreButton link={store} key={store.store} />
      )}

      <span className={styles.addition}>
        The pricing is provided by <a href="https://www.betterworldbooks.com/"
                                      target="_blank">BetterWorldBooks</a>, &nbsp;
        <a href="https://www.thereadingwarehouse.com" target="_blank">TheReadingWarehouse</a>
      </span>

      <span
        onClick={() => setshowDescription(!showDescription)}
        dangerouslySetInnerHTML={{__html: book.description}}
        className={cn(styles.description, showDescription && styles.showDescription)}
      ></span>
      <div className={styles.details}>
        <div className={styles.detailsItem}>
          <span className={styles.itemTitle}>Publisher</span>
          <span className={styles.itemText}>{book.publisher}</span>
        </div>
        <div className={styles.detailsItem}>
          <span className={styles.itemTitle}>Published on</span>
          <span className={styles.itemText}>{book.publishedDate}</span>
        </div>
        <div className={styles.detailsItem}>
          <span className={styles.itemTitle}>ISBN</span>
          <span className={styles.itemText}>{book.isbn}</span>
        </div>
        <div className={styles.detailsItem}>
          <span className={styles.itemTitle}>Pages</span>
          <span className={styles.itemText}>{book.pageCount}</span>
        </div>
        {book.categories &&
            <div className={styles.detailsItem}>
                <span className={styles.itemTitle}>Genres</span>
                <span className={styles.itemText}>{book.categories}</span>
            </div>
        }
      </div>

      <span className={styles.addition}>
        The content is provided by <a href="https://www.betterworldbooks.com/"
                                      target="_blank">BetterWorldBooks</a>, <a
        href="https://developers.google.com/books" target="_blank">Google Books API</a> and <a
        href="http://openlibrary.org/" target="_blank">OpenLibrary API</a><br/><br/>

          <a href={book.infoLink ? book.infoLink : `https://www.google.ru/search?tbm=bks&hl=en&q=${book.isbn}`}
             target="_blank">
            <img src={PoweredbyGoogleImg}/>
          </a>

      </span>
    </div>
  );
};

export default withRouter(BookDetails);
