import React, { useState, useEffect, useContext } from 'react';
import { Input, Modal, Checkbox, FormatError } from '../../../components';
import ChangeAvatar from '../../CreateReaderProfile/ChangeAvatar/ChangeAvatar';
import defaultAvatar from '../../../assets/images/icons/defaultAvatar.svg';
import styles from '../SignUp.module.scss';
import ButtonNavigation from '../ButtonNavigation/ButtonNavigation';
import { Formik } from 'formik';
import { Field } from 'formik';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { IState, Action } from '../../../types';
import { getUser } from '../../../store/User/user.thunk';
import { changeUser } from '../../../REST_API/userApi';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { AppContext } from '../SignUp';
import { getEvents } from '../../../store/Events/events.thunk';
import { analytics } from "../../../analytics";
import { getListsBooks } from "../../../store/Books/books.thunk";

interface IData {
  firstName?: string;
  lastName?: string;
  displayName?: string;
  avatar?: string;
}

const CreateReaderProfile: React.FC<RouteComponentProps> = ({history}) => {
  const {avatar, setAvatar, isOpen, setIsOpen, page, list, setActivePage} = useContext(
    AppContext
  );

  // const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state: IState) => state.user.data);

  useEffect(() => {
    analytics.logView('sign_up_profile');
    dispatch(getUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const adapt = useMediaQuery({
    query: '(min-width: 750px)',
  });

  // useEffect(() => {
  //   if (isOpen) {
  //     setShow(true);
  //   } else {
  //     setTimeout(() => {
  //       setShow(false);
  //     }, 1000);
  //   }
  // }, [isOpen]);

  const onSubmit = (values: IData, actions: Action) => {

    if (!avatar) {
      setIsOpen(true);
      return;
    }
    const currentValues: IData = {
      firstName: values.firstName,
      lastName: values.lastName,
      displayName: values.displayName || values.firstName,
      avatar,
    };
    setIsLoading(true);

    const succses = () => {
      setIsLoading(false);
      history.push('/sign-up/personal');
      setActivePage(4);
      dispatch(getUser());
      dispatch(getEvents());
      dispatch(getListsBooks());
      analytics.logEvent('sign_up_profile_created');
    };

    const error = (data: any) => {
      actions.setFieldError('firstName', FormatError(data));
      setIsLoading(false);
    };

    changeUser('PUT', '/api/v1/users/me', currentValues, succses, error);
  };

  useEffect(() => {
    if (user) setAvatar(user?.avatar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (!user) return <div className={styles.filler}></div>;

  return (
    <>
      <span className={styles.title}>Sign Up</span>
      <span className={styles.text}>Create reader profile</span>
      <button onClick={() => setIsOpen(true)} className={styles.wrapperAvatar}>
        <img className={styles.img} src={avatar || defaultAvatar} alt='avatar'/>
        <span className={styles.text}>{avatar? 'Change avatar' : 'Choose avatar'}</span>
      </button>
      <Formik
        initialValues={{
          firstName: user?.firstName || '',
          lastName: user?.lastName || '',
          displayName: user?.displayName || '',
        }}
        onSubmit={onSubmit}
      >
        {({handleSubmit, submitForm, values}: any) => (
          <form className={styles.listInput} onSubmit={handleSubmit}>
            <Field name='firstName'>
              {({field, meta}: any) => (
                <div>
                  <Input meta={meta} placeholder='First name' {...field} />
                </div>
              )}
            </Field>
            <Field name='lastName'>
              {({field, meta}: any) => (
                <div>
                  <Input meta={meta} placeholder='Last name' {...field} />
                </div>
              )}
            </Field>
            <Field name='displayName'>
              {({field, meta}: any) => (
                <div>
                  <Input meta={meta} placeholder='Display name' {...field} />
                </div>
              )}
            </Field>
            <div className={styles.contract}>
              <button type='button' onClick={() => setIsChecked(!isChecked)}>
                <Checkbox isChecked={isChecked}/>
              </button>
              <span>
                I confirm that I'm over the age of 13 and agree to the RFMS{' '}
                <span className={styles.link}>
                  <a
                    rel='noopener noreferrer'
                    target='_blank'
                    href='https://www.readformyschool.com/toolkit/privacy-policy/'>
                    Privacy Policy
                  </a>
                </span>{' '}
                and{' '}
                <span className={styles.link}>
                  <a
                    rel='noopener noreferrer'
                    target='_blank'
                    href='https://www.readformyschool.com/toolkit/tou'
                  >
                    Terms of Use
                  </a>
                </span>
              </span>
            </div>
            <ButtonNavigation
              isLoading={isLoading}
              isDisabled={
                !(values.firstName && values.lastName && isChecked)
              }
              list={list}
              onClick={submitForm}
              page={{...page, activePage: 3}}
            />
          </form>
        )}
      </Formik>
      <Modal
        classModal={styles.modalAvatar}
        styleModal={{width: 845, height: 700, marginBottom: -200}}
        isOpen={adapt && isOpen}
        close={adapt ? () => setIsOpen(false) : () => {
        }}
      >
        <>

              <span className={styles.titleModal}>Choose avatar</span>
              <ChangeAvatar url={avatar} setAvatar={setAvatar} setActive={() => setIsOpen(false)}/>

        </>
      </Modal>
    </>
  );
};

export default withRouter(CreateReaderProfile);
