import React, { useEffect } from 'react';

import { Donation, Events } from '../../components';

import { IDonations } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../types';
import { getDonations } from '../../store/Donations/donations.thunk';
import styles from './Main.module.scss';
import Button from '../../components/UI/Button/Button';
import { isAuthorized } from "../../REST_API/userApi";
import { getUser } from "../../store/User/user.thunk";
import { getEvents } from "../../store/Events/events.thunk";
import { getListsBooks } from "../../store/Books/books.thunk";
import ToDoList from "../../components/ToDoList";

const Main: React.FC = () => {
  const dispatch = useDispatch();
  const donations = useSelector((state: IState) => state.donations.data);
  const event = useSelector((state: IState) => state.events.data);

  useEffect(() => {
    if (isAuthorized()) {
      dispatch(getDonations());
    }else {
      window.location.href='/';
    }
  }, []);
  if (!donations) return null;

  return (
    <div className={styles.wrapperMain}>
      <ToDoList/>
      <Events />
      <span className={styles.headingMain}>My Donations</span>

      {donations.length > 0 ? (
        <>
          {donations.map((item: IDonations, index: number) => (
            <Donation key={index} index={index} donation={item} />
          ))}
        </>
      ) : (
        <div className={styles.noDonations}>
          <div>No donations yet</div>
          <a href={(event && event.shareLink) + '/donate'} target='_blank'>
            <Button onClick={() => {}}>Pledge now</Button>
          </a>
        </div>
      )}
    </div>
  );
};

export default Main;
