import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import OkFilledIcon from '../../../assets/images/icons/OkFilledIcon.svg';

import styles from './Achievement.module.scss';
import {IAchievement} from "../../../types";
import Button from "../../UI/Button/Button";

interface IProps {
  achievement: IAchievement
}

const Achievement: React.FC<IProps> = ({achievement}) => {

  const [animated, setAnimated] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setAnimated(true)
    }, 500);
  }, []);

  return (
    <>
      <span className={styles.titleAchievementModal}>
        {achievement.achieved ? 'Way to go!' : cn(achievement.name.replace('_', ' '))}
        <br/>
        {achievement.achieved ? 'You earned an achievement.' : 'Achievement'}
      </span>
      <div
        className={cn(styles.wrapperAchievementModal, achievement.achieved && (styles.activeBg), animated && styles.activeBgFull)}>
        <div className={styles.achievementModal}>
          <img
            className={styles.achievementImgModal}
            style={achievement.achieved ? {} : {opacity: 0.5}}
            alt={achievement.name}
            src={achievement.imageUrl}
          />
        </div>
      </div>
      <div
        className={cn(
          styles.achievementTextModal,
          achievement.achieved && styles.achievementTextModalCurrent
        )}
      >
        {achievement.achieved && <img className={styles.successIcon} alt='check' src={OkFilledIcon}/>}
        <span>{achievement.achieved ? achievement.doneText : achievement.goalText}</span>
      </div>

      {achievement.partnerUrl &&
      <>

        <label className={styles.sponsoredBy}>Sponsored by:</label>
        <img src={achievement.partnerLogoUrl} className={styles.partnerLogo}/>

        {achievement.achieved &&
        <a href={achievement.partnerUrl} target="_blank">
          <Button onClick={() => {
          }} className={styles.partnerLinkBtn}>Unlock my reward</Button>
        </a>}

      </>}
    </>
  );
};

export default Achievement;
