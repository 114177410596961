import {axiosInstance} from './';
import API from "./listApi";

export default {
  getProgressMyData() {
    return axiosInstance.get('/api/v1/members/current');
  },

  async getMyAchievements() {
    const response = await axiosInstance.get('/api/v1/members/current/achievements');
    return response.data;
  },

  async setViewed(id: number) {
    await axiosInstance.post(`/api/v1/members/current/achievements/${id}/viewed`);
  },
};
