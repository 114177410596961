import React, { useState } from 'react';

import { deleteUser } from '../../../REST_API/userApi';
import styles from '../CreateReaderProfile.module.scss';
import { ConfirmModal } from '../../../components';
import { useParams, useHistory } from 'react-router-dom';
import { getUser } from '../../../store/User/user.thunk';
import { useDispatch } from 'react-redux';

const DeleteProfile = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const confirm = () => {
    const succses = () => {
      dispatch(getUser());
      history.push('/settings');
    };

    // @ts-ignore
    deleteUser(params.id, succses);
  };

  return (
    <>
      <div onClick={() => setIsOpen(true)} className={styles.delete}>
        Delete profile
      </div>
      <ConfirmModal
        title={`Are you sure you want to delete this reader profile?
        You cannot undo this.`}
        confirm={confirm}
        cancel={() => setIsOpen(false)}
        isOpen={isOpen}
        textBTN={'Delete'}
        type={'Delete'}
      />
    </>
  );
};

export default DeleteProfile;
