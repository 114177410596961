import user, { changeUser } from './userApi';
import donations from './donationsApi';
import progressMy from './progressMyApi';
import readingRecords from './readingRecordsApi';
import schoolLeaderboard from './schoolLeaderboardApi';
import teams from './teamsApi';
import events from './eventsApi';
import addReading from './addReadingApi';
import listTeam from './listTeamApi';
import listAvatar from './listAvatarApi';
import signUpData from './signUpDataApi';
import books from './booksApi';

const API = {
  user,
  events,
  donations,
  progressMy,
  readingRecords,
  teams,
  schoolLeaderboard,
  addReading,
  listTeam,
  changeUser,
  listAvatar,
  signUpData,
  books,
};

export default API;
