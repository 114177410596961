import { createAsyncThunk } from '@reduxjs/toolkit';

import API from '../../REST_API/listApi';

export const getAddReading = createAsyncThunk(
  'addReading/getAddReading',
  async (data: {
    id: number;
    datetime?: Date;
    mins?: number;
    bookId?: number;
    books?: number;
    setIsRequestSuccess?: (v: boolean) => void;
    setShowGoal?: (v: boolean) => void;
  }) => {
    const response = await API.addReading.getAddReadingData(
      data.id,
      data.datetime,
      data.mins,
      data.bookId,
      data.books
    );

    if (data?.setIsRequestSuccess) data.setIsRequestSuccess(true);
    
    if (data?.setShowGoal) {
      setTimeout(() => {
        // @ts-ignore
        data.setShowGoal(true);
      }, 800);
      return response.ok;
    }
  }
);
