import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { RecommendedBooks, ListBook, List, Search, BookDetails } from '../screens';

const Navigation: React.FC = () => {
  return (
    <Switch>
      <Route exact path='/books'>
        <Redirect to='/books/recommended' />
      </Route>
      <Route path='/books/recommended'>
        <RecommendedBooks />
      </Route>
      <Route path='/books/my'>
        <ListBook />
      </Route>
      <Route path='/books/lists/:id'>
        <List />
      </Route>
      <Route path='/books/search'>
        <Search />
      </Route>
      <Route path='/books/:isbn'>
        <BookDetails />
      </Route>
    </Switch>
  );
};

export default Navigation;
