import { axiosInstance } from './';
import load from "./index";

export default {
  getEventsData() {
    return axiosInstance.get('/api/v1/events/current');
  },

  getPublicEvent(code: string) {
    return axiosInstance.get(`/api/v1/events?code=${code}`);
  },

  getDonations(eventId: number, limit: number) {
    return axiosInstance.get(`/api/v1/events/${eventId}/donations?limit=${limit}`);
  },

  payment(request: IPaymentRequest) {
    return load('POST', `/api/v1/events/${request.eventId}/payments`, request);
  },

  getConfig() {
    return axiosInstance.get('/api/v1/config');
  },
};

interface IPaymentRequest {
  eventId: number,
  stripeToken: string,
  amount: number,
  tipsAmount: number,
  stripeEmail: string,
  name: string,
  message: string,
  public: boolean,
  matchCorp: boolean,
  giftAid: boolean,
  targetUserId: number
}
