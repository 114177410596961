import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';

import { Button, ErrorModal, FormatError, Input, Modal } from '../../../components';

import { changePassword } from '../../../REST_API/userApi';
import { Action } from '../../../types';

import styles from '../Settings.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

interface IPass {
  newPassword?: string;
  confirmPassword?: string;
  password?: string;
}

const ChangePasswordModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [error, setError] = useState<false | string>(false);

  useEffect(() => {
    if (isOpen) setIsSuccessful(false);
  }, [isOpen]);

  const onSubmit = (values: IPass, actions: Action) => {
    setIsLoading(true);

    const succses = (data: { accessToken: string }) => {
      setIsLoading(false);
      localStorage.setItem('accessToken', data.accessToken);
      setIsSuccessful(true);
      setTimeout(() => {
        onClose();
      }, 400);
    };

    const error = (data: any) => {
      setIsLoading(false);
      setError(FormatError(data));
      actions.setFieldError('password', FormatError(data));
    };

    if (values.newPassword) changePassword({ password: values.newPassword }, succses, error);
  };

  const isActiveBtn = (values: IPass): boolean =>
    !(
      Object.entries(values).every((item) => item[1] && item[1].length >= 6) &&
      values.newPassword === values.confirmPassword
    );

  const validate = (values: IPass) => {
    const errors: IPass = {};
    for (const key in values) {
      // @ts-ignore
      if (!values[key]) errors[key] = 'This field is required';
      // @ts-ignore
      if (values[key].length < 6) errors[key] = 'Min password length is 6 symbol';
    }
    if (values.newPassword !== values.confirmPassword) errors.confirmPassword = 'Password mismatch';

    return errors;
  };
  return (
    <>
      <Modal styleModal={{ minHeight: 377, height: 377 }} isOpen={isOpen} close={onClose}>
        <>
          {isOpen && (
            <div>
              <h3 className={styles.title}>Change password</h3>
              <Formik
                validate={validate}
                initialValues={{ newPassword: '', confirmPassword: '' }}
                onSubmit={onSubmit}
              >
                {({ handleSubmit, submitForm, values }: any) => (
                  <form className={styles.formPassword} onSubmit={handleSubmit}>
                    <Field name='newPassword'>
                      {({ field, meta }: any) => (
                        <div>
                          <Input
                            meta={meta}
                            placeholder='New password'
                            {...field}
                            type={values.newPassword ? 'password' : 'text'}
                          />
                        </div>
                      )}
                    </Field>
                    <Field name='confirmPassword'>
                      {({ field, meta }: any) => (
                        <div>
                          <Input
                            meta={meta}
                            placeholder='Confirm password'
                            {...field}
                            type={values.confirmPassword ? 'password' : 'text'}
                          />
                        </div>
                      )}
                    </Field>
                    <Button
                      styleBTN={{ heigth: 45 }}
                      isSuccessful={isSuccessful}
                      isLoading={isLoading}
                      disabled={isActiveBtn(values)}
                      onClick={isLoading ? null : submitForm}
                    >
                      Save
                    </Button>
                  </form>
                )}
              </Formik>
            </div>
          )}
        </>
      </Modal>
      <ErrorModal title={error || ''} cancel={() => setError(false)} isOpen={!!error} />
    </>
  );
};

export default ChangePasswordModal;
