import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './ButtonNavigation.module.scss';
import { LoaderData } from '../../../../components';

interface IButtonNavigation {
  onClick: () => void;
  values: { email: string; password: string };
  isLoading?: boolean;
}

const ButtonNavigation: React.FC<IButtonNavigation> = ({ onClick, values, isLoading }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  if (!values.email.length) {
    if (!isDisabled) setIsDisabled(true);
  } else if (!values.password.length) {
    if (!isDisabled) setIsDisabled(true);
  } else {
    if (isDisabled) setIsDisabled(false);
  }

  return (
    <div className={cn(styles.listButton)}>
      <Link to='/forgot-password' className={styles.LogIn}>
        <span className={styles.text}>Forgot password?</span>
      </Link>
      <button
        onClick={!isDisabled || isLoading ? onClick : () => {}}
        className={cn(styles.SignUp, isDisabled && styles.SignUpDisabled)}
        type='button'
      >
        {isLoading ? <LoaderData width={20} /> : <span className={styles.text}>Log in</span>}
      </button>
    </div>
  );
};

export default ButtonNavigation;
