import React from 'react';
import {Route, Switch} from 'react-router-dom';

import {ProgressMy, ProgressMy2, ProgressScool, ProgressTeam} from '../screens';

const Navigation: React.FC = () => {
  return (
    <Switch>
      <Route path='/progress/my'>
        <ProgressMy/>
      </Route>
      <Route path='/progress/my2'>
        <ProgressMy2/>
      </Route>
      <Route path='/progress/team'>
        <ProgressTeam/>
      </Route>
      <Route path='/progress/school'>
        <ProgressScool/>
      </Route>
    </Switch>
  );
};

export default Navigation;
