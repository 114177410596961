import React from 'react';

import { ArrowIcon } from '../../../assets/images/icons';

import styles from './Select.module.scss';

interface ISelect {
  children: JSX.Element | any;
  onChange: (value: any) => void;
  value?: number | string;
}

const Select: React.FC<ISelect> = ({ children, onChange, value }) => {
  return (
    <div className={styles.wrapper}>
      <select
        autoFocus
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => onChange(event.target.value)}
        className={styles.select}
        value={value || 'x'}
      >

        <option value='x' disabled hidden>
          Choose
        </option>
        {children}
      </select>
      <ArrowIcon className={styles.icon} />
    </div>
  );
};

export default Select;
