import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../REST_API/listApi';

export const getPublicEvent = createAsyncThunk('dp/getPublicEvent',
  async (code: string, thunkApi) => {
    const response = await API.events.getPublicEvent(code);

    if (response.data.invite.from.role === 'TM') {
      thunkApi.dispatch(getTeamMember(
        {eventId: response.data.id, userId: response.data.invite.from.id}
      ));
      thunkApi.dispatch(getBookLists(response.data.invite.from.id));
    }
    thunkApi.dispatch(getDonations(
      {eventId: response.data.id, limit: 3}
    ));
    return response.data;
  });

export interface DonationsRequest {
  eventId: number;
  limit: number;
}

export const getBookLists = createAsyncThunk('dp/getBookLists', async (userId?: number) => {
  const response = await API.books.getlistsBooksData(userId);
  return response.data;
});


export const getDonations = createAsyncThunk('dp/getDonations',
  async (req: DonationsRequest) => {
    const response = await API.events.getDonations(req.eventId, req.limit);
    return response.data;
  });

export interface TeamMemberRequest {
  eventId: number;
  userId: number;
}

export const getTeamMember = createAsyncThunk('dp/getTeamMember', async (req: TeamMemberRequest) => {
  const response = await API.teams.getMember(req.eventId, req.userId);
  return response.data;
});

