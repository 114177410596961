import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { IState } from '../../../types';
import arrow from './arrow.svg';

import styles from './SettingsItems.module.scss';
import { ConfirmModal } from "../../../components";
import { resendVerificationLink } from "../../../REST_API/userApi";

type Props = {
  onEditEmail: () => void;
  onChangePasswordEmail: () => void;
};

const AccountSettings: React.FC<Props> = ({onEditEmail, onChangePasswordEmail}) => {
  const user = useSelector((state: IState) => state.user.data);
  const [verifyEmailModal, setVerifyEmailModal] = useState<boolean>(false);
  const resendEmailVerifyLink = () => {
    resendVerificationLink();
    setVerifyEmailModal(false);
  }

  if (!user) return null;
  return (
    <div className={styles.wrapper}>
      <span className={styles.heading}>Account</span>
      <button onClick={onEditEmail} className={styles.card}>
        <div className={styles.wrapperTitle}>
          <span className={styles.title}>Email</span>
          <span className={styles.value}>{user.email}</span>
        </div>
        <div className={styles.arrow}>
          <img src={arrow} alt='arrow'/>
        </div>
      </button>

      {!user.emailVerified &&
      <button onClick={() => setVerifyEmailModal(true)} className={`${styles.card}`}>
        <div className={`${styles.wrapperTitle} ${styles.verifyEmail}`}>
          <span className={styles.title}>Unverified email</span>
          <span className={styles.value}>
            Resend verification link
          </span>
        </div>
        <div className={styles.arrow}>
          <img src={arrow} alt='arrow'/>
        </div>
      </button>}

      <button onClick={onChangePasswordEmail} className={styles.card}>
        <div className={styles.wrapperTitle}>
          <span className={styles.title}>Password</span>
          <span className={styles.value}>*********</span>
        </div>
        <div className={styles.arrow}>
          <img src={arrow} alt='arrow'/>
        </div>
      </button>

      <ConfirmModal
        confirm={() => resendEmailVerifyLink()}
        title={`Your email address (${user.email}) is not verified. Please check your email and click on the verification link.`}
        cancel={() => setVerifyEmailModal(false)}
        textBTN={'Resend link'}
        isOpen={verifyEmailModal}
      />

    </div>
  );
};

export default AccountSettings;
