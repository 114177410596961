import React from 'react';
import {Field, Formik} from 'formik';

import {Button, Input, SelectModal, Textarea} from '../../../components';

import defaultAvatar from '../../../assets/images/icons/defaultAvatar.svg';
import {ArrowIcon} from '../../../assets/images/icons';
import {IUserCreate, IProfile, ITeam, IEvents} from '../../../types';
import styles from '../CreateReaderProfile.module.scss';

interface IForm {
  user: IUserCreate | null;
  onSubmit: (data: IProfile) => void;
  listTeam: ITeam[];
  setShowSelect: (data: boolean) => void;
  showSelect: boolean;
  isLoading: boolean;
  isSuccessful: boolean;
  events: IEvents;
  setActive: (data: string) => void;
  avatar: string;
}

const validate = (values: IProfile) => {
  const errors: { [key: string]: string } = {};
  for (const key in values) {
    // @ts-ignore
    if (!values[key]) {
      if (key !== 'personalMessage' && key !== 'avatar') errors[key] = 'This field is required';
    }
  }
  console.log("errors", errors)
  return errors;
};

const Form: React.FC<IForm> = ({
                                 user,
                                 onSubmit,
                                 listTeam,
                                 setShowSelect,
                                 showSelect,
                                 isLoading,
                                 isSuccessful,
                                 events,
                                 setActive,
                                 avatar,
                               }) => {

  const isActiveBtn = (values: IProfile) => {
    return !(avatar && values.firstName && values.lastName && values.team && values.displayName);
  };

  return (
    <div>
      <Formik
        initialValues={{
          team: user ? user.team.id : '',
          firstName: user ? user.firstName : '',
          lastName: user ? user.lastName : '',
          displayName: user ? user.displayName : '',
          personalMessage: user ? user.personalMessage : '',
          avatar: avatar,
        }}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({handleSubmit, submitForm, values, setFieldValue}: any) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            <button
              type='button'
              onClick={() => listTeam && setShowSelect(true)}
              className={styles.seletcTeam}
            >
              {values.team && listTeam ? (
                <>
                  <span className={styles.subtitle}>
                    {listTeam.filter((team: ITeam) => +team.id === +values.team)[0].gradeDisplay}{' '}
                    Grade
                  </span>
                  <br/>
                  <span className={styles.textTeamName}>
                    {listTeam.filter((team: ITeam) => +team.id === +values.team)[0].name}
                  </span>
                </>
              ) : (
                <span className={styles.subtitleChooseTeam}>Choose a team</span>
              )}
              <ArrowIcon/>
            </button>
            <SelectModal
              title='Choose a team'
              isOpen={showSelect}
              onSelect={(data: number) => {
                setShowSelect(false);
                setFieldValue('team', listTeam.filter((team: ITeam) => +team.id === +data)[0].id)
              }
              }
              value={user ? user.team.id : ''}
              cancel={() => setShowSelect(false)}
            >
              {listTeam
                ? listTeam.map((team: ITeam) => (
                  <option key={team.id} value={team.id}>
                    {team.gradeDisplay} Grade ({team.name})
                  </option>
                ))
                : ''}
            </SelectModal>
            <button
              type='button'
              onClick={() => setActive('avatar')}
              className={styles.wrapperAvatar}
            >
              <img className={styles.img} src={avatar || defaultAvatar} alt='avatar'/>
              <span className={styles.title}>Change avatar</span>
            </button>
            <div className={styles.body}>
              <Field name='firstName'>
                {({field, meta}: any) => (
                  <div>
                    <Input meta={meta} placeholder='First name' {...field} />{' '}
                  </div>
                )}
              </Field>
              <Field name='lastName'>
                {({field, meta}: any) => (
                  <div>
                    <Input meta={meta} placeholder='Last name' {...field} />{' '}
                  </div>
                )}
              </Field>
              <Field name='displayName'>
                {({field, meta}: any) => (
                  <div>
                    <Input meta={meta} placeholder='Display name' {...field} />{' '}
                  </div>
                )}
              </Field>
              <Field name='personalMessage'>
                {({field, meta}: any) => (
                  <div>
                    <Textarea meta={meta} placeholder='Personal message' {...field} />{' '}
                  </div>
                )}
              </Field>
              <span className={styles.text}>
                Your personal message will be displayed on your donation page.
              </span>
              <div className={styles.listButton}>
                <Button
                  disabled={isActiveBtn(values)}
                  isSuccessful={isSuccessful}
                  isLoading={isLoading}
                  onClick={submitForm}
                  type='button'
                >
                  Save
                </Button>
                {user &&
                <a href={events.shareLink} target="_blank">
                  <Button type='thin' onClick={() => {
                  }}>
                    View My Donation Page
                  </Button>
                </a>
                }
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Form;
