import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FooterNav, Header, Navigation } from './components';
import { IState } from './types';

import { getUser } from './store/User/user.thunk';
import { getEvents } from './store/Events/events.thunk';

import './index.scss';
import {RouteComponentProps, useLocation, useParams, withRouter} from 'react-router-dom';
import { getListsBooks } from './store/Books/books.thunk';
import { isAuthorized } from "./REST_API/userApi";
import { getAchievements, getProgressMy } from "./store/ProgressMy/progressMy.thunk";

interface IMain extends RouteComponentProps {
}

const Main: React.FC<IMain> = ({history}) => {
  const dispatch = useDispatch();

  const user = useSelector((state: IState) => state.user.data);
  const event = useSelector((state: IState) => state.events.data);
  const params = useParams<{ code: string }>();
  const location = useLocation().pathname.slice(1);

  useEffect(() => {
    if (isAuthorized()) {
      dispatch(getUser(params.code));
      dispatch(getEvents());
      dispatch(getProgressMy());
      dispatch(getListsBooks());
      dispatch(getAchievements());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let isPublicPage = (
    location.includes('welcome') ||
    location.includes('event/') ||
    location.includes('sign-up') ||
    location.includes('log-in') ||
    location.includes('forgot-password')
  );


  //TODO bad bad!!!
  let showHeader = !(
    isPublicPage ||
    location.includes('settings/users/')
  );

  //TODO bad bad
  if (location.includes('books/')) {
    if (!location.includes('books/recommended') && !location.includes('books/my'))
      showHeader = false;
  }

  if (user && !user.hasProfile && !location.includes('sign-up')) {
    history.push('/sign-up/profile');
    return null;
  }

  if (event && event.closed && !location.includes('sign-up')) {
    history.push('/sign-up/join/code/-');
    return null;
  }

  return (
    <>
      {showHeader && <Header/>}
      <div className='contentScreen height'>
        <Navigation/>
      </div>
      {!isPublicPage &&
      <FooterNav/>}
    </>
  );
};

export default withRouter(Main);
