import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import cn from 'classnames';
import { IManaged, IUser } from '../../types';
import defaultAvatar from '../../assets/images/icons/defaultAvatar.svg';
import { Button, Modal, RadioButtons } from '../';
import { useMediaQuery } from 'react-responsive';

import { Settings } from '../../assets/images/icons';

import styles from './ChangeUser.module.scss';
import Readers from "../../screens/Settings/Readers/Readers";
import ReadersSelect from "../UI/ReadersSelect/ReadersSelect";

interface IChangeUser extends RouteComponentProps {
  user: IUser;
  avatar: string;
  alt: string;
}

const ChangeUser: React.FC<IChangeUser> = ({avatar, alt, user, history}) => {
  const adapt = useMediaQuery({
    query: '(min-device-width: 1100px)',
  });

  const [show, setShow] = useState(false);
  const [isAvtiveUser, setIsAvtiveUser] = useState<IManaged | any>(
    !!user.managed && user.managed.filter((item: IManaged) => item.id === user.id)[0]
  );

  if (!user.managed) return null;

  const setUser = (item: IManaged) => {
    if (!(user.id === item.id)) {
      setIsAvtiveUser(item);
      localStorage.setItem('accessToken', item.accessToken);
      setShow(false);
      setTimeout(() => {
        document.location.reload(true);
      }, 500);
    } else {
      setShow(false);
    }
  };

  const getMarginBottom = () => {
    if (user.managed.length > 5) return adapt ? -30 * 6 : 0;
    return adapt ? -30 * user.managed.length : 0;
  };

  return (
    <div>
      <button onClick={() => setShow(true)}>
        <img alt={alt} className={styles.avatar} src={avatar ? avatar : defaultAvatar}/>
      </button>
      <Modal
        classModal={styles.modalUserChange}
        styleModal={{minHeight: 340, marginBottom: getMarginBottom()}}
        close={() => setShow(false)}
        isOpen={show}
      >
        <>
          <div className={styles.wrapperModalContent}>
            <div className={styles.header}>
              {/*<div className={styles.title}>Choose reader</div>*/}

              <div>
                <div className={styles.title}>My Reader Profiles</div>
              </div>

              <button
                onClick={() => {
                  history.push('/settings');
                  setShow(false);
                }}
              >
                <Settings/>
              </button>
            </div>

            <ReadersSelect users={user.managed}
                           onEdit={(u) => history.push(`/settings/users/${u.id}/profile`)}
                           onSetCurrent={setUser}/>

            {/*<div className={styles.listButton}>*/}
            {/*  <Button type='thin' onClick={() => setShow(false)}>*/}
            {/*    Cancel*/}
            {/*  </Button>*/}
            {/*  <Button onClick={() => changeUser()}>Choose</Button>*/}
            {/*</div>*/}
          </div>
        </>
      </Modal>
    </div>
  );
};

export default withRouter(ChangeUser);
