import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './ButtonNavigation.module.scss';

const ButtonNavigation: React.FC<{welcome?: boolean, appLink?: any}> = ({welcome, appLink}) => {
  return (
    <>
      {welcome ?
        <div className={styles.listButton}>
          <Link to='/log-in' className={styles.LogIn}>
            <span className={styles.text}>Log in</span>
          </Link>
          <Link to={'/sign-up/join'} className={styles.SignUp}>
            <span className={styles.text}>Sign Up</span>
          </Link>
        </div>
        :
        <div className={cn(styles.listButton, styles.LastStep)}>

          {appLink &&
          <a href={appLink} target="_blank" className={styles.LogIn}>
            <span className={styles.text}>Install App</span>
          </a>}

          <Link to={'/main'} className={styles.SignUp}>
            <span className={styles.text}>Go to my page</span>
          </Link>
        </div>
      }
    </>
  );
};

export default ButtonNavigation;
