import React, { useEffect, useState } from 'react';

import styles from './ReadingProgress.module.scss';

interface Props {
  value: number;
  goal: number;
}

const ReadingProgress: React.FC<Props> = ({value, goal}) => {
  const [progressWidth, setProgressWidth] = useState(0);
  useEffect(() => {
    let persent = (value || 0) / goal * 100;
    setProgressWidth(persent < 100 ? persent : 100);
  });
  return (
    <div className={styles.wrapper}>
      <div className={styles.values}>
        <div>
          <span className={styles.amount}>{value}</span>{' '}
          <span className={styles.amountLabel}>minutes read</span>
        </div>
        <div className={styles.goal}>
          <span>goal: {goal}</span>
        </div>
      </div>
      <div className={`${styles.progress}`}>
        <div style={{width: `${progressWidth}%`}}/>
      </div>
    </div>
  );
};

export default ReadingProgress;
