import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import API from '../../REST_API/listApi';

export const getCreateReaderProfile = createAsyncThunk(
  'createReaderProfile/getCreateReaderProfile',
  async (url: string) => {
    const response = await API.user.getCreateReaderProfile(url);
    return response.data;
  }
);

export const deleteCreateReaderProfile = createAction(
  'createReaderProfile/deleteCreateReaderProfile'
);
