import copy from "copy-to-clipboard";
import styles from "./ShareButtons.module.scss";
import { CopyIcon, Facebook, Twitter, EmailIcon } from "../../assets/images/icons";
import React, { useState } from "react";

interface IShareButtonProps {
  shareLink: string,
}

const ShareButtons = ({shareLink}: IShareButtonProps) => {

  const [isCopied, setIsCopied] = useState(false);

  const twitterLink = 'http://twitter.com/share?url=' + shareLink;
  const facebookLink = 'https://www.facebook.com/sharer/sharer.php?u=' + shareLink;
  const mailLink = encodeURI('mailto:?subject=My school fundraiser needs your support!&body=Hello, \n' +
    '\n' +
    'I`m working hard to raise money by participating in my school read-a-thon.As someone who loves to read, I’m excited about sharing our fundraiser with you.I`ve included a link to my personal donation page where you can see all of my reading achievements. \n' +
    '\n' +
    shareLink + '\n' +
    '\n' +
    'Reading is important to me so please consider making a donation on my behalf. Every bit helps as I look to reach my read-a-thon finish line!\n' +
    '\n' +
    'Thank you for your support!');

  const copyToClipboard = () => {
    copy(shareLink);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  }

  return (
    <div className={styles.ShareButtons}>

      <a className={styles.facebook} target="_blank"
         href={facebookLink}><Facebook/></a>

      <a className={styles.twitter} target="_blank"
         href={twitterLink}><Twitter/></a>

      <a className={styles.email} target="_blank"
         href={mailLink}><EmailIcon/></a>

      <div className={styles.copy} onClick={copyToClipboard}><CopyIcon/></div>

      <div className={`${styles.copied} ${isCopied? styles.visible: styles.hidden}`}>
        The link was copied to clipboard
      </div>
    </div>
  );
};

export default ShareButtons;
