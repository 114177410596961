import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../REST_API/listApi';

export const getListAvatar = createAsyncThunk(
  'listAvatar/getListAvatar',
  async (data: { url: string; type: string }) => {
    const response = await API.listAvatar.getListAvatarData(data.url);
    return { data: response.data, type: data.type };
  }
);
