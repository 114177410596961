import load, { axiosInstance } from './';

export default {
  getUserData() {
    return axiosInstance.get('/api/v1/users/me');
  },
  getCreateReaderProfile(url: string) {
    return axiosInstance.get(url);
  },
};

export const isAuthorized = ()=>{
  return !!localStorage.getItem('accessToken');
}

export const changeUser = async (
  type: string,
  url: string,
  currentValues: any,
  succses: () => void,
  error: (data: any) => void
) => {
  const res = await load(type, url, currentValues);
  const errorText = await res.json();
  if (res.ok) {
    succses();
  } else {
    error(errorText);
  }
};

export const changePassword = async (
  currentValues: { password: string },
  succses: (data: { accessToken: string }) => void,
  error: (data: any) => void
) => {
  const res = await load('POST', '/api/v1/users/me/password', currentValues);
  const errorText = await res.json();

  if (res.ok) {
    succses(errorText);
  } else {
    error(errorText);
  }
};

export const forgotPassword = async (
  currentValues: { email: string },
  succses: () => void,
  error: (data: any) => void
) => {
  const res = await load('POST', '/api/v1/auth/reset-password', currentValues);
  const errorText = await res.json();

  if (res.ok) {
    succses();
  } else {
    error(errorText);
  }
};

export const resendVerificationLink = async () => {
 await load('POST', '/api/v1/auth/confirm-email');
};

export const editEmail = async (
  currentValues: { email: string },
  succses: () => void,
  error: (data: string) => void
) => {
  const res = await load('POST', '/api/v1/users/me', currentValues);
  const errorText = await res.json();
  if (res.ok) {
    succses();
  } else {
    error(errorText);
  }
};

export const deleteUser = async (id: number, succses: () => void) => {
  const res = await load('DELETE', `/api/v1/users/me/managed/${id}`);
  if (res.ok) {
    succses();
  }
};
