import React from 'react';
import {
  CheckboxActive,
  CheckboxDisable,
  
} from '../../../assets/images/icons/index';

const Checkbox: React.FC<{ isChecked: boolean }> = ({ isChecked }) => {
  return isChecked ? <CheckboxActive /> : <CheckboxDisable />;
};

export default Checkbox;
