import React from "react";
import icon from '../../../assets/images/logo/ic_launcher.png';
import {Button} from "../../../components";


class StripeCheckoutButton extends React.Component {
  state = {
    loading: true
  };

  loadStripe = onload => {
    if (!window.StripeCheckout) {
      const script = document.createElement("script");
      script.onload = () => {
        onload();
      };
      script.src = "https://checkout.stripe.com/checkout.js";
      document.head.appendChild(script);
    } else {
      onload();
    }
  };


  componentDidMount() {
    this.loadStripe(() => {
      let key = window.location.host.startsWith('dev-') || window.location.host.startsWith('localhost')
        ? 'pk_test_yrn1yJ8btsiek8VLlzphmjfC' : 'pk_live_cqBQ0XpNcv5iz3nKjaehztvu'
      this.stripeHandler = window.StripeCheckout.configure({
        key: key,
        image: icon,
        locale: "auto",
        token: token => {
          this.props.onSuccess(token.id);
          this.setState({loading: false});
        }
      });

      this.setState({
        loading: false
      });
    });
  }

  componentWillUnmount() {
    if (this.stripeHandler) {
      this.stripeHandler.close();
    }
  }

  handleOpenStripeDialog = e => {
    if (!this.state.loading)
      this.stripeHandler.open({
        name: this.props.title || window.document.title,
        currency: this.props.currency,
        amount: this.props.amount * 100,
        email: this.props.email,
        allowRememberMe: false,
      });
    // e.preventDefault();
  };

  render() {
    const {loading} = this.state;
    return (
      <Button
        disabled={this.props.disabled}
        isLoading={loading || this.props.isLoading}
        onClick={this.handleOpenStripeDialog}>
        {this.props.children}
      </Button>
    );
  }
}

export default StripeCheckoutButton;
