import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import reduxThunk from 'redux-thunk';

import reducerAddReading from './AddReading/reducerAddReading';
import reducerDonations from './Donations/reducerDonations';
import reducerEvents from './Events/reducerEvents';
import dpReducer from './DonationPage/reducer';
import reducerIndicators from './Indicators/reducerIndicators';
import { loadingMiddleware } from './Middleware';
import createReaderProfile from './CreateReaderProfile/reducerCreateReaderProfile';
import reducerProgressMy from './ProgressMy/reducerProgressMy';
import reducerReadingRecords from './ReadingRecords/reducerReadingRecords';
import reducerSchoolLeaderboard from './SchoolLeaderboard/reducerSchoolLeaderboard';
import reducerTeamLeaderboard from './TeamLeaderboar/reducerTeamLeaderboard';
import reducerUser from './User/reducerUser';
import reducerListTeam from './listTeam/reducerListTeam';
import reducerListAvatar from './listAvatar/reducerListAvatar';
import reducerSignUpData from './SignUpData/reducerSignUpData';
import reducerBooks from './Books/reducerBooks';
import reducerConfig from "./Config/reducerConfig";

const rootReducer = combineReducers({
  indicators: reducerIndicators,
  user: reducerUser,
  events: reducerEvents,
  dp: dpReducer,
  donations: reducerDonations,
  progressMy: reducerProgressMy,
  readingRecords: reducerReadingRecords,
  teamLeaderboard: reducerTeamLeaderboard,
  schoolLeaderboard: reducerSchoolLeaderboard,
  addReading: reducerAddReading,
  createReaderProfile: createReaderProfile,
  listTeam: reducerListTeam,
  listAvatar: reducerListAvatar,
  signUpData: reducerSignUpData,
  books: reducerBooks,
  config: reducerConfig
});

export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
  middleware: [reduxThunk, ...getDefaultMiddleware(), loadingMiddleware],
});
