import React from 'react';

import styles from './FundraiserStats.module.scss';
import { IUser } from "../../../types";
import { IPublicEvent, ITeamMember } from "../../../store/DonationPage/types";

interface Props {
  event: any,
  member?: ITeamMember,
}

const FundraiserStats = ({event, member}: Props) => {

  const minutesRead = event.progress.minsSum >= 10000 ?
    `${(event.progress.minsSum / 1000).toFixed(0)}K` :
    event.progress.minsSum.toLocaleString();

  return (
    <div className={`${styles.FundraiserStats}`}>

      {member ? <>

          <div className={styles.item}>
            <h2>{member.progress.minsSum}</h2>
            <label>minutes read</label>
          </div>

          {/*<div className={styles.item}>*/}
          {/*  <h2>{member.progress.booksSum}</h2>*/}
          {/*  <label>books read</label>*/}
          {/*</div>*/}

        </> :
        <>
          <div className={styles.item}>
            <h2>{event.membersCount}</h2>
            <label>students</label>
          </div>

          <div className={styles.item}>
            <h2>{minutesRead}</h2>
            <label>minutes read</label>
          </div>

          <div className={styles.item}>
            <h2>{event.progress.booksSum.toLocaleString()}</h2>
            <label>books read</label>
          </div>



        </>}

    </div>
  );
};


export default FundraiserStats;
