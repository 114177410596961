import React from 'react';

import { Input, Modal } from '../../';

import styles from './ShareModal.module.scss';
import ShareButtons from "../../ShareButtons/ShareButtons";

interface IConfirmModal {
  shareLink: string,
  cancel: () => void;
  isOpen: boolean;
}

const ShareModal: React.FC<IConfirmModal> = ({shareLink,
                                               isOpen,
                                               cancel,
                                             }) => {
  return (
    <Modal styleModal={{minHeight: 250}}
           isOpen={isOpen} close={cancel}>
      <div className={styles.wraper}>
        <span className={styles.title}>Share my donation page</span>

        <Input type="text" value={shareLink} onChange={() => {
        }}/>

        <br/>
        <ShareButtons shareLink={shareLink}/>

      </div>
    </Modal>
  );
};

export default ShareModal;
