import React, { useState } from 'react';
import cn from 'classnames';
import { forgotPassword } from '../../../REST_API/userApi';
import styles from '../SignUp.module.scss';
import { Input, Button, FormatError } from '../../../components';
import { Clock } from '../../../assets/images/icons';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [error, setError] = useState('');

  const handleClick = () => {
    setLoading(true);
    const succses = () => {
      setSuccessful(true);
      setLoading(false);
    };
    const handleError = (error: any) => {
      setError(FormatError(error));
      setLoading(false);
    };
    forgotPassword({ email: email }, succses, handleError);
  };

  return (
    <div className={styles.wrapperForgotPassword}>
      <span className={styles.title}>Forgot password</span>
      <span className={styles.text}>
        {successful ? 'Check your email' : 'Enter your email address'}
      </span>
      <div className={styles.wrapperLoaderDataRegister}>
        <Input
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          value={email}
          disabled={successful}
          placeholder='Email'
          type='email'
          onClick={() => setError('')}
          meta={{
            error: error,
            touched: error && true,
          }}
        />
        {successful && <Clock className={styles.LoaderDataRegister} />}
      </div>
      {successful ? (
        <span
          className={cn(styles.textForgotPassword, styles.hint)}
        >{`A link to reset your password has been sent to ${email}`}</span>
      ) : (
        <span className={cn(styles.textForgotPassword, styles.hint)}>
          We will send you a link to reset your password.
        </span>
      )}
      {!successful && (
        <Button isLoading={loading} onClick={!loading && email ? handleClick : () => {}}>
          Send reset link
        </Button>
      )}
    </div>
  );
};

export default ForgotPassword;
