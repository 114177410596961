import { createSlice } from '@reduxjs/toolkit';
import { getEvents } from './events.thunk';
import { IEvents } from '../../types';

interface IinitialState {
  error: boolean | string;
  data: null | IEvents;
  loading: boolean;
}

const reducerEvent = createSlice({
  name: 'event',
  initialState: { error: false, data: null, loading: false } as IinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEvents.pending, (state: IinitialState) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getEvents.fulfilled, (state: IinitialState, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getEvents.rejected, (state: IinitialState) => {
      state.loading = false;
    });
  },
});

export default reducerEvent.reducer;
