import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LogIn from '../screens/SignUp/LogIn/LogIn';
import { Books, Main, Progress, SignUp, Welcome } from '../screens';
import NavigationSettings from './NavigationSettings';
import CreateReaderProfile from '../screens/SignUp/CreateReaderProfile/CreateReaderProfile';
import SetupYourDonationPage from '../screens/SignUp/SetupYourDonationPage/SetupYourDonationPage';
import ForgotPassword from '../screens/SignUp/ForgotPassword/ForgotPassword';
import LastStep from '../screens/SignUp/LastStep/LastStep';
import DonationPage from "../screens/DonationPage/DonationPage";
import DonateTo from "../screens/DonationPage/DonateTo/DonateTo";
import DonateSuccess from "../screens/DonationPage/DonateSuccess/DonateSuccess";

const Navigation: React.FC = () => {
  const authorized = !!localStorage.getItem('accessToken');
  return (
    <Switch>
      <Route path='/welcome'>
        <Welcome />
      </Route>
      <Route path='/main'>
        <Main />
      </Route>
      <Route path='/progress'>
        <Progress />
      </Route>
      <Route path='/books'>
        <Books />
      </Route>
      <Route path='/settings'>
        <NavigationSettings />
      </Route>
      <Route path='/sign-up/join/'>
        <SignUp />
      </Route>
      <Route path='/log-in'>
        <SignUp>
          <LogIn />
        </SignUp>
      </Route>
      <Route path='/sign-up/profile'>
        {authorized ? (
          <SignUp>
            <CreateReaderProfile />
          </SignUp>
        ) : (
          <Welcome />
        )}
      </Route>
      <Route path='/sign-up/personal'>
        <SignUp>
          <SetupYourDonationPage />
        </SignUp>
      </Route>
      <Route path='/sign-up/completed'>
        <SignUp>
          <LastStep />
        </SignUp>
      </Route>
      <Route path='/forgot-password'>
        <SignUp>
          <ForgotPassword />
        </SignUp>
      </Route>
      <Route exact path='/event/:code/:slug'>
        <DonationPage/>
      </Route>
      <Route exact path='/event/:code/:slug/donate'>
        <DonateTo/>
      </Route>
      <Route exact path='/event/:code/:slug/donate-success-:amount'>
        <DonateSuccess/>
      </Route>
      <Route exact path='/'>
        <Redirect to={authorized ? '/main' : '/welcome'} />
      </Route>
    </Switch>
  );
};

export default Navigation;
