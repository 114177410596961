import { createSlice } from '@reduxjs/toolkit';
import { IProfile } from '../../types';
import { getCreateReaderProfile, deleteCreateReaderProfile } from './createReaderProfile.thunk';

interface IinitialState {
  error: boolean | string;
  data: null | IProfile;
  loading: boolean;
}

const reducerCreateReaderProfile = createSlice({
  name: 'createReaderProfile',
  initialState: { error: false, data: null, loading: false } as IinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCreateReaderProfile.pending, (state: IinitialState) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getCreateReaderProfile.fulfilled, (state: IinitialState, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(deleteCreateReaderProfile, (state: IinitialState) => {
      state.data = null;
    });
    builder.addCase(getCreateReaderProfile.rejected, (state: IinitialState) => {
      state.loading = false;
    });
  },
});

export default reducerCreateReaderProfile.reducer;
