import React from 'react';

import { GetCurrency, Loader } from '../';
import coin from './icon/coin.svg';
import time from './icon/time.svg';

import styles from './ProgressLoader.module.scss';

interface   ProgressLoader {
  valueCoin: number;
  valueTime: number;
  totalValueTime: number;
  totalValueCoin: number;
}

const ProgressLoader: React.FC<ProgressLoader> = ({
  valueCoin,
  valueTime,
  totalValueTime,
  totalValueCoin,
}) => {
  const formatBigNumber = (value: number) =>
    value < 100000 ? value.toLocaleString() : `${(value / 1000).toLocaleString()}K`;

  return (
    <div className={styles.wrapperPogressMy}>
      <Loader valueEnd={(valueTime * 100) / totalValueTime} backroundColor='#00AEEF'>
        <div className={styles.wrapperLoaderContent}>
          <img className={styles.imgLoader} alt='time' src={time} />
          <span className={styles.textLoader}>
            minutes read<br/>
            <span className={styles.textGoal}> {formatBigNumber(totalValueTime)} goal</span>
          </span>
          <span className={styles.totalValue}>{valueTime.toLocaleString()}</span>
        </div>
      </Loader>
      <div className={styles.centerPadding}></div>
      <Loader valueEnd={(valueCoin * 100) / totalValueCoin} backroundColor='#F4B81B'>
        <div className={styles.wrapperLoaderContent}>
          <img className={styles.imgLoader} alt='coin' src={coin} />
          <span className={styles.textLoader}>
            pledge donations<br/>
            <span className={styles.textGoal}>
              {' '}
              <GetCurrency value={formatBigNumber(totalValueCoin)} /> goal
            </span>
          </span>
          <span className={styles.totalValue}>
            <GetCurrency value={valueCoin.toLocaleString()} />
          </span>
        </div>
      </Loader>
    </div>
  );
};

export default ProgressLoader;
