import { createSlice } from '@reduxjs/toolkit';
import { getListAvatar } from './listAvatar.thunk';

interface IinitialState {
  error: boolean | string;
  data: null | any;
  loading: boolean;
}

const reducerListAvatar = createSlice({
  name: 'listAvatar',
  initialState: { error: false, data: {}, loading: false } as IinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getListAvatar.pending, (state: IinitialState) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getListAvatar.fulfilled, (state: IinitialState, action) => {
      state.loading = false;
      state.data[action.payload.type] = action.payload.data;
    });
    builder.addCase(getListAvatar.rejected, (state: IinitialState) => {
      state.loading = false;
    });
  },
});

export default reducerListAvatar.reducer;
