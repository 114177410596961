import React from 'react';

import styles from './Beneficiary.module.scss';
import { IUser } from "../../../types";

interface Props {
  user: IUser;
}

const Beneficiary = ({user}: Props) => {

  let caption = ' ';
  if (user.role === 'TM' && user.gradeDisplay) caption = `${user.gradeDisplay} Grade`;
  if (user.role === 'EA') caption = `Administrator`;
  if (user.role === 'TL') caption = `Team lead`;

  return (
    <div className={`${styles.Beneficiary}`}>
      <div className={styles.avatarBg}>
        <img src={user.avatar} alt=""/>
      </div>

      <h2 className={styles.name}>
        {user.displayName}
      </h2>

      <div className={styles.caption}>
        {caption}
      </div>
    </div>
  );
};


export default Beneficiary;
