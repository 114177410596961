import React, { useEffect, useState } from 'react';

import styles from './DonationsProgress.module.scss';
import MoneyAmount from "../../UI/MoneyAmount";

interface Props {
  amount: number;
  goal: number;
  raisedLabel?: string;
  goalLabel?: string;
  currency: string;
  memberName?: string;
}

const DonationsProgress: React.FC<Props> = ({amount, currency, goal, raisedLabel, goalLabel}) => {

  const [progressWidth, setProgressWidth] = useState(0);

  useEffect(() => {
    let persent = (amount || 0) / goal * 100;
    setProgressWidth(persent < 100 ? persent : 100);
  });
  return (
    <div className={styles.wrapper}>
      <div className={styles.values}>
        <div>
          <span className={styles.amount}><MoneyAmount amount={amount || 0} currency={currency}/></span>{' '}
          <span className={styles.amountLabel}>{`${raisedLabel || 'raised'}`}</span>
        </div>
        <div className={styles.goal}>
          <span>{`${goalLabel ? `${goalLabel}: ` : ''}`}<MoneyAmount amount={goal} currency={currency}/></span>
        </div>
      </div>
      <div className={`${styles.progress}`}>
        <div style={{width: `${progressWidth}%`}}/>
      </div>
    </div>
  );
};

export default DonationsProgress;
