import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { FormatError, Input } from '../../../components';
import { logIn, requestToken, signUp } from '../../../REST_API/logInApi';
import { Facebook, Google, Clock, } from '../../../assets/images/icons';
import styles from '../SignUp.module.scss';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ButtonNavigation from '../ButtonNavigation/ButtonNavigation';
import { Field, Formik } from 'formik';
import { useMediaQuery } from 'react-responsive';
import { agent } from "../../../cordova-agent";
import { analytics } from "../../../analytics";
import API from "../../../REST_API/listApi";
import { useSelector } from "react-redux";
import { IState } from "../../../types";
import {SIGN_UP_TEAM_ID} from "../../../const";

interface IData {
  email?: string;
  newPassword?: string;
  confirmPassword?: string;
  password?: string;
}

interface ISignUpResponse {
  verificationId?: string;
  accessToken?: string;
}

const isActiveBtn = (values: IData) => {
  let errors: boolean = true;
  const {newPassword, confirmPassword, email} = values;

  if (!newPassword || !confirmPassword || !email) {
    errors = false;
  }

  if (newPassword && newPassword.length < 6) {
    errors = false;
  }
  if (confirmPassword && confirmPassword.length < 6) {
    errors = false;
  }
  if (newPassword && confirmPassword && newPassword !== confirmPassword) {
    errors = false;
  }
  if ((email && !email.includes('@')) || (email && email.length < 6)) {
    errors = false;
  }
  if (newPassword !== confirmPassword) {
    errors = false;
  }
  return !errors;
};

const validate = (values: IData) => {
  const errors: IData = {};
  const {newPassword, confirmPassword, email} = values;

  if (newPassword && newPassword.length < 6) {
    errors.newPassword = 'This field is required';
  }
  if (confirmPassword && confirmPassword.length < 6) {
    errors.confirmPassword = 'This field is required';
  }
  if (newPassword && confirmPassword && newPassword !== confirmPassword) {
    errors.confirmPassword = 'Password mismatch';
  }
  if ((email && !email.includes('@')) || (email && email.length < 6)) {
    errors.email = 'Email not valid';
  }
  if (newPassword !== confirmPassword) {
    errors.confirmPassword = 'Password mismatch';
  }
  return errors;
};

interface MatchParams {
  code: string;
}

interface IRegister extends RouteComponentProps<MatchParams> {
  page: {activePage: number; setActivePage: (page: number) => void};
  list: string[];
}

const CreateAccount: React.FC<IRegister> = ({match, page, list, history}) => {
  const [error, setError] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [confirmEmail, setСonfirmEmail] = useState(false);
  const selectedTeam = useSelector((state: IState) => state.signUpData.currentTeam);

  useEffect(() => {
    page.setActivePage(2);
    window.addEventListener("focus", () => {
      let verificationId = localStorage.getItem("email.verificationId");
      let email = localStorage.getItem("email.value") || '';
      if (verificationId) {
        requestToken({email, verificationId}, (data) => {
          if (data.accessToken) {
            localStorage.clear();
            localStorage.setItem('accessToken', data.accessToken);
            history.push('/sign-up/profile')
          }
        }, () => {
        });
      }
    });

  }, []);

  const onSubmit = async (values: IData) => {
    setIsLoading(true);

    const succses = ({verificationId, accessToken}: ISignUpResponse) => {
      setIsLoading(false);
      setIsSuccessful(true);
      if (verificationId) {
        setСonfirmEmail(true);
        localStorage.setItem("email.verificationId", verificationId);
        localStorage.setItem("email.value", values.email + '');
        analytics.logEvent('sign_up_submit');
      } else {
        if (accessToken) {
          localStorage.setItem('accessToken', accessToken);
          history.push('/sign-up/profile');
          analytics.logEvent('sign_up_account_created');
        }
      }
    };
    const error = (data: any) => {
      setIsLoading(false);
      setError(FormatError(data));

      //relogin for already existing users
      if (selectedTeam && data.error && data.error.code === 2) {
        logIn(
          {
            email: values.email || '',
            password: values.newPassword || ''
          },
          (data) => {
            localStorage.setItem('accessToken', data.accessToken);
            API.teams.setTeam(selectedTeam.id, selectedTeam.inviteCode || '').then(() => {
              history.push(`/sign-up/profile`);
            });
          },
          error
        );
      }
    };

    signUp(
      {
        email: values.email || '',
        password: values.newPassword || '',
        inviteCode: match.params.code,
        teamId: localStorage.getItem(
            SIGN_UP_TEAM_ID
        )
      },
      succses,
      error
    );
  };

  return (
    <>

      <span className={styles.text}>
        {confirmEmail ? 'Waiting for confirmation' : 'Sign up with Email'}
      </span>
      <Formik
        validate={validate}
        initialValues={{email: '', newPassword: '', confirmPassword: ''}}
        onSubmit={onSubmit}
      >
        {({handleSubmit, submitForm, values}: any) => {
          const showPass =
            (values.email.length > 6 && values.email.includes('@') && !confirmEmail);
          return (
            <form className={styles.formRegister} onSubmit={handleSubmit}>
              {confirmEmail ? (
                <div className={styles.wrapperLoaderDataRegister}>
                  <Clock className={styles.LoaderDataRegister}/>
                  <Input disabled placeholder={values.email}/>
                </div>
              ) : (
                <Field name='email'>
                  {({field, meta}: any) => (
                    <div className={styles.input}>
                      <Input
                        meta={
                          meta.error && meta.touched
                            ? meta
                            : {
                              error: error,
                              touched: error && true,
                            }
                        }
                        {...field}
                        type='email'
                        onClick={() => setError('')}
                        placeholder='Enter Email address'
                      />
                    </div>
                  )}
                </Field>
              )}
              {!confirmEmail && showPass && (
                <>
                  <Field name='newPassword'>
                    {({field, meta}: any) => (
                      <div className={styles.input}>
                        <Input
                          meta={meta}
                          placeholder='New password'
                          {...field}
                          type={'password'}
                        />
                      </div>
                    )}
                  </Field>
                  <Field name='confirmPassword'>
                    {({field, meta}: any) => (
                      <div className={styles.input}>
                        <Input
                          meta={meta}
                          placeholder='Confirm password'
                          {...field}
                          type={'password'}
                        />
                      </div>
                    )}
                  </Field>
                </>
              )}
              {!confirmEmail ? (
                <span className={cn(styles.field, styles.fieldPrivateInformation)}>
                  {/*If you already have a Read for My School account from a previous fundraiser,*/}
                  {/*please{' '}*/}
                  {/*<span className={styles.link}>*/}
                  {/*<Link to='/log-in'>LOG IN</Link>*/}
                  {/*</span>{' '}*/}
                  {/*to continue.*/}
                </span>
              ) : (
                <span className={cn(styles.field)}>
                  Check your inbox. An email verification link has been sent to your email. Please
                  click on the link to complete your registration.
                </span>
              )}
              {!confirmEmail && !agent.cordova && (
                <div className={styles.loginWithSocial}>
                  <span className={styles.label}>Or sign up with</span>
                  <div className={styles.socialButtons}>
                    <div className={styles.SocialBtn}>
                      <a href={`/auth/google?inviteCode=${match.params.code}`}><Google/></a>
                    </div>
                    <div className={styles.SocialBtn}>
                      <a href={`/auth/facebook?inviteCode=${match.params.code}`}><Facebook/></a>
                    </div>
                  </div>
                </div>
              )}
              <ButtonNavigation
                isLoading={isLoading}
                isDisabled={confirmEmail ? confirmEmail : isActiveBtn(values)}
                list={list}
                page={{...page, activePage: 2}}
                onClick={!isSuccessful && submitForm}
              />
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default withRouter(CreateAccount);
