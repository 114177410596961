import {createAsyncThunk} from '@reduxjs/toolkit';
import API from '../../REST_API/listApi';
import {getReadingRecords} from '../ReadingRecords/readingRecords.thunk';

export const getProgressMy = createAsyncThunk('progressMy/getProgressMyData', async () => {
  const response = await API.progressMy.getProgressMyData();
  return response.data;
});

export const getRecordsWithProgressMy = createAsyncThunk(
  'progressMy/getProgressMyData',
  async (_, thunkApi) => {
    const response = await API.progressMy.getProgressMyData();
    thunkApi.dispatch(getReadingRecords(response.data.id));
    return response.data;
  }
);

export const getAchievements = createAsyncThunk('progressMy/getAchievements', async () => {
  return await API.progressMy.getMyAchievements();
});

