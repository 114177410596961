import React, { useEffect, useRef } from 'react';
// import { createRipples } from 'react-ripples';
import ReactDOM from 'react-dom';

// const MyRipples = createRipples({
//   color: '#CEECFE',
//   during: 1000,
// });

// interface IRipple {
//   children: JSX.Element;
//   styles: { [key: string]: string };
// }

const Ripple: React.FC<any> = ({ children, styles }) => {
  const ref = useRef(null);
  const el = ReactDOM.findDOMNode(ref.current) as HTMLInputElement;
  useEffect(() => {
    if (el) {
      el.style.display = 'block';
    }
  }, [el]);

  return (
    <div style={styles}>
      {/* <MyRipples ref={ref}>{children}</MyRipples> */}
      {children}
    </div>
  );
};

export default Ripple;
