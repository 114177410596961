import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../../store/User/user.thunk';
import { Button, ErrorModal, FormatError, Input, Modal } from '../../../components';

import { editEmail } from '../../../REST_API/userApi';
import { Action, IState } from '../../../types';

import styles from '../Settings.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const validate = (values: { email: string }) => {
  const errors: { email?: string } = {};
  if (!values.email.includes('@') || values.email.length < 5) {
    errors.email = 'Email not valid';
  }
  return errors;
};

const EditEmailModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const user = useSelector((state: IState) => state.user.data);

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [error, setError] = useState<false | string>(false);

  useEffect(() => {
    setIsLoading(false);
    setIsSuccessful(false);
  }, [isOpen]);

  useEffect(() => {
    setTimeout(() => {
      document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    }, 100);
  }, [isOpen, error]);

  const onSubmit = async (values: { email: string }, actions: Action) => {
    setIsLoading(true);

    const succses = () => {
      setIsLoading(false);
      setIsSuccessful(true);
      dispatch(getUser());
      setTimeout(() => {
        onClose();
      }, 400);
    };
    const error = (data: any) => {
      setIsLoading(false);
      setError(FormatError(data));
      actions.setFieldError('email', FormatError(data));
    };

    editEmail({ email: values.email }, succses, error);
  };

  return (
    <>
      <Modal
        classModal={isOpen ? styles.emailModal : ''}
        styleModal={{ minHeight: 290, height: 290 }}
        isOpen={isOpen}
        close={error ? () => {} : onClose}
      >
        <>
          {isOpen && (
            <div className={styles.wrapperFormEmail}>
              <span className={styles.title}>Edit email</span>
              <Formik
                initialValues={{ email: user ? user.email : '' }}
                validate={validate}
                onSubmit={onSubmit}
              >
                {({ handleSubmit, submitForm, errors, touched, values }: any) => (
                  <form className={styles.formEmail} onSubmit={handleSubmit}>
                    <Field name='email'>
                      {({ field, meta }: any) => (
                        <Input meta={meta} placeholder='Email' {...field} />
                      )}
                    </Field>
                    <Button
                      styleBTN={{ heigth: 45 }}
                      isSuccessful={isSuccessful}
                      isLoading={isLoading}
                      disabled={
                        (errors.email && touched.email) || values.email === (user ? user.email : '')
                      }
                      onClick={isLoading ? null : submitForm}
                    >
                      Save
                    </Button>
                  </form>
                )}
              </Formik>
            </div>
          )}
        </>
      </Modal>
      <ErrorModal title={error || ''} cancel={() => setError(false)} isOpen={!!error} />
    </>
  );
};

export default EditEmailModal;
