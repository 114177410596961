import React, { Component } from 'react';
import { Animate } from 'react-move';

type IProgressProvider = {
  valueStart: number;
  valueEnd: number;
  duration: number;
  children: (value: number) => JSX.Element;
};

class AnimatedProgressProvider extends Component<IProgressProvider> {
  state: { isAnimated: boolean } = {
    isAnimated: false,
  };

  static defaultProps = {
    valueStart: 5,
  };

  componentDidMount() {
    this.setState({
      isAnimated: !this.state.isAnimated,
    });
  }

  render() {
    return (
      <Animate
        start={() => ({
          value: this.props.valueStart,
        })}
        update={() => ({
          value: [this.state.isAnimated ? this.props.valueEnd : this.props.valueStart],
        })}>
        {({ value }) => this.props.children(value)}
      </Animate>
    );
  }
}
export default AnimatedProgressProvider;
