import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';


import { Button } from '../../../components';

import { IManaged, IState } from '../../../types';

import styles from './Readers.module.scss';
import ReadersSelect from "../../../components/UI/ReadersSelect/ReadersSelect";

interface IReaders extends RouteComponentProps {
}

const Readers: React.FC<IReaders> = ({history}) => {
  const user = useSelector((state: IState) => state.user.data);

  if (!user || !user.managed) return null;

  const setCurrent = (selected: IManaged) => {
    localStorage.setItem('accessToken', selected.accessToken);
    window.location.reload();
  };

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>My Reader Profiles</span>
      {user.managed.length > 1 && <span className={styles.text}>Tap avatar to active reader profile.</span>}

      <ReadersSelect users={user.managed} onSetCurrent={setCurrent}
                     onEdit={(u) => history.push(`/settings/users/${u.id}/profile`)}
      />

      <div className={styles.wrapperButton}>
        <Button onClick={() => history.push('/settings/users/new/profile')}>
          Create a reader profile
        </Button>
      </div>
    </div>
  );
};

export default withRouter(Readers);
