import React from 'react';

interface Props {
  amount: number;
  currency: string;
}

const currencyChar: {[key: string]: string} = {
  USD: '$', EUR: '€', GBP: '£', AUD: '$', CAD: '$'
};

const MoneyAmount: React.FC<Props> = ({amount, currency}): JSX.Element => {
  return <>{(currencyChar[currency] || '') + amount.toLocaleString()}</>;
};

export default MoneyAmount;
