import React from 'react';
import cn from 'classnames';
import styles from './LoaderData.module.scss';

interface ILoaderData {
  children?: JSX.Element;
  width?: number;
  colorWhite?: boolean | any;
  className?: string;
}

const DEFAULT_WIDTH = 40;

const LoaderData: React.FC<ILoaderData> = ({children, width, colorWhite, className}) => {
  return (
    <div
      style={{width: width || DEFAULT_WIDTH, height: width || DEFAULT_WIDTH, margin: 0}}
      className={cn(styles.loader, className, colorWhite && styles.loaderWhite)}
    >
      {children}
    </div>
  );
};

export default LoaderData;
