import {intercomAgent} from "./intercom";
import * as Sentry from '@sentry/browser';

const ONESIGNAL_APP_ID = '3671967f-11a2-45f4-abc0-4c42c5cad99d';

export const APP_STORE_LINK = 'https://apps.apple.com/gb/app/read-for-my-school/id1521707966';
export const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=com.readformyschool';

export const agent = {
  initialize() {
    document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);
    document.addEventListener('resume', this.onResume.bind(this), false);
    document.addEventListener('pause', this.onPause.bind(this), false);
    document.addEventListener('backbutton', this.onBackbutton.bind(this), false);
    document.addEventListener('userloaded', this.onUserLoaded.bind(this), false);
    // document.addEventListener('clearcache', this.clearCache.bind(this), false);
  },

  openScanner(success, failure) {
    if (window.cordova) {
      agent.isScanMode = true;
      let self = this;
      window.cordova.plugins.barcodeScanner.scan(
        function (result) {
          console.info(result, JSON.stringify(result));
          success(result.text);
        },
        function (error) {
          agent.isScanMode = false;
          console.error("scan error", error);
          if (error) failure(error);
        },
        {
          preferFrontCamera: false, // iOS and Android
          showFlipCameraButton: false, // iOS and Android
          showTorchButton: true, // iOS and Android
          torchOn: false, // Android, launch with the torch switched on (if available)
          saveHistory: true, // Android, save scan history (default false)
          prompt: "...", // Android
          resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
          formats: "QR_CODE,EAN_8,EAN_13", // default: all but PDF_417 and RSS_EXPANDED
          orientation: "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
          disableAnimations: false, // iOS
          disableSuccessBeep: false // iOS and Android
        }
      );
    }
  },

  isAndroid() {
    let userAgent = navigator.userAgent;
    return /android/i.test(userAgent);
  },

  onDeviceReady() {
    console.log(
      'onDeviceReady, cordova ' +
      window.cordova.version +
      ', device ' +
      (window.device && window.device.model)
    );
    setupStatusBar();
    setupPushNotifications();
    setupDeeplinks();

    setTimeout(function () {
      navigator.splashscreen.hide();
    }, 1000);


    if (window.FCMPlugin) {
      window.FCMPlugin.getToken(function (token) {
        if(intercomAgent) intercomAgent.setPushToken(token);
      });

      window.FCMPlugin.onNotification(function (data) {
        if (data.wasTapped) {
          //Notification was received on device tray and tapped by the user.
          console.info('FCM message', JSON.stringify(data));
        } else {
          //Notification was received in foreground. Maybe the user needs to be notified.
          console.info('FCM message', JSON.stringify(data));
        }
      });

    }
  },

  onUserLoaded(e) {
    let user = e.detail;
    if (user)
      Sentry.configureScope(function (scope) {
        scope.setUser({"email": user.email, "id": user.id, "role": user.role});
      });

    if (window.plugins && window.plugins.OneSignal && user) {
      console.log('OneSignal.setExternalUserId =  ' + user.id);
      try {
        window.plugins.OneSignal.setExternalUserId(user.id, (results)=>{
            console.log('Results of setting external user id');
            console.log(results);
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  },

  onBackbutton() {
    if (agent.isScanMode) {
      agent.isScanMode = false;
      return false;
    }

    console.log('backPressed  pathname=' + window.location.pathname + ', hash=' + window.location.hash);
    if (!window.location.hash) window.history.back();
    if (
      window.location.hash.endsWith('welcome') ||
      window.location.hash.endsWith('main') ||
      window.location.hash.endsWith('progress') ||
      window.location.hash.endsWith('books/my') ||
      window.location.hash.endsWith('books/recommended') ||
      window.location.hash.endsWith('settings')
    ) {
      navigator.app.exitApp();
    } else {
      window.history.back();
    }
  },

  onPause() {
  },

  onResume() {
  },

  get cordova() {
    return window.cordova;
  },
  //
  // clearCache() {
  //   if (this.cordova && window.CacheClear)
  //     window.CacheClear(() => {
  //       console.log("webview cache has been cleared");
  //     }, () => {
  //       console.log("clear cache failed");
  //     });
  // },

  setOnesignalExternalId(userId) {
  },
};

// function setupStatusBar() {
//   StatusBar.overlaysWebView(false);
//   StatusBar.styleDefault();
//   StatusBar.backgroundColorByHexString('#FFF');
// }

function setupStatusBar() {
  window.StatusBar.overlaysWebView(false);
  window.StatusBar.styleBlackOpaque();
  window.StatusBar.backgroundColorByHexString('#00aeef');
}

function setupPushNotifications() {
  if (window.plugins.OneSignal) {
    console.log('init OneSignal');
    window.plugins.OneSignal.setAppId(ONESIGNAL_APP_ID);
    window.plugins.OneSignal.setNotificationOpenedHandler(function(jsonData) {
      console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    });
  }
}

function setupDeeplinks() {
  console.log("universalLinks.subscribe");
  window.universalLinks.subscribe(null, function (eventData) {
    console.log('Did launch application from the link: ' + eventData.url);
    let url = eventData.url.replace('https://', '').replace('http://', '').replace('rfms://', '');
    url = url.substring(url.indexOf("/"));
    window.location.href = url;
  });
}

// agent.initialize();
