import React, { useEffect, useRef } from 'react';
// import { createRipples } from 'react-ripples';
import ReactDOM from 'react-dom';
import cn from 'classnames';

import { LoaderData } from '../../';
import check from './check.svg';
import searchIcon from '../../../assets/images/icons/search.svg';

import styles from './Button.module.scss';

// const MyRipples = createRipples({
//   color: '#CEECFE',
//   during: 1000,
// });

interface Button {
  onClick: () => void;
  text?: string | number;
  children?: string | number | JSX.Element;
  type?: string;
  className?: string;
  disabled?: boolean;
  [key: string]: any;
}

const Button: React.FC<Button> = ({
                                    onClick,
                                    text,
                                    children,
                                    type,
                                    className,
                                    isLoading = null,
                                    isSuccessful,
                                    styleBTN,
                                    isSearch,
                                    disabled = false,
                                    ...props
                                  }) => {
  const ref = useRef(null);
  const el = ReactDOM.findDOMNode(ref.current) as HTMLInputElement;
  useEffect(() => {
    if (el) {
      el.style.display = 'block';
      setTimeout(() => {
        el.style.display = 'block';
      }, 100);
    }
  }, [el]);
  return (
    <button
      {...props}
      style={styleBTN}
      onClick={() => (disabled || isLoading || isSuccessful ? null : onClick())}
      className={cn(
        styles.buttonContent,
        isSuccessful && styles.isSuccessful,
        disabled && styles.disabled,
        type && styles[type],
        className
      )}
      type='button'
    >
      {isLoading && (
        <div className={styles.isLoading}>
          <LoaderData colorWhite={true} width={20}/>
        </div>
      )}
      {isSuccessful && (
        <div className={styles.check}>
          <img alt='check' src={check}/>
        </div>
      )}
      {isSearch && (
        <div className={styles.search}>
          <img alt='search' src={searchIcon}/>
        </div>
      )}
      {!isLoading && !isSuccessful && (
        <span className={cn(styles.textContent, type && styles[type])}>{text || children}</span>
      )}
    </button>
  );
  //   <MyRipples ref={ref}>
  //     <div
  //       {...props}
  //       style={styleBTN}
  //       onClick={() => (disabled ? null : onClick())}
  //       className={cn(type ? styles.buttonContent : styles.buttonContent, isSuccessful && styles.isSuccessful)}>
  //       <div className={styles.isLoading}>{isLoading && <LoaderData width={24} />}</div>
  //       <div className={styles.check}>{isSuccessful && <img alt='check' src={check} />}</div>
  //       <span className={type ? styles.textContent : styles.textContent}>{text || children}</span>
  //     </div>
  //   </MyRipples>
};

export default Button;
