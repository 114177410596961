import React, {useEffect, useState} from 'react';
import cn from 'classnames';

import {Button, LoaderData, RadioButtons} from '../../../components';

import {getListAvatar} from '../../../store/listAvatar/listAvatar.thunk';
import {IState} from '../../../types';
import {Check} from '../../../assets/images/icons';

import styles from './ChangeAvatar.module.scss';
import {useSelector, useDispatch} from 'react-redux';

interface IChangeAvatar {
    url: string;
    setAvatar: (data: string) => void;
    setActive: (data: string) => void;
}

const getCurrentPage = (url: string) => {
    if (url && url.includes('boys')) return 'Boys';
    if (url && url.includes('girls')) return 'Girls';
    if (url && url.includes('others')) return 'Others';
    return 'Boys';
};

const listTab = ['Boys', 'Girls', 'Others'];

const ChangeAvatar: React.FC<IChangeAvatar> = ({url, setAvatar, setActive}) => {
    const dispatch = useDispatch();
    const listAvatar = useSelector((state: IState) => state.listAvatar.data);
    const [activePage, setactivePage] = useState(getCurrentPage(url));
    const [currentAvatar, setCurrentAvatar] = useState<string>(url);
    const user = useSelector((state: IState) => state.user.data);


    useEffect(() => {
        if (!listAvatar || !listAvatar[activePage])
            dispatch(
                getListAvatar({
                    url: `/api/v1/avatars/${
                        user?.role === 'TL' ? 'admins' : activePage.toLowerCase()}`, type: activePage
                })
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePage, user]);

    return (
        <div className={styles.wrapper}>
            {user?.role === 'TL' ? '':
            <div className={styles.listTab}>
                {listTab.map((item: string) => (
                    <button className={styles.tab} onClick={() => setactivePage(item)} key={item}>
                        <RadioButtons isCheck={activePage === item}/>
                        <span className={cn(styles.tabText, activePage === item && styles.tabTextAvtive)}>
              {item}
            </span>
                    </button>
                ))}
            </div>}
            <div className={styles.listImg}>
                {listAvatar && listAvatar[activePage] ? (
                    listAvatar[activePage].map((item: { url: string; id: number }) => (
                        <button
                            onClick={() => setCurrentAvatar(item.url)}
                            className={cn(
                                styles.wrapperImg,
                                currentAvatar === item.url && styles.wrapperImgCurrent
                            )}
                            key={item.id}
                        >
                            <img className={styles.img} src={item.url} alt='avatar'/>
                            {currentAvatar === item.url && <Check/>}
                        </button>
                    ))
                ) : (
                    <LoaderData width={60}/>
                )}
            </div>
            <div className={styles.button}>
                {listAvatar && listAvatar[activePage] && (
                    <Button
                        disabled={!currentAvatar}
                        onClick={() => {
                            setAvatar(currentAvatar);
                            setActive('form');
                        }}
                    >
                        Choose
                    </Button>
                )}
            </div>
            <div className={styles.listButton}>
                <Button
                    type='thin'
                    onClick={() => {
                        setActive('form');
                    }}
                >
                    Cancel
                </Button>
                <Button
                    disabled={!currentAvatar}
                    onClick={() => {
                        setAvatar(currentAvatar);
                        setActive('form');
                    }}
                >
                    Choose
                </Button>
            </div>
            <br/>
        </div>
    );
};

export default ChangeAvatar;
