import React from 'react';
import styles from './Books.module.scss';
import NavigationBooks from '../../navigation/NavigationBooks';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';

const Books: React.FC = () => {
  const location = useLocation().pathname.slice(1);

  const isWrapper = !location.includes('books/recommended') && !location.includes('books/my');

  return (
    <div className={cn(styles.wrapper, !isWrapper && styles.wrapperBG, )}>
      <NavigationBooks />
    </div>
  );
};
export default Books;
