import React, { useState } from 'react';
import styles from './BookList.module.scss';
import cn from 'classnames';
import { Link } from 'react-router-dom';

const Books: React.FC<any> = ({listBook}) => {
  return (
    <div key={listBook.id} className={styles.wrapperBooks}>
      <div className={styles.headerBooks}>
        <span className={styles.headerBooksTitle}>{listBook.title}</span>
        <div>
          {listBook.books.length > 0 &&
          <Link to={`/books/lists/${listBook.id}`} className={styles.listBooksText}>
            Show All ({listBook.books.length})
          </Link>}
        </div>
      </div>
      <div className={cn(styles.listBooks)}>
        {listBook.books.map((item: {[key: string]: string}) => (
          <Link to={`/books/${item.isbn}`} className={styles.book} key={item.id}>
            <img className={styles.image} src={item.image} alt=''/>
          </Link>
        ))}
      </div>
      {listBook.books.length===0 && <div className={styles.listEmpty}>No books yet</div>}
    </div>
  );
};

export default Books;
