import React from 'react';
import cn from 'classnames';

import styles from './Stepper.module.scss';

interface IStepper {
  quantity?: number;
  value: number;
}

const Stepper: React.FC<IStepper> = ({ quantity = 5, value }) => {
  const points: number[] = new Array(quantity).fill(0, 0, 5);
  return (
    <div className={styles.wrapper}>
      {points.map((item: number, index: number) => (
        <div
          className={cn(
            index !== value ? styles.circle : styles.currentCircle,
            value > index && styles.passed
          )}
          key={index}
        />
      ))}
    </div>
  );
};

export default Stepper;
