import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getRecommendedBooks } from '../../../store/Books/books.thunk';
import { IState } from '../../../types';
import { LogoIcon } from '../../../assets/images/icons';
import styles from './recommendedBooks.module.scss';
import BookList from '../BookList/BookList';
import { getConfig } from "../../../store/Events/events.thunk";

const RecommendedBooks = () => {
  const dispatch = useDispatch();
  const recommendedBooks = useSelector((state: IState) => state.books.data.recommendedBooks);
  const config = useSelector((state: IState) => state.config.data);

  const myRef = useRef(null);

  useEffect(() => {
    dispatch(getRecommendedBooks());
    dispatch(getConfig());
  }, []);

  useEffect(() => {
    if (myRef.current) handleScroll();
  }, [myRef]);

  const handleScroll = () => {
    // @ts-ignore
    myRef.current.scrollIntoView(false);
  };

  if (!recommendedBooks)
    return (
      <div className={styles.wrapperListBook}>
        <div ref={myRef} className={styles.recommendation}>
          <LogoIcon/>
          <span>
            {config && config['tooltip.text.books_recommendation']}
          </span>
        </div>
      </div>
    );

  return (
    <div className={styles.wrapperListBook}>
      <div ref={myRef} className={styles.recommendation}>
        <LogoIcon/>
        <span>
         {config && config['tooltip.text.books_recommendation']}
        </span>
      </div>
      {recommendedBooks.map((item: any) => (
        <BookList key={item.id} listBook={item}/>
      ))}
    </div>
  );
};

export default RecommendedBooks;
