import React, { useEffect } from 'react';

import ButtonNavigation from './ButtonNavigation/ButtonNavigation';

import { ChildOnBook } from '../../assets/images/icons';

import styles from './Welcome.module.scss';
import { analytics } from "../../analytics";

const Welcome: React.FC = () => {
  useEffect(()=>{
    analytics.logView('welcome');
  });
  return (
    <div className={styles.wrapper}>
      <ChildOnBook className={styles.ChildOnBook} />
      <div className={styles.body}>
        <span className={styles.title}>Join your fundraiser</span>
        <span className={styles.text}>Ready to pick up a book and start your challenge?</span>
        <div className={styles.links}>
          © {new Date().getFullYear()} Read for My School<br/>
          <a href="https://www.readformyschool.com" target="_blank">readformyschool.com</a>
          &nbsp;|&nbsp;
          <a href="https://www.readformyschool.com/toolkit/privacy-policy/" target="_blank">Privacy Policy</a>
        </div>
      </div>
      <ButtonNavigation welcome={true}/>
    </div>
  );
};

export default Welcome;
