import React, { useEffect } from 'react';

import { useDispatch, useSelector } from "react-redux";
import { Link, RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import { ICardBook, IState } from "../../../types";
import { getPublicEvent } from "../../../store/DonationPage/thunks";

import styles from './DonateSuccess.module.scss';
import Beneficiary from "../Beneficiary/Beneficiary";
import { LoaderData } from "../../../components";
import MoneyAmount from "../../../components/UI/MoneyAmount";
import ShareButtons from "../../../components/ShareButtons/ShareButtons";
import BooksWishlist from "./BooksWishlist/BooksWishlist";
import { analytics } from "../../../analytics";
import { getBookDetails } from "../../../store/Books/books.thunk";

interface Props extends RouteComponentProps {

}

const DonateSuccess = ({history}: Props) => {
  const {amount} = useParams<{ amount: any }>();
  const {code} = useParams<{ code: string }>();
  const dispatch = useDispatch();
  const event = useSelector((state: IState) => state.dp.event);
  const dpPath = history.location.pathname.substring(0, history.location.pathname.lastIndexOf('/'))
  const bookLists = useSelector((state: IState) => state.dp.bookLists);
  const book: ICardBook = useSelector((state: IState) => state.books.data.bookDetails);

  const wishlistBooks = bookLists && bookLists.find(list => list.type === 'wishlist')?.books || [];

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getPublicEvent(code));
    analytics.logView('donate_success');
  }, []);

  useEffect(() => {
    if (wishlistBooks.length)
      dispatch(getBookDetails(+wishlistBooks[0].isbn))
  }, [wishlistBooks]);


  return (
    <div className={styles.DonateSuccess}>
      <div className={styles.contentContainer}>

        {event ?
          <div className={styles.content}>
            <h2>Thank you!</h2>
            <div className={styles.caption}>
              You have successfully made a pledge donation of <strong>
              <MoneyAmount amount={amount ? amount * 1 : 0} currency={event.currency}/>
            </strong> to support <Link to={dpPath}>{event.name}</Link> on behalf of
            </div>


            <Beneficiary user={event.invite.from}/>

            {book &&
                <>
                    <div className={styles.divider}/>
                    <BooksWishlist user={event.invite.from} book={book}/>
                </>}

            <div className={styles.divider}/>

            <div>
              <div className={styles.shareTitle}>Help spread the word!</div>

              <div className={styles.shareMessage}>
                If you know someone who might be able to help my cause, please send them my donation page!
              </div>

              <ShareButtons shareLink={event.shareLink}/>


            </div>

          </div> :
          <LoaderData className={styles.loader} colorWhite={true}/>
        }
      </div>
    </div>
  );
};

export default withRouter(DonateSuccess);
