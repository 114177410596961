import { createSlice } from '@reduxjs/toolkit';
import { FormatError } from '../../components';
import {
  getListsBooks,
  getRecommendedBooks,
  getList,
  deleteList,
  getSearchBooks,
  getBookDetails,
  deleteBookDetails,
  deleteBook,
} from './books.thunk';
import { IBook, IState } from '../../types';

interface IinitialState {
  error: boolean | string;
  data: {
    recommendedBooks: IBook[] | null;
    listBook: IBook[] | null;
    list: IBook[] | null;
    searchBooks: any;
    bookDetails: any;
  };
  loading: boolean;
}

const reducerEvent = createSlice({
  name: 'books',
  initialState: {
    error: false,
    data: {
      recommendedBooks: null,
      listBook: null,
      list: null,
      searchBooks: null,
      bookDetails: null,
    },
    loading: false,
  } as IinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getListsBooks.pending, (state: IinitialState) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getListsBooks.fulfilled, (state: IinitialState, action) => {
      state.loading = false;
      state.data.listBook = action.payload;
    });
    builder.addCase(getListsBooks.rejected, (state: IinitialState) => {
      state.loading = false;
    });
    builder.addCase(getRecommendedBooks.pending, (state: IinitialState) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getRecommendedBooks.fulfilled, (state: IinitialState, action) => {
      state.loading = false;
      state.data.recommendedBooks = action.payload;
    });
    builder.addCase(getRecommendedBooks.rejected, (state: IinitialState) => {
      state.loading = false;
    });
    builder.addCase(getList.pending, (state: IinitialState) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getList.fulfilled, (state: IinitialState, action) => {
      state.loading = false;
      state.data.list = action.payload;
    });
    builder.addCase(getList.rejected, (state: IinitialState) => {
      state.loading = false;
    });
    builder.addCase(getSearchBooks.pending, (state: IinitialState) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getSearchBooks.fulfilled, (state: IinitialState, action) => {
      state.loading = false;
      state.data.searchBooks = action.payload;
    });
    builder.addCase(getSearchBooks.rejected, (state: IinitialState) => {
      state.loading = false;
    });
    builder.addCase(deleteList.fulfilled, (state: IinitialState) => {
      state.loading = false;
      state.data.list = null;
    });
    builder.addCase(getBookDetails.pending, (state: IinitialState) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getBookDetails.fulfilled, (state: IinitialState, action) => {
      state.loading = false;
      state.data.bookDetails = action.payload;
    });
    builder.addCase(getBookDetails.rejected, (state: IinitialState, action) => {
      state.error = FormatError(action);
      state.loading = false;
    });
    builder.addCase(deleteBookDetails.fulfilled, (state: IinitialState) => {
      state.loading = false;
      state.data.bookDetails = null;
    });
    builder.addCase(deleteBook.fulfilled, (state: IinitialState, action) => {
      let currnetIndex = 0;
      const bookshelf = state?.data.listBook?.find((item, index) => {
        currnetIndex = index;
        return item.type === 'bookshelf';
      });
      const currentBookshelf = bookshelf?.books.filter((book) => book.id !== action.payload);
      // @ts-ignore
      state.data.listBook[currnetIndex].books = currentBookshelf;
      state.loading = false;
    });
  },
});

export default reducerEvent.reducer;
