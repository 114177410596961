import React, { useState, createContext, useEffect } from 'react';
import { Modal } from '..';
import FirstStep from './FirstStep/FirstStep';
import SelectBook from './SelectBook/SelectBook';
import LogMinutes from './LogMinutes/LogMinutes';
import Goal from './Goal/Goal';
import cn from 'classnames';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './AddReadingModal.module.scss';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../types';
import { getListsBooks } from "../../store/Books/books.thunk";
import { RouteComponentProps, withRouter } from "react-router-dom";
import CompleteConfirm from "./CompleteConfirm/CompleteConfirm";


interface IProps extends RouteComponentProps {
  isOpen: boolean;
  onClose: () => void;
}


export const AppContext = createContext({} as any);

const AddReadingModal: React.FC<IProps> = ({isOpen, onClose, history}) => {

  const [currentBook, setCurrentBook] = useState<number>();
  const bookshelf = useSelector((state: IState) =>
    state.books.data.listBook?.find((item) => item.type === 'bookshelf')
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [timeAdd, setTimeAdd] = useState(0);

  const onFindClick = () => {
    onClose();
    setTimeout(()=>{
      history.push('/books/search');
    }, 500)
  }

  return (
    <Modal isOpen={isOpen} close={onClose}>
      <AppContext.Provider
        value={{
          currentIndex,
          setCurrentIndex,
          timeAdd,
          currentBook,
        }}
      >
        {isOpen ? (
          <>
            {bookshelf && (
              <AliceCarousel
                swipeDisabled={true}
                buttonsDisabled={true}
                dotsDisabled={true}

                slideToIndex={currentIndex}
              >
                <div className={cn(styles.wrapper)}>
                  <FirstStep
                    setCurrentSlide={setCurrentIndex}
                    skip={bookshelf.books.length > 0}
                    onFindClick={onFindClick}/>
                </div>

                <div className={cn(styles.wrapper)}>
                  <SelectBook
                    setCurrentSlide={setCurrentIndex}
                    currentBook={currentBook}
                    setcurrentBook={setCurrentBook}
                  />
                </div>

                <div className={cn(styles.wrapper, styles.wrapperIsActive)}>
                  <LogMinutes setCurrentSlide={setCurrentIndex} setTimeAdd={setTimeAdd}/>
                </div>

                <div className={cn(styles.wrapper, styles.wrapperIsActive)}>
                  <CompleteConfirm setCurrentSlide={setCurrentIndex}/>
                </div>

                <div className={cn(styles.wrapper)}>
                  <Goal onClose={onClose}/>
                </div>
              </AliceCarousel>
            )}
          </>
        ) : (
          <></>
        )}
      </AppContext.Provider>
    </Modal>
  );
};

export default withRouter(AddReadingModal);
