import {
  IGetDonationsData,
  IGetDonationsError,
  IGetDonationsLoading,
} from '../store/Donations/actionsDonations';
import {IGetEventData, IGetEventError, IGetEventLoading} from '../store/Events/actionsEvents';
import {
  IGetProgressMyData,
  IGetProgressMyError,
  IGetProgressMyLoading,
} from '../store/ProgressMy/actionsProgressMy';
import {
  IGetReadingRecordsData,
  IGetReadingRecordsError,
  IGetReadingRecordsLoading,
} from '../store/ReadingRecords/actionsReadingRecords';
import {
  IGetSchoolLeaderboardData,
  IGetSchoolLeaderboardError,
  IGetSchoolLeaderboardLoading,
} from '../store/SchoolLeaderboard/actionsSchoolLeaderboard';
import {
  IGetTeamLeaderboardData,
  IGetTeamLeaderboardError,
  IGetTeamLeaderboardLoading,
} from '../store/TeamLeaderboar/actionsTeamLeaderboard';
import {IGetUserData, IGetUserError, IGetUserLoading} from '../store/User/actionsUser';
import {IPublicEvent, ITeamMember} from "../store/DonationPage/types";
import exp from "constants";

export interface ILocation {
  address1: string;
  city: string;
  country: string;
  currency: string;
  displayCountry: string;
  id: number;
  schoolName: string;
  state: string;
  valid: boolean;
  zip: string;
}

export interface IEvent {
  approved: boolean;
  closed: boolean;
  created: number;
  currency: string;
  end: number;
  id: number;
  location: Array<ILocation>;
  name: string;
  role: string;
  start: number;
  teamId: number;
  teamMemberId: number;
}

export interface IManaged {
  id: number | string;
  firstName: string;
  lastName: string;
  accessToken: string;
  avatar: string;
  isCurrent: boolean;
}

export interface IUser {
  id: number;
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  emailVerified?: boolean;
  avatar: string;
  created: number;
  hasProfile: boolean;
  password: boolean;
  role: string;
  managed: Array<IManaged>;
  team: ITeam;
  gradeDisplay?: string
}

export interface IUserCreate {
  id: number | string;
  displayName: string;
  email: string;
  activeAt: number;
  avatar: string;
  created: number;
  createdBy: number;
  events: Array<IEvent>;
  firstName: string;
  gender: string;
  lastName: string;
  password: boolean;
  role: string;
  team: ITeam;
  personalMessage?: string;
}

export interface details {
  [key: string]: string | Array<string>;

  defaultPersonalMessage: string;
  defaultSummary: string;
  objectives: Array<string>;
  personalMessage: string;
  photo: string;
  video: string;
  summary: string;
}

export interface options {
  accessToDonationPage: true
  showBooks: true
  showDonations: true
  showMinutes: true
}

export interface goals {
  fundraising: number;
  fundraisingPerMember: number;
  readingBooks: number;
  readingMins: number;
  readingMinsEnabled: number;
  readingMinsPerMember: number;
}

interface progress {
  booksSum: number;
  minsSum: number;
  raisedSum: number;
  recordsCount: number;
  donationsCount?: number;
  where: object;
  with: object;
}

export interface IAddReadingResponse {
  id: number;
  memberId: number;
  mins: number;
  where: string;
  with: string;
}


export interface IEvents {
  id: number;
  name: string;
  closed: boolean;
  created: number;
  currency: string;
  details: details;
  end: number;
  goals: goals;
  location: ILocation;
  options: options;
  pageViews: number;
  progress: progress;
  shareLink: string;
}

interface team {
  eventId: number;
  grade: number;
  gradeDisplay: string;
  id: number;
  name: string;
  ownerId: number;
  size: number;
  targetSize: number;
}

interface beneficiary {
  avatar: string;
  createdBy: number;
  displayName: string;
  firstName: string;
  id: number;
  lastName: string;
  password: boolean;
}

export interface IDonations {
  amount: number;
  beneficiary: beneficiary;
  created: number;
  donorEmail: string;
  donorMessage: string;
  donorName: string;
  id: number;
  matchCorp: boolean;
  memberId: number;
  team: team;
}

export interface ISignUpData {
  error: false | string;
  data: null | any;
  loading: boolean;
  currentTeam: ITeam;
}

export interface IProgress {
  booksSum: number;
  minsSum: number;
  pageViews: number;
  raisedSum: number;
  recordsCount: number;
  donationsCount: number | 0;
  where: { school: number; home: number };
  with: { friends: number; family: number; alone: number };

  [key: string]: number | { [key: string]: number };
}

export interface ITeam {
  eventId: number;
  goals: { [key: string]: number };
  grade: number;
  gradeDisplay: string;
  id: number;
  name: string;
  ownerId: number;
  progress: IProgress;
  size: number;
  targetSize: number;
  owner?: { avatar: string };
  inviteCode?: string;
  inviteRole?: string;
}

export interface IProgressMy {
  achievements: Array<string>;
  created: number;
  eventId: number;
  id: number;
  progress: IProgress;
  summary: IProgress;
  team: ITeam;
  teamId: number;
  userId: number;
}

export interface IAchievement {
  id: number;
  name: string;
  doneText: string;
  goalText: string;
  imageUrl: string;
  partnerLogoUrl?: string;
  partnerUrl?: string;
  achieved: boolean;
  viewed?: boolean;
  createdAt?: number;
}

export interface IReadingRecord {
  created: number;
  day: string;
  id: number;
  memberId: number;
  mins: number;
  books: number;
  book: any;
}

export interface IReadingDayRecord {
  day: string;
  mins: number;
  weekDay: string;
}

export interface IReadingRecords {
  natural: Array<IReadingRecord>;
  days: Array<IReadingDayRecord>;
}

export interface Leaderboard {
  achievements: Array<string>;
  eventId: number;
  id: number;
  progress: IProgress;
  teamId: number;
  user: IUser;
  userId: number;
}

export type ITeamLeaderboard = Array<Leaderboard>;

export type ISchoolLeaderboard = Array<Leaderboard>;

export interface ICardBook {
  id: number;
  listId: number;
  title: string;
  description: string;
  image: string;
  author: string;
  publisher: string;
  publishedDate: string;
  language: string;
  isbn: string;
  externalId: string;
  bwbLink: string;
  lists: []
  infoLink: string;
  pageCount: number;
  categories: string;

  storeLinks: BookStoreLink[]
}

export function compareBookStoreLink(a: BookStoreLink, b: BookStoreLink): number {
  if (a.store === BookStore.BWB && b.store !== BookStore.BWB) {
    return 1;
  }
  if (a.store !== BookStore.BWB && b.store === BookStore.BWB) {
    return -1;
  }
  return 0;
}

export enum BookStore {
  BWB = 'BWB',
  RW = 'RW',
}
export interface BookStoreLink {
  store: BookStore
  price: number;
  link: string;
}

export interface IBook {
  id: number;
  title: string;
  owner: {
    id: number;
    displayName: string;
    avatar: string;
    email: string;
    activeAt: number;
    createdBy: number;
    created: number;
    role: string;
    firstName: string;
    lastName: string;
    events: any;
    password: true;
  };
  country: any;
  minGrade: number;
  maxGrade: number;
  status: string;
  size: number;
  created: number;
  books: ICardBook[];
  type?: string;
}

export interface IState {
  user: { error: false | string; data: IUser | null; loading: boolean };
  events: { error: false | string; data: IEvents | null; loading: boolean };
  dp: { error: false | string; event: IPublicEvent | null; bookLists: Array<IBook>, donations: [], member: ITeamMember, loading: boolean };
  donations: { error: false | string; data: Array<IDonations> | null; loading: boolean };
  progressMy: { error: false | string; data: IProgressMy | null; achievements: [], loading: boolean };
  readingRecords: { error: false | string; data: IReadingRecords | null; loading: boolean };
  teamLeaderboard: { error: false | string; data: ITeamLeaderboard | null; loading: boolean };
  schoolLeaderboard: { error: false | string; data: ISchoolLeaderboard | null; loading: boolean };
  books: {
    error: false | string;
    data: {
      recommendedBooks: IBook[] | null;
      listBook: IBook[] | null;
      list: IBook;
      searchBooks: any;
      bookDetails: any;
    };
    loading: boolean;
  };
  config: { error: false | string; data: null; loading: boolean };
  addReading: { error: false | string; data: false | string; loading: boolean };
  createReaderProfile: { error: false | string; data: null | any; loading: boolean };
  listTeam: { error: false | string; data: null | ITeam[]; loading: boolean };
  listAvatar: {
    error: false | string;
    data: null | { [Boys: string]: any; Girls: any; Other: any };
    loading: boolean;
  };
  signUpData: { error: false | string; data: null | any; loading: boolean; currentTeam: ITeam };
}

export interface IProfile {
  teamId?: number;
  team?: number;
  firstName: string;
  lastName: string;
  displayName: string;
  personalMessage: string;
  avatar: string;
}

export type Action =
  | IGetDonationsData
  | IGetDonationsError
  | IGetDonationsLoading
  | IGetEventData
  | IGetEventError
  | IGetEventLoading
  | IGetProgressMyData
  | IGetProgressMyError
  | IGetProgressMyLoading
  | IGetReadingRecordsData
  | IGetReadingRecordsError
  | IGetReadingRecordsLoading
  | IGetSchoolLeaderboardData
  | IGetSchoolLeaderboardError
  | IGetSchoolLeaderboardLoading
  | IGetTeamLeaderboardData
  | IGetTeamLeaderboardError
  | IGetTeamLeaderboardLoading
  | IGetUserData
  | IGetUserError
  | IGetUserLoading
  | any;
