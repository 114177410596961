import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { IEvents, IProgress, IProgressMy, IState } from '../../types';

import styles from './index.module.scss';
import { ArrowIcon, CompletedIcon, HelpIcon, NotCompletedIcon } from "../../assets/images/icons";

const ToDoList: React.FC = () => {
  const [open, setOpen] = useState<boolean>(true);
  const summary = useSelector((state: IState) => state.progressMy.data?.summary);
  useEffect(() => {
    if (summary && calculateCompletion(summary) === 4) {
      setOpen(false);
    }
  }, [summary]);

  if (!summary) return null;

  const completed = calculateCompletion(summary);

  return (
    <>

      <div className={styles.wrapper}>

        <div onClick={() => setOpen(!open)} className={`${styles.arrowBtn} ${open && styles.arrowUp}`}>
          <ArrowIcon/>
        </div>

        <span className={styles.heading}>Getting Started</span>
        <span className={styles.name}>My To-Do List</span>
        <span className={styles.textEvents}>
          {completed} out of 4 completed.<br/>
          {/*Simple tasks to support your fundraised.*/}
        </span>

        {open &&
            <div className={open && styles.items}>
                <ToDoItem completed={true} title="Profile"
                          link="#">
                    Register to support your fundraiser.
                </ToDoItem>
                <ToDoItem completed={summary.raisedSum > 0} title="Pledge"
                          link="https://intercom.help/rfms/en/articles/4504408-how-can-i-donate-to-my-school-fundraiser-on-read-for-my-school">
                    Make a pledge donation to support your fundraiser.
                </ToDoItem>
                <ToDoItem completed={summary.minsSum > 0} title="Read"
                          link="https://intercom.help/rfms/en/articles/4502346-tracking-your-reading-sessions-on-read-for-my-school">
                    Track progress to your favorite book you are reading.
                </ToDoItem>
                <ToDoItem completed={summary.pageViews > 1} title="Share"
                          link="https://intercom.help/rfms/en/articles/4504388-how-to-share-your-donation-page-on-read-for-my-school">
                    Send your donation page to donors to raise money for your fundraiser.
                </ToDoItem>
            </div>}
      </div>

    </>
  );
};

const calculateCompletion = (summary: IProgress) => 1 +
  (summary.raisedSum > 0 ? 1 : 0) +
  (summary.minsSum > 0 ? 1 : 0) +
  (summary.pageViews > 1 ? 1 : 0);

interface ItemProps {
  completed?: boolean
  link: string,
  title: string
  children?: string
}

const ToDoItem: React.FC<ItemProps> = ({completed, link, title, children}) => {
  return (<a href={link} target="_blank" className={styles.item}>
    <div>
      {completed ? <CompletedIcon className={styles.completedIcon}/> :
        <NotCompletedIcon className={styles.notCompletedIcon}/>}
    </div>
    <div>
      <div className={`${styles.itemTitle}  ${completed ? styles.completed : ''}`}>{title} <HelpIcon/></div>
      <div className={`${styles.itemCaption} ${completed ? styles.completed : ''}`}>{children}</div>
    </div>
  </a>)
}

export default ToDoList;
