import { createSlice } from '@reduxjs/toolkit';
import { getPublicEvent, getTeamMember, getDonations, getBookLists } from "./thunks";

const slice = createSlice({
  name: 'dp',
  initialState: {
    error: false,
    event: null,
    bookLists: [],
    donations: [],
    member: null,
    isLoading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPublicEvent.fulfilled, (state, {payload}) => {
      state.isLoading = false;
      state.event = payload;
    });
    builder.addCase(getBookLists.fulfilled, (state, {payload}) => {
      state.isLoading = false;
      state.bookLists = payload;
    });
    builder.addCase(getDonations.fulfilled, (state, {payload}) => {
      state.isLoading = false;
      state.donations = payload;
    });
    builder.addCase(getTeamMember.fulfilled, (state, {payload}) => {
      state.isLoading = false;
      state.member = payload;
    });
  },
});

export default slice.reducer;
