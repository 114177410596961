import React from 'react';

import { Button, Modal } from '../../';
import { ReactComponent as QuestionMark } from './questionMark.svg';

import styles from './ConfirmModal.module.scss';

interface IConfirmModal {
  confirm: () => void;
  cancel: () => void;
  isOpen: boolean;
  title?: string;
  textBTN?: string;
  type?: string;
}

const ConfirmModal: React.FC<IConfirmModal> = ({
                                                 confirm,
                                                 cancel,
                                                 isOpen,
                                                 title,
                                                 textBTN,
                                                 type,
                                               }) => {
  return (
    <Modal styleModal={{minHeight: 368}} close={cancel} isOpen={isOpen}>
      {isOpen ? (
        <div className={styles.wraper}>
          <div>
            <QuestionMark className={type && styles.delete}/>
            <div className={styles.title}>
            {title}
          </div>
          </div>
          <div className={styles.listButton}>
            <Button type='thin' onClick={cancel}>
              Cancel
            </Button>
            <Button className={type && styles.delete} onClick={confirm}>
              {textBTN ? textBTN : 'Yes'}
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default ConfirmModal;
