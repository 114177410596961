import React, { useState, useContext } from 'react';

import { Textarea } from '../../../components';

import styles from '../SignUp.module.scss';
import ButtonNavigation from '../ButtonNavigation/ButtonNavigation';
import { useMediaQuery } from 'react-responsive';
import { changeUser } from '../../../REST_API/userApi';
import { AppContext } from '../SignUp';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { getUser } from "../../../store/User/user.thunk";
import { useDispatch } from "react-redux";
import { getListsBooks } from "../../../store/Books/books.thunk";

const SetupYourDonationPage: React.FC<RouteComponentProps> = ({ history }) => {
  const { page, list } = useContext(AppContext);

  const [text, setText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = () => {
    setIsLoading(true);

    const succses = () => {
      setIsLoading(false);
      history.push('/sign-up/completed');
    };

    const error = () => {
      setIsLoading(false);
    };

    changeUser('PUT', '/api/v1/users/me', { personalMessage: text }, succses, error);

    dispatch(getUser());
    dispatch(getListsBooks());
  };
  return (
    <>
      <span className={styles.title}>Sign Up</span>
      <span className={styles.text}>Setup your Donation page</span>
      <div className={styles.textareaWrapper}>
        <Textarea
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
          value={text}
          placeholder='Write a personal message that donors will see on your page.'
        />
      </div>
        <span>Keep empty to use default message</span>
      <ButtonNavigation
        isDisabled={false}
        isLoading={isLoading}
        onClick={text ? onSubmit : () => history.push('/sign-up/completed')}
        list={list}
        page={{ ...page, activePage: 4 }}
      />
    </>
  );
};

export default withRouter(SetupYourDonationPage);
