import { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';

const Portal: React.FC = ({ children }) => {
  const element = useMemo(() => document.createElement('div'), []);

  useEffect((): any => {
    document.body.appendChild(element);
    return () => element.remove();
  }, [element]);

  return ReactDOM.createPortal(children, element);
};

export default Portal;
