import { createSlice } from '@reduxjs/toolkit';
import { getSignUpData, getCurrentTeam, deleteCurrentTeam } from './signUpData.thunk';
import { IEvent, ITeam } from '../../types';

interface IinitialState {
  error: boolean | string;
  data: null | IEvent;
  loading: boolean;
  currentTeam: null | ITeam;
}

const reducerListTeam = createSlice({
  name: 'listTeam',
  initialState: { error: false, data: null, loading: false, currentTeam: null } as IinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSignUpData.pending, (state: IinitialState) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getSignUpData.fulfilled, (state: IinitialState, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getCurrentTeam.fulfilled, (state: IinitialState, action) => {
      state.currentTeam = action.payload;
      state.loading = false;
    });
    builder.addCase(getSignUpData.rejected, (state: IinitialState) => {
      state.loading = false;
      state.error = 'true';
      state.data = null;
    });
    builder.addCase(deleteCurrentTeam.fulfilled, (state: IinitialState) => {
      state.data = null;
    });
  },
});
export default reducerListTeam.reducer;
