import { Middleware } from 'redux';

import { fetchStart, fetchSuccess } from '../Indicators/actionsIndicators';

export const loadingMiddleware: Middleware = (store) => (next) => (action) => {
  let isLoading = false;
  let isLoaded = false;

  next(action);

  for (const key in store.getState()) {
    if (store.getState()[key]?.loading) {
      isLoading = true;
    }
  }

  if (!isLoading) {
    isLoaded = true;
  }

  if (isLoading && store.getState().indicators.isLoading === false) {
    store.dispatch(fetchStart());
  }

  if (isLoaded && store.getState().indicators.isLoading !== false) {
    store.dispatch(fetchSuccess());
  }
};
