import React, { useEffect, useState } from 'react';

import styles from './DonationsProgressCentered.module.scss';
import MoneyAmount from "../../UI/MoneyAmount";

interface Props {
  amount: number;
  goal: number;
  donors?: number,
  currency: string;
  caption?: string;
}

const DonationsProgressCentered: React.FC<Props> = ({amount, currency, donors, goal, caption}) => {

  const [progressWidth, setProgressWidth] = useState(0);

  useEffect(() => {
    let persent = (amount || 0) / goal * 100;
    setProgressWidth(persent < 100 ? persent : 100);
  });
  return (
    <div className={styles.wrapper}>
      <div className={styles.values}>
        <div>
          <span className={styles.amount}>
            <MoneyAmount amount={amount || 0} currency={currency}/>
          </span>
          <span className={styles.goal}>
             {' / '} <MoneyAmount amount={goal || 0} currency={currency}/>
          </span>
        </div>
        <div className={styles.caption}>
          {caption ? caption : `raised by ${donors} donors`}
        </div>
      </div>
      <div className={`${styles.progress}`}>
        <div style={{width: `${progressWidth}%`}}/>
      </div>
    </div>
  );
};

export default DonationsProgressCentered;
