import React, { useEffect } from 'react';


import styles from './DonationPage.module.scss';
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../types";
import MainBlock from "./MainBlock/MainBlock";
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import { getPublicEvent } from "../../store/DonationPage/thunks";
import { LoaderData } from "../../components";
import FundraiserInfo from "./FundraiserInfo/FundraiserInfo";
import DonationInfo from "./DonationInfo/DonationInfo";
import { analytics } from "../../analytics";

interface Props extends RouteComponentProps {

}

const DonationPage = ({history}: Props) => {
  const {code} = useParams<{code: string}>();
  const dispatch = useDispatch();
  const event = useSelector((state: IState) => state.dp.event);
  const donations = useSelector((state: IState) => state.dp.donations);
  const member = useSelector((state: IState) => state.dp.member);
  const bookLists = useSelector((state: IState) => state.dp.bookLists);

  const goDonateTo = () => {
    history.push(history.location.pathname + '/donate');
  };
  const goSignUp = () => {
    history.push('/sign-up/join/code/' + event?.inviteCode);
  };
  useEffect(() => {
    dispatch(getPublicEvent(code));
    analytics.logView('donaiton_page');
  }, []);

  return (
    <div className={styles.DonationPage}>
      <div className={styles.contentContainer}>
        {event && <MainBlock event={event} member={member}
                             onDonateClick={goDonateTo}
                             bookLists={bookLists}
                             onJoinClick={goSignUp}
        />}
        {!event && <LoaderData className={styles.loader} colorWhite={true}/>}

        {event && <FundraiserInfo event={event} donations={donations}/>}

      </div>
    </div>
  );
};

export default withRouter(DonationPage);
