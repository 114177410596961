import React, { useState } from 'react';

import { ConfirmModal } from '../../../components';

import styles from './LogOut.module.scss';

interface ILogOut {
  onLogout: () => void;
}

const LogOut: React.FC<ILogOut> = ({ onLogout }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  return (
    <div>
      <button onClick={() => setShowConfirm(true)} className={styles.LogOut}>
        Log out
      </button>
      <ConfirmModal
        confirm={() => onLogout()}
        title={' Are you sure want to log out?'}
        cancel={() => setShowConfirm(false)}
        isOpen={showConfirm}
      />
    </div>
  );
};

export default LogOut;
