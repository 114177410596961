import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { CreateReaderProfile, Settings } from '../screens';

const Navigation: React.FC = () => {
  return (
    <Switch>
      <Route exact path='/settings'>
        <Settings />
      </Route>
      <Route exact path='/settings/users/new/profile'>
        <CreateReaderProfile title='Create reader profile' />
      </Route>
      <Route exact path='/settings/users/:id/profile'>
        <CreateReaderProfile title='Edit reader profile' />
      </Route>
    </Switch>
  );
};

export default Navigation;
