import React, { useEffect, useState } from 'react';

import AccountSettings from './AccountSettings/AccountSettings';
import ChangePasswordModal from './ChangePasswordModal/ChangePasswordModal';
import EditEmailModal from './EditEmailModal/EditEmailModal';
import LogOut from './LogOut/LogOut';
import Readers from './Readers/Readers';

import styles from './Settings.module.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import { History } from 'history';
import HelpAndSupport from "./AccountSettings/HelpAndSupport";
import { analytics } from "../../analytics";
import { ConfirmModal } from "../../components";
import {useSelector} from "react-redux";
import {IState} from "../../types";

interface ISettings extends RouteComponentProps {
  history: History;
}

const Settings: React.FC<ISettings> = ({history}) => {
  const [editEmailModal, setEditModal] = useState<boolean>(false);
  const [changePasswordModal, setChangePasswordModal] = useState<boolean>(false);

  const user = useSelector((state: IState) => state.user.data);

  useEffect(() => {
    analytics.logView('settings');
  });

  const logout = () => {
    localStorage.clear();
    history.push('/welcome');
    window.location.reload();
  };

  return (
    <div className={styles.wrapperSettings}>
      <Readers/>
      <AccountSettings
        onEditEmail={() => setEditModal(true)}
        onChangePasswordEmail={() => setChangePasswordModal(true)}
      />
      <HelpAndSupport user={user}/>
      <EditEmailModal isOpen={editEmailModal} onClose={() => setEditModal(false)}/>
      <ChangePasswordModal
        isOpen={changePasswordModal}
        onClose={() => setChangePasswordModal(false)}
      />
      <LogOut onLogout={logout}/>

    </div>
  );
};

export default withRouter(Settings);
