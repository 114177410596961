import load from './';

export const logIn = async (
  currentValues: {
    email: string;
    password: string;
  },
  success: (data: any) => void,
  error: (data: string) => void
) => {
  const res = await load('POST', '/api/v1/auth/login', currentValues);
  const json = await res.json();
  if (res.ok) {
    success(json);
  } else {
    error(json);
  }
};

export const signUp = async (
  currentValues: {
    email: string;
    password: string;
    inviteCode: string;
    teamId?: string | null;
  },
  success: (data: any) => void,
  error: (data: string) => void
) => {
  const res = await load('POST', '/api/v1/auth/signup', currentValues);
  const json = await res.json();
  if (res.ok) {
    success(json);
  } else {
    error(json);
  }
};

export const requestToken = async (
  currentValues: {
    email: string;
    verificationId: string;
  },
  success: (data: any) => void,
  error: (data: string) => void
) => {
  const res = await load('POST', '/api/v1/auth/access-token/request', currentValues);
  const json = await res.json();
  if (res.ok) {
    success(json);
  } else {
    error(json);
  }
};
