import React, { useState } from 'react';

import { Button, Modal, Select } from '../../';

import styles from './SelectModal.module.scss';

interface IConfirmModal {
  onSelect: (data: number) => void;
  cancel: () => void;
  isOpen: boolean;
  title: string;
  value?: number | string;
}

const SelectModal: React.FC<IConfirmModal> = ({
  onSelect,
  cancel,
  isOpen,
  children,
  title,
  value,
}) => {
  // const [selectedItem, setSelectedItem] = useState<number | null>(null);
  return (
    <Modal styleModal={{ minHeight: 317, height: 317 }} close={cancel} isOpen={isOpen}>
      <div className={styles.wraper}>
        <span className={styles.title}>{title}</span>
        <Select value={value || ''} onChange={onSelect}>
          {children}
        </Select>
        <div className={styles.listButton}>
          <Button
            type='thin'
            onClick={() => {
              cancel();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              cancel();
              onSelect(parseInt(value+''));
            }}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SelectModal;
