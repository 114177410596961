import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import cn from 'classnames';

import styles from './MenuSorting.module.scss';

interface IMenuSorting {
  title: string;
  list: Array<{name: string; icon: string}>;
  setSortBy: (value: string) => void;
}

const MenuSorting: React.FC<IMenuSorting> = ({title, list, setSortBy}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
      <div className={styles.wrapperMenuSorting}>
        <button onClick={() => setIsOpen(!isOpen)} className={styles.menuSortingTitle}>
          {title}
        </button>
        {isOpen && (
          <div className={styles.menuSortingList}>
            {list.map((item) => (
              <div
                className={cn(
                  styles.menuSortingRow,
                  title === item.name && styles.menuSortingRowActive
                )}
                key={item.name}
              >
                <img className={styles.menuSortingImg} src={item.icon} alt='icon'/>
                <button
                  className={styles.itemSortingList}
                  onClick={() => {
                    setSortBy(item.name);
                    setIsOpen(false);
                  }}
                >
                  {item.name}
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default MenuSorting;
