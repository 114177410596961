import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { IState } from '../../types';
import { ChangeUser, Input, Ripple } from '../';
import logo from '../../assets/images/logo/logo_horizontal_white.svg';

import styles from './Header.module.scss';

const listBottom = [
  {name: 'My page', url: '/main'},
  {name: 'Progress', url: '/progress/my'},
  {name: 'Books', url: '/books'},
  {name: 'Settings', url: '/settings'},
];

const styleRipple = {
  borderRadius: '40px',
  overflow: 'hidden',
  display: 'inline-block',
  margin: '0 6px',
};
const styleRippleMob = {borderRadius: '40px', overflow: 'hidden', display: 'inline-block'};

const Header: React.FC = () => {
  const user = useSelector((state: IState) => state.user.data);
  const [showNav, setShowNav] = useState(true);
  const location = useLocation().pathname.slice(1);
  useEffect(() => {
    if (location.startsWith('progress')) {
      const removeEventListener = window.addEventListener('scroll', (e: Event) => {
        setShowNav(document.documentElement.scrollTop < 100);
      });
      return () => removeEventListener;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!user) return null;

  const {firstName, avatar, created} = user;
  const isNewUser = Date.now() - created < 3600 * 1000 * 12;

  const getHeading = (): JSX.Element | string => {
    const item = (title: string, subtitle: string) => (
      <>
        <span className={styles.title}>{title}</span>
        <span className={styles.subtitle}>{subtitle}</span>
      </>
    );

    switch (location) {
      case 'main':
        return item(isNewUser ? 'Hello,' : 'Welcome back,', firstName);
      case 'progress/my':
        return item('', 'My progress');
      case 'progress/team':
        return item('', 'Team progress');
      case 'progress/school':
        return item('', 'School progress');
      case 'books/recommended':
        return item('', 'Books');
      case 'books/my':
        return item('', 'Books');
      default:
        return '';
    }
  };

  const listNav = location.includes('books')
    ? [
      {name: 'Recommended', url: '/books/recommended'},
      {name: 'My books', url: '/books/my'},
    ]
    : [
      {name: 'My goals', url: '/progress/my'},
      {name: 'My team', url: '/progress/team'},
      {name: 'My school', url: '/progress/school'},
    ];

  const withTans = location.startsWith('progress') || location.startsWith('books');
  return (
    <header
      className={cn(
        styles.wrapperHeader,
        location.startsWith('progress') && styles.wrapperHeaderProgress,
        location.startsWith('books') && styles.wrapperHeaderbooks,
        location.includes('settings') && styles.wrapperHeaderSettings
      )}
    >
      <>

        <Link to={'/main'} className={styles.headerLogo}>
          <img alt='logo' src={logo}/>
        </Link>

        <div className={styles.avatarDesktop}>
          <ChangeUser user={user} alt='avatar' avatar={avatar}/>
        </div>

        <div
          className={cn(styles.contentHeader, withTans && styles.contentHeaderProgress)}
        >
          <div className={styles.title}>{getHeading()}</div>
          <div className={styles.avatarMobile}>
            <ChangeUser user={user} alt='avatar' avatar={avatar}/>
          </div>
        </div>
        {location.startsWith('books') && (
          <Link to='/books/search' className={styles.inputWrapper}>
            <Input isSearch={true} placeholder={'Find your book'}/>
          </Link>
        )}
        {withTans && (
          <nav
            className={cn(location.startsWith('books') ? styles.booksTabs : styles.progressTabs, !showNav && styles.showNav)}>
            {listNav.map((item) => {
              const url = item.url.slice(1);
              return (
                <li
                  className={cn(
                    styles.navItemProgress,
                    location === url && styles.navItemProgressActive
                  )}
                  key={item.url}
                >
                  <Ripple styles={styleRippleMob}>
                    <Link to={item.url}>
                      <span
                        className={cn(
                          styles.navTextProgress,
                          location === url && styles.navTextProgressActive
                        )}
                      >
                        {item.name}
                      </span>
                    </Link>
                  </Ripple>
                </li>
              );
            })}
          </nav>
        )}
      </>
    </header>
  );
};

export default Header;
