import { createSlice } from '@reduxjs/toolkit';
import { getAddReading } from './addReading.thunk';

interface IinitialState {
  error: boolean | string;
  data: null | boolean;
  loading: boolean;
}

const reducerEvent = createSlice({
  name: 'addReading',
  initialState: { error: false, data: null, loading: false } as IinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAddReading.pending, (state: IinitialState) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getAddReading.fulfilled, (state: IinitialState, action) => {
      state.loading = false;
    });
    builder.addCase(getAddReading.rejected, (state: IinitialState) => {
      state.loading = false;
    });
  },
});

export default reducerEvent.reducer;
