import React from 'react';

import { Button,Modal } from '../../';

import { SadSmile } from '../../../assets/images/icons';

import styles from './ErrorModal.module.scss';

interface IErrorModal {
  cancel: () => void;
  isOpen: boolean;
  title: string;
}

const ErrorModal: React.FC<IErrorModal> = ({ cancel, isOpen, title }) => {
  return (
    <Modal styleModal={{ minHeight: 368}} close={cancel} isOpen={isOpen}>
      <div className={styles.wraper}>
        <SadSmile />
        <span className={styles.title}>
          {title}.
        </span>
        <Button type="thin" onClick={cancel}>
          OK
        </Button>
      </div>
    </Modal>
  );
};

export default ErrorModal;
