import React from 'react';
import defaultAvatar from '../../../assets/images/icons/defaultAvatar.svg';
import OkFilledIcon from '../../../assets/images/icons/OkFilledIcon.svg';

import { IManaged } from '../../../types';
import { ReactComponent as EditSvg } from '../../../assets/images/icons/Edit.svg';

import styles from './ReadersSelect.module.scss';

interface IReaders {
  users: Array<IManaged>,
  onSetCurrent: (user: any) => void,
  onEdit: (user: any) => void,
}

const ReadersSelect: React.FC<IReaders> = ({users, onSetCurrent, onEdit}) => {

  return (
    <div className={styles.listCard}>
      {users.map((user: IManaged) => (
        <div
          key={user.id}
          className={styles.card}
        >
          <div className={styles.avatar} onClick={() => {
            if (!user.isCurrent) onSetCurrent(user)
          }}>
            <img className={styles.imgCard} src={user.avatar ? user.avatar : defaultAvatar} alt='Avatar'/>
            {user.isCurrent && <img src={OkFilledIcon} className={styles.imgOk}/>}
          </div>
          <div className={styles.nameCard} onClick={() => {
            if (!user.isCurrent) onSetCurrent(user)
          }}>
              {user.firstName} {user.lastName}
            </div>

          <EditSvg className={styles.editBtn} onClick={() => onEdit(user)}/>
        </div>
      ))}
    </div>
  );
};

export default ReadersSelect;
