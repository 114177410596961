import {IAchievement, IProgressMy} from '../../types';
import {createSlice} from '@reduxjs/toolkit';
import {getAchievements, getProgressMy} from './progressMy.thunk';

interface IinitialState {
  error: boolean | string;
  data: null | IProgressMy;
  achievements: Array<IAchievement>;
  newAchievement?: IAchievement;
  loading: boolean;
}

const reducerProgressMy = createSlice({
  name: 'progressMy',
  initialState: {error: false, data: null, loading: false} as IinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProgressMy.pending, (state: IinitialState) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getProgressMy.fulfilled, (state: IinitialState, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getProgressMy.rejected, (state: IinitialState) => {
      state.loading = false;
    });
    builder.addCase(getAchievements.fulfilled, (state: IinitialState, {payload}) => {
      state.achievements = payload;
      let unviewed = state.achievements.filter(a => !a.viewed && a.achieved);
      state.newAchievement = unviewed.length > 0 ? unviewed[unviewed.length - 1] : undefined;
    });
  },
});

export default reducerProgressMy.reducer;
