import React, { useEffect } from 'react';

import { ReactComponent as BookIcon } from '../icons/book.svg';
import Button from '../../UI/Button/Button';

import styles from '../AddReadingModal.module.scss';
import { Link } from 'react-router-dom';

type Props = {
  onFindClick: () => void;
  setCurrentSlide: (index: number) => void;
  skip: boolean
};

const FirstStep: React.FC<Props> = ({onFindClick: onFindClick, setCurrentSlide, skip}) => {
  useEffect(() => {
    setCurrentSlide(skip ? 1 : 0);
  }, []);

  return (
    <>
      <h3 className={styles.title}>Your bookshelf is empty</h3>
      <div className={styles.middle}>
        <BookIcon/>
      </div>

      <div>
        <Button onClick={() => setCurrentSlide(1)}
                type='link'>Can’t find your book? Log minutes here.</Button>
        <div className={styles.buttonsSivider}></div>
        <Button onClick={onFindClick}>Find your book</Button>
      </div>
    </>
  );
};

export default FirstStep;
