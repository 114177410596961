import React from 'react';

import styles from './Textarea.module.scss';

const Textarea: React.FC<any> = ({ placeholder, meta, type, ...field }) => {
  return (
    <>
      <div className={styles.wrapper}>
        <textarea {...field} placeholder={placeholder}
                  rows={5} className={styles.textarea} />
      </div>
      {meta && meta.touched && meta.error && <div className={styles.error}>{meta.error}</div>}
    </>
  );
};

export default Textarea;
