import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../REST_API/listApi';

export const getEvents = createAsyncThunk('event/getEventsData', async () => {
  const response = await API.events.getEventsData();
  const event = new CustomEvent('eventloaded', {'detail': response.data});
  document.dispatchEvent(event);
  return response.data;
});

export const getConfig = createAsyncThunk('config', async () => {
  const response = await API.events.getConfig();
  return response.data;
});

